import React from "react"
import styled from "styled-components"
import { colors } from "../../../styles"
import sizes from "../../../utils/sizes"
import { Container } from "../styles"
import TitleBar from "../TitleBar"
import ConnectDiscord from "./ConnectDiscord"

const Social = () => {
  return (
    <Container>
      <TitleBar title="Discord" />
      <ConnectDiscord />
    </Container>
  )
}

export default Social
