import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { BackendBaseURL } from "../config/constants"
import { IExplorer, IExplorerMetadata } from "../models/Explorer"
import { convertIExplorerMetadataToIExplorer } from "../utils/explorer"

const useExplorerData = (id: string) => {
  const [data, setData] = useState<IExplorer>()
  const [loading, setLoading] = useState(true)
  const [, setCounter] = useState("")

  const loadData = useCallback(async () => {
    setLoading(true)
    setCounter(id)
    const { data } = await axios.get<IExplorerMetadata>(`${BackendBaseURL}/metadata/${id}`)

    setCounter((prevCounter) => {
      if (prevCounter === id) {
        setData(convertIExplorerMetadataToIExplorer(id, data))
        setLoading(false)
      }

      return prevCounter
    })
  }, [id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return { data, loading }
}

export default useExplorerData
