import { chainIdsMapping } from "./env"
import externalAddresses from "./externalAddresses.json"

export const getSubgraphBaseUrl = () => {
  return "https://api.thegraph.com/subgraphs/name/lootexplorers/loot-explorers"
}

export const getLootContractAddress = (chainId: number) => {
  const networkName = chainIdsMapping[chainId as keyof typeof chainIdsMapping]
  if (networkName === "Rinkeby") {
    return externalAddresses.rinkeby.loot
  }
  return externalAddresses.mainnet.loot
}

export const getLootExplorersContractAddress = (chainId: number) => {
  const networkName = chainIdsMapping[chainId as keyof typeof chainIdsMapping]
  if (networkName === "Rinkeby") {
    return externalAddresses.rinkeby.lootexplorers
  }
  return externalAddresses.mainnet.lootexplorers
}

export const BackendBaseURL = process.env.REACT_APP_BACKEND_BASE_URL || "https://api.lootexplorers.quest"
