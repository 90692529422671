import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import { ReactComponent as MetamaskIcon } from "../../../assets/icons/metamask.svg"
import { ReactComponent as WalletConnectIcon } from "../../../assets/icons/walletconnect.svg"
import { chainIdsMapping, supportedChainIDs } from "../../../config/env"
import { useGlobalState } from "../../../store/state"
import { colors } from "../../../styles"
import { getWalletConnectConnector, injectedConnector } from "../../../utils/connectors"
import BasicModal from "../BasicModal"
import { clickable } from "../common"
import { ConnectorType } from "./types"

interface ConnectorButtonProps {
  status: "normal" | "initializing" | "neglected" | "connected"
  isConnected: boolean
  isDisabled?: boolean
  wrongNetwork?: boolean
}

const ICON_WIDTH = 40

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  padding-bottom: 32px;
  text-align: center;
`

const Title = styled.h1`
  color: ${colors.brown};
  font-weight: bold;
  font-size: 1.5rem;
`

const ConnectorButton = styled.button<ConnectorButtonProps>`
  ${({ wrongNetwork }) => (wrongNetwork ? "cursor: default;" : clickable)}
  background-color: ${colors.green};
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  border: ${({ isConnected }) => {
    return isConnected ? `3px solid ${colors.brown}` : "none"
  }};
  opacity: ${({ isDisabled }) => {
    return isDisabled ? "0.5" : "1"
  }};

  ${(props) => {
    switch (props.status) {
      case "neglected":
        return `
          opacity: 0.24;

          &:hover {
            opacity: 0.24;
          }
        `
      case "initializing":
        return `
          &:hover {
            opacity: 1;
          }
        `
      default:
        return props.wrongNetwork
          ? ""
          : `
            &:hover {
              background-color: ${colors.darkGreen}
            }
          `
    }
  }}
`

const IconContainer = styled.div`
  background-color: white;
  padding: 4px;
  border-radius: 8px;
`

const WalletName = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  padding-left: 8px;
  display: flex;
  align-items: center;
  text-align: left;
`

const DisconnectButton = styled.button`
  background-color: transparent;
  padding: 16px;
  font-weight: bold;
  color: ${colors.darkGreen};
`

const StatusText = styled.span`
  color: ${colors.brown};
  text-align: center;
  padding: 8px 24px;
`

const WalletConnectModal = () => {
  const { connector, activate, error, library, account, active, deactivate } = useWeb3React()
  const [connectingConnector, setConnectingConnector] = useState<ConnectorType>()
  const [showConnectWallet, setShowConnectWallet] = useGlobalState("showConnectWallet")

  const connectedTo: ConnectorType | undefined = useMemo(() => {
    return library && account ? (library.connection.url === "metamask" ? "metamask" : "walletConnect") : undefined
  }, [account, library])

  const isUnsupportedNetwork = useMemo(() => {
    return error instanceof UnsupportedChainIdError
  }, [error])

  const onClose = useCallback(() => {
    setShowConnectWallet(false)
  }, [setShowConnectWallet])

  const handleDisconnect = useCallback(
    (closeModal?: boolean) => {
      if (connector && connector instanceof WalletConnectConnector) {
        const wcConnector = connector as WalletConnectConnector
        wcConnector.close()
      }
      deactivate()

      if (closeModal) {
        onClose()
      }
    },
    [connector, deactivate, onClose],
  )

  const handleConnect = useCallback(
    async (type: ConnectorType) => {
      // Disconnect wallet if currently connected already
      if (connectedTo) {
        handleDisconnect(false)
        return
      }

      // Connect wallet
      setConnectingConnector(type)
      switch (type) {
        case "metamask":
          await activate(injectedConnector)
          break
        case "walletConnect":
          await activate(getWalletConnectConnector())
          break
      }
      setConnectingConnector(undefined)
    },
    [activate, handleDisconnect, connectedTo],
  )

  const getConnectorStatus = useCallback(
    (ConnectorType: ConnectorType) => {
      // If connected, check if current button is connected
      if (active) {
        switch (ConnectorType) {
          case "metamask":
            if (connector instanceof InjectedConnector) return "connected"
            break
          case "walletConnect":
            if (connector instanceof WalletConnectConnector) return "connected"
            break
        }
      }

      // Check initializing status
      switch (connectingConnector) {
        case undefined:
          return "normal"
        case ConnectorType:
          return "initializing"
      }
      return "neglected"
    },
    [active, connector, connectingConnector],
  )

  const renderConnectorIcon = useCallback((type: ConnectorType) => {
    switch (type) {
      case "metamask":
        return <MetamaskIcon width={ICON_WIDTH} height={ICON_WIDTH} />
      case "walletConnect":
        return <WalletConnectIcon width={ICON_WIDTH} height={ICON_WIDTH} />
    }
  }, [])

  const renderConnectorButton = useCallback(
    (type: ConnectorType, title: string) => {
      return (
        <ConnectorButton
          role="button"
          disabled={isUnsupportedNetwork || (connectedTo && connectedTo !== type)}
          isDisabled={isUnsupportedNetwork || (connectedTo && connectedTo !== type)}
          wrongNetwork={isUnsupportedNetwork || (connectedTo && connectedTo !== type)}
          onClick={() => handleConnect(type)}
          status={getConnectorStatus(type)}
          isConnected={connectedTo === type}
        >
          <WalletName>
            {connectingConnector === type ? "Loading..." : title}
            {connectedTo === type && (
              <span style={{ fontSize: "0.7rem", paddingLeft: "8px" }}>(Connected. Click to disconnect)</span>
            )}
          </WalletName>
          <IconContainer>{renderConnectorIcon(type)}</IconContainer>
        </ConnectorButton>
      )
    },
    [connectingConnector, getConnectorStatus, renderConnectorIcon, handleConnect, connectedTo, isUnsupportedNetwork],
  )

  return (
    <BasicModal show={Boolean(showConnectWallet)} onClose={onClose} height={354} closeButton>
      <Content>
        <Header>
          <Title>{isUnsupportedNetwork ? "UNSUPPORTED NETWORK" : "CONNECT WALLET"}</Title>
        </Header>
        {renderConnectorButton("metamask", "Metamask")}
        {renderConnectorButton("walletConnect", "Wallet Connect")}
        {Boolean(connectedTo || isUnsupportedNetwork) && (
          <DisconnectButton onClick={() => handleDisconnect(true)}>Disconnect?</DisconnectButton>
        )}
        {Boolean(!connectedTo && isUnsupportedNetwork) && (
          <StatusText>
            You are on an unsupported network.
            <br />
            Please switch to&nbsp;
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {supportedChainIDs.map((id) => (chainIdsMapping as any)[id]).join(", ")}.
          </StatusText>
        )}
      </Content>
    </BasicModal>
  )
}

export default WalletConnectModal
