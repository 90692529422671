import React from "react"
import styled, { css } from "styled-components"
import LogoImage from "../assets/webp/logo.webp"
import { colors } from "../styles"
import { clickable } from "./common/common"

interface LogoProps {
  onLogoClicked?: () => void
  bottomBarHeight: number

  logoMetrics: {
    remainder: number
    borderWidth: number
    logoWidth: number
    logoPadding: number
    logoBottom: number
    circleHeight: number
  }
}

const shared = css`
  ${clickable}
  position: absolute;
`

interface CircleProps {
  right: number
  bottom: number
  width: number
  height: number
  borderWidth: number
}
const Circle = styled.div<CircleProps>`
  ${shared}
  right: ${(props) => props.right}px;
  bottom: ${(props) => props.bottom}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${colors.green};
  border: ${(props) => props.borderWidth}px solid ${colors.darkGreen};
  border-bottom: none;
  border-top-left-radius: 99999px;
  border-top-right-radius: 99999px;
  opacity: 0.9;
`

interface LogoIconProps {
  bottom: number
  right: number
  width: number
}
const LogoIcon = styled.img.attrs<LogoIconProps>((props) => ({
  src: LogoImage,
}))<LogoIconProps>`
  ${shared}
  bottom: ${(props) => props.bottom}px;
  right: ${(props) => props.right}px;
  width: ${(props) => props.width}px;
`

const Logo = (props: LogoProps) => {
  const { bottomBarHeight, onLogoClicked, logoMetrics } = props
  const { remainder, borderWidth, logoWidth, logoPadding, logoBottom, circleHeight } = logoMetrics

  return (
    <>
      <Circle
        onClick={onLogoClicked}
        right={remainder - borderWidth}
        bottom={bottomBarHeight}
        width={logoWidth + logoPadding * 2 + borderWidth * 2}
        height={circleHeight}
        borderWidth={borderWidth}
      />
      <LogoIcon onClick={onLogoClicked} bottom={logoBottom} right={logoPadding + remainder} width={logoWidth} />
    </>
  )
}

export default Logo
