import { Provider } from "@ethersproject/abstract-provider"
import { Signer } from "@ethersproject/abstract-signer"
import { useCallback, useEffect } from "react"
import { Loot__factory } from "../../codegen"
import { getLootContractAddress } from "../../config/constants"
import { useGlobalState } from "../../store/state"

export const getLootContract = (signerOrProvider: Signer | Provider, chainId: number) => {
  if (signerOrProvider) {
    return Loot__factory.connect(getLootContractAddress(chainId), signerOrProvider)
  }

  return undefined
}

const useLootContract = (library?: any) => {
  const [contract, setContract] = useGlobalState("lootContract")

  const updateContract = useCallback(async () => {
    if (!library) {
      setContract(undefined)
      return
    }

    const signerOrProvider = (await library.getSigner()) as Signer
    const chainId = await signerOrProvider.getChainId()
    const contract = getLootContract(library, chainId)
    setContract(contract)
  }, [library, setContract])

  // Added signerAddress as deps to retrigger connection whenever signer is changed.
  useEffect(() => {
    updateContract()
  }, [updateContract])

  return contract
}
export default useLootContract
