import React, { useMemo } from "react"
import styled from "styled-components"
import { faqInfos } from "../../config/info"
import { usePuzzle } from "../../hooks/usePuzzle"
import { colors } from "../../styles"
import { Body, Container } from "./styles"
import TitleBar from "./TitleBar"

const Title = styled.h4`
  font-weight: bold;
  text-align: left;
  font-size: 1.3rem;
  padding-top: 8px;
`

const PuzzleContainer = styled.div`
  position: relative;
`
const PuzzleBg = styled.div`
  position: absolute;
  width: 34px;
  height: 34px;
  top: -28px;
  left: -8px;
  background-color: ${colors.pale};
`
const PuzzleSymbol = styled.img.attrs(({ src }) => ({ src }))`
  position: absolute;
  top: -38px;
  left: -16px;
  width: 50px;
  height: 50px;
`

const Faq = () => {
  const { correctPuzzle, puzzleSolved, getImageAssetForPuzzleName, puzzleDisabled } = usePuzzle()

  const randomFaqSymbolIndex = useMemo(() => {
    return Math.floor(Math.random() * faqInfos.length)
  }, [])

  const puzzleSymbol = useMemo(() => {
    if (correctPuzzle?.[2] && !puzzleSolved) {
      return getImageAssetForPuzzleName(correctPuzzle[2].name)
    }
    return undefined
  }, [correctPuzzle, getImageAssetForPuzzleName, puzzleSolved])

  return (
    <Container>
      <TitleBar title="FAQ" />
      {faqInfos.map(({ question, answers }, index) => (
        <React.Fragment key={question}>
          <Title>
            {question}
            {Boolean(puzzleSymbol && randomFaqSymbolIndex === index && !puzzleDisabled) && (
              <PuzzleContainer>
                <PuzzleBg />
                <PuzzleSymbol src={puzzleSymbol} />
              </PuzzleContainer>
            )}
          </Title>
          {answers.map((answer) => (
            <Body key={answer}>{answer}</Body>
          ))}
        </React.Fragment>
      ))}
    </Container>
  )
}

export default Faq
