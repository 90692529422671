import { Signer } from "@ethersproject/abstract-signer"
import { BaseContract } from "@ethersproject/contracts"
import { BigNumber, ethers } from "ethers"
import { useCallback, useEffect, useMemo, useState } from "react"
import { LootExplorers, LootExplorers__factory } from "../../codegen"
import { getLootExplorersContractAddress } from "../../config/constants"
import { isDevelopment, isProduction } from "../../config/env"
import { whitelistProof } from "../../config/proof"
import { SaleStage, useGlobalState } from "../../store/state"
import { processLog } from "../../utils/transaction"
import { useLootBags } from "../useLootBags"

export const getExplorerContract = (provider: Signer, chainId: number) => {
  if (provider) {
    const address = getLootExplorersContractAddress(chainId)
    if (address) {
      return LootExplorers__factory.connect(address, provider)
    }
  }
  return undefined
}

export interface GeneralMintingInfo {
  totalAmount: number

  // Price is in WEI, display price is simplified for display
  pricePerMint: BigNumber
  displayPricePerMint: number

  // Fixed
  maxMintPerTransaction: 5
}

const defaultGeneralMintingInfo: GeneralMintingInfo = {
  // 8003, 3 is reserved for team
  totalAmount: 8003,
  pricePerMint: ethers.utils.parseEther("0.04"),
  displayPricePerMint: 0.04,
  maxMintPerTransaction: 5,
}

const useExplorerContract = (library?: any, account?: string | null) => {
  const [contract, setContract] = useState<LootExplorers>()
  const [mintedAmount, setMintedAmount] = useState<number>()

  // Default minting info
  const [generalMintingInfo, setGeneralMintingInfo] = useState<GeneralMintingInfo>(defaultGeneralMintingInfo)

  // default (before load) is undefined
  const [saleStage, setSaleStage] = useGlobalState("saleStage")

  const { loading: lootLoading, bags } = useLootBags(library, account)
  const [mintedBagIds, setMintedBagIds] = useState<number[]>([])
  const [, setTransaction] = useGlobalState("transaction")

  const proof = useMemo(() => {
    return account ? ((whitelistProof as any)[account.toLowerCase()] as string[]) : undefined
  }, [account])

  const isMintingSoon = useMemo(() => {
    return !saleStage || saleStage === "lootSoon" || saleStage === "whitelistSoon" || saleStage === "publicSoon"
  }, [saleStage])

  const isSoldOut = useMemo(() => {
    if (mintedAmount && generalMintingInfo.totalAmount) {
      return mintedAmount >= generalMintingInfo.totalAmount
    }
    return false
  }, [mintedAmount, generalMintingInfo.totalAmount])

  // Updates latest sale stage and minted amount.
  // For auto updates, the component can poll these functions
  const updateSaleStage = useCallback(async () => {
    if (contract) {
      try {
        const [saleActive, saleStageString] = await Promise.all([contract.saleIsActive(), contract.saleStage()])
        let ss: SaleStage = "lootSoon"
        if (!saleActive) {
          if (saleStageString === "public") {
            ss = "publicSoon"
          } else if (saleStageString === "whitelist") {
            ss = "whitelistSoon"
          } else if (saleStageString === "loot") {
            ss = "lootSoon"
          }
        } else {
          if (saleStageString === "public") {
            ss = "public"
          } else if (saleStageString === "whitelist") {
            ss = "whitelist"
          } else if (saleStageString === "loot") {
            ss = "loot"
          }
        }
        !isProduction() && console.log("SALE STAGE", ss)
        setSaleStage(ss)
      } catch (error) {
        console.log("Error retrieving sale stage", error, contract)
      }
    }
  }, [contract, setSaleStage])

  const updateMintedAmount = useCallback(async () => {
    if (contract) {
      try {
        setMintedAmount((await contract.totalSupply()).toNumber())
      } catch (error) {
        console.log("Error retrieving total supply")
      }
    } else {
      setMintedAmount(undefined)
    }
  }, [contract])

  // When bags is available, check which ones have been minted
  const updateMintedBagIds = useCallback(async () => {
    if (contract && bags.length) {
      const sentLogs = await contract.queryFilter(contract.filters.Transfer(ethers.constants.AddressZero, null, null))
      const mintedTokens = sentLogs.map((log) => processLog(log, "Transfer").tokenId)
      setMintedBagIds(mintedTokens)
    }
  }, [contract, bags])

  // Added signerAddress as deps to retrigger connection whenever signer is changed.
  const updateContract = useCallback(
    async (lib: any) => {
      // Reset all dirty states
      setContract(undefined)
      setMintedAmount(undefined)
      setGeneralMintingInfo(defaultGeneralMintingInfo)
      setSaleStage(undefined)
      setMintedBagIds([])

      if (lib) {
        const signer = (await lib.getSigner()) as Signer
        const chainId = await signer.getChainId()
        !isProduction() && console.log("USING SIGNER", chainId)

        // Set contract
        const contract = getExplorerContract(signer, chainId)
        setContract(contract)
      }
    },
    [setSaleStage],
  )

  // Update contract on load
  useEffect(() => {
    updateContract(library)
  }, [updateContract, library])

  // When contract is loaded, load initial data
  useEffect(() => {
    isDevelopment() && console.log("Contract Ready:", Boolean(contract))
    // Update default values
    updateMintedAmount()
    updateSaleStage()
  }, [contract, updateMintedAmount, updateSaleStage])

  // When bags is available, check which ones have been minted
  useEffect(() => {
    updateMintedBagIds()
  }, [updateMintedBagIds])

  // Given a transaction, waits for confirmation and then ends the transaction.
  const handleMintTransactionProcessing = useCallback(
    async (provider: BaseContract["provider"], contractTransaction?: ethers.ContractTransaction) => {
      if (contractTransaction) {
        setTransaction({
          state: "processing",
          hash: contractTransaction.hash,
        })
        const receipt = await provider.waitForTransaction(contractTransaction.hash, 3)
        setTransaction({
          state: "completed",
          hash: receipt.transactionHash,
          receipt,
        })
      }
    },
    [setTransaction],
  )

  const handleMintTransactionError = useCallback(
    (anyError: any) => {
      if (anyError.code === 4001) {
        // Metamask denied signature. End transaction without error
        setTransaction(undefined)
      } else if (anyError.error) {
        // Failed gas limit estimation is -32603
        setTransaction({
          state: "error",
          error: anyError.error?.message || "Sorry, an error occured.",
        })
      } else {
        console.log(anyError)
        setTransaction({
          state: "error",
          error: "Sorry, an error occured.",
        })
      }
    },
    [setTransaction],
  )

  // METHODS
  const ogMint = useCallback(
    async (lootIds: number[]) => {
      if (!contract) {
        return
      }
      try {
        setTransaction({ state: "started" })
        const response = await contract.summonWithLoots(lootIds, {
          value: generalMintingInfo.pricePerMint.mul(lootIds.length),
        })
        await handleMintTransactionProcessing(contract.provider, response)
      } catch (error) {
        handleMintTransactionError(error)
      }
    },
    [
      contract,
      generalMintingInfo.pricePerMint,
      setTransaction,
      handleMintTransactionProcessing,
      handleMintTransactionError,
    ],
  )

  const whitelistMint = useCallback(
    async (amount: number) => {
      if (!contract || !proof) {
        return
      }

      try {
        setTransaction({ state: "started" })

        const response = await contract.summonFirstExplorers(amount, proof, {
          value: generalMintingInfo.pricePerMint.mul(amount),
        })
        await handleMintTransactionProcessing(contract.provider, response)
      } catch (error) {
        handleMintTransactionError(error)
      }
    },
    [
      contract,
      proof,
      generalMintingInfo.pricePerMint,
      setTransaction,
      handleMintTransactionProcessing,
      handleMintTransactionError,
    ],
  )

  const publicMint = useCallback(
    async (amount: number) => {
      if (!contract) {
        return
      }

      try {
        setTransaction({ state: "started" })

        let gasLimit = 100000
        if (amount > 4) {
          gasLimit = 210000
        } else if (amount > 3) {
          gasLimit = 200000
        } else if (amount > 2) {
          gasLimit = 170000
        } else if (amount > 1) {
          gasLimit = 140000
        }

        const response = await contract.summon(amount, {
          value: generalMintingInfo.pricePerMint.mul(amount),
          gasLimit: gasLimit,
        })
        await handleMintTransactionProcessing(contract.provider, response)
      } catch (error) {
        handleMintTransactionError(error)
      }
    },
    [
      contract,
      generalMintingInfo.pricePerMint,
      setTransaction,
      handleMintTransactionProcessing,
      handleMintTransactionError,
    ],
  )

  return {
    ready: Boolean(contract),

    // INFO
    saleStage,
    generalMintingInfo,
    mintedAmount: mintedAmount ?? 0,
    lootLoading,
    bags,
    mintedBagIds,
    isSoldOut,
    isMintingSoon,

    // MERKLE PROOF FOR WHITELISTING
    proof,

    // METHODS
    ogMint,
    whitelistMint,
    publicMint,
    updateMintedBagIds,
    updateSaleStage,
    updateMintedAmount,
  }
}

export default useExplorerContract
