const requiredExplorerAttributes = [
  "level",
  "background",
  "race",
  "allegiance",
  "origins",
  "type",
  "head",
  "weapon",
  "chestArmor",
  "headArmor",
  "waistArmor",
  "footArmor",
  "handArmor",
  "necklace",
  "ring",
] as const
const optionalExplorerAttributes = ["face", "subtype"] as const
export const explorerAttributes = [...requiredExplorerAttributes, ...optionalExplorerAttributes] as const

type RequiredExplorerAttribute = typeof requiredExplorerAttributes[number]
type OptionalExplorerAttribute = typeof optionalExplorerAttributes[number]
export type ExplorerAttribute = typeof explorerAttributes[number]

export type IExplorerAttribute = { [attribute in RequiredExplorerAttribute]: string } & Partial<{
  [attribute in OptionalExplorerAttribute]: string
}>

export interface IExplorer extends IExplorerAttribute {
  id: string // tokenId
  name: string
  imageUrl: string
  description: string
}

export interface IExplorerMetadata {
  name: string
  image: string
  description: string
  attributes: {
    trait_type: ExplorerMetadataAttribute
    value: number | string
    display_type: "number" | undefined
  }[]
}

export const explorerMetadataAttributes = [
  "Level",
  "Background",
  "Race",
  "Allegiance",
  "Origins",
  "Type",
  "Head",
  "Face",
  "Weapon",
  "Chest Armor",
  "Head Armor",
  "Waist Armor",
  "Foot Armor",
  "Hand Armor",
  "Necklace",
  "Ring",
  "Subtype",
] as const
export type ExplorerMetadataAttribute = typeof explorerMetadataAttributes[number]

export type ExplorerMetadataAttributeSummary = { [key in ExplorerMetadataAttribute]: { [value: string]: number } }

export const EXPLORER_METADATA_ATTRIBUTE_MAP: { [attribute in ExplorerMetadataAttribute]: ExplorerAttribute } = {
  Level: "level",
  Background: "background",
  Race: "race",
  Allegiance: "allegiance",
  Origins: "origins",
  Type: "type",
  Head: "head",
  Face: "face",
  Weapon: "weapon",
  "Chest Armor": "chestArmor",
  "Head Armor": "headArmor",
  "Waist Armor": "waistArmor",
  "Foot Armor": "footArmor",
  "Hand Armor": "handArmor",
  Necklace: "necklace",
  Ring: "ring",
  Subtype: "subtype",
}

export const EXPLORER_ATTRIBUTE_NAME_MAP = Object.fromEntries(
  (Object.keys(EXPLORER_METADATA_ATTRIBUTE_MAP) as ExplorerMetadataAttribute[]).map((metadataAttribute) => [
    EXPLORER_METADATA_ATTRIBUTE_MAP[metadataAttribute],
    metadataAttribute,
  ]),
) as { [attribute in ExplorerAttribute]: ExplorerMetadataAttribute }
