import { useEffect, useRef } from "react"

export function useLast<T>(value: T, initialValue?: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const storedValue = useRef(initialValue)

  // Store current value in ref
  useEffect(() => {
    storedValue.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return storedValue.current
}
