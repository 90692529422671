import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { ReactComponent as Discord } from "../../../assets/icons/discord.svg"
import WalletDisconnectedIcon from "../../../assets/icons/wallet/wallet-offline.png"
import { BackendBaseURL } from "../../../config/constants"
import useSiwe from "../../../hooks/useSiwe"
import { useGlobalState } from "../../../store/state"
import { borderColors, colors } from "../../../styles"
import sizes from "../../../utils/sizes"
import { Button } from "../../common/Button"
import { BaseAnchor, clickable } from "../../common/common"
import { Body } from "../styles"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: ${colors.lightGreen};
  border: 1px solid ${borderColors.Apocalypse};
  border-radius: 8px;
`

const Description = styled(Body)<{ marginTop?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  margin-top: ${(props) => props.marginTop || 8}px;
  text-align: center;
  width: 70%;
  color: ${colors.darkGreen};

  @media (max-width: ${sizes.md}px) {
    width: 100%;
  }
`

const DisconnectText = styled(Description)<{ marginTop?: number }>`
  ${clickable}
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

const ActionButton = styled(Button)`
  margin-top: 16px;
`

const WalletStatusIcon = styled.img.attrs(({ src }) => ({ src }))`
  width: 24px;
  height: 24px;
  margin-left: 4px;
`

const ErrorDescription = styled(Description)`
  color: ${colors.red};
`

const ConnectDiscord = () => {
  const location = useLocation()
  const [error, setError] = useState<string>()
  const { active, account } = useWeb3React()
  const { token, onRequestSignature, resetToken } = useSiwe()
  const [, setShowConnectWallet] = useGlobalState("showConnectWallet")
  const [discordConnect, setDiscordConnect] = useGlobalState("discordConnect")

  useEffect(() => {
    setError(new URLSearchParams(location.search).get("error") || undefined)
  }, [location])

  useEffect(() => {
    // Reset token when it's invalid
    if (error === "tokenInvalid") {
      resetToken()
    }
  }, [error, resetToken])

  const handleDisconnectDiscord = useCallback(() => {
    if (!discordConnect.data.connected) {
      return
    }

    axios.get(`${BackendBaseURL}/discord/unregister`, {
      params: { publicAddress: account },
    })

    setDiscordConnect({ loading: false, data: { connected: false } })
  }, [account, discordConnect.data.connected, setDiscordConnect])

  const actionButton = useMemo(() => {
    if (!active) {
      return (
        <ActionButton onClick={() => setShowConnectWallet(true)}>
          Connect Wallet <WalletStatusIcon src={WalletDisconnectedIcon} />
        </ActionButton>
      )
    }

    if (!discordConnect.loading && discordConnect.data.connected && discordConnect.data.accounts) {
      return (
        <Fragment>
          <Description marginTop={16}>
            Connected to{" "}
            {discordConnect.data.accounts.reduce(
              (acc, curr, index) => `${acc}${index !== 0 ? ", " : ""}${curr.username}`,
              "",
            )}
          </Description>
          <DisconnectText role="button" onClick={handleDisconnectDiscord}>
            Disconnect
          </DisconnectText>
        </Fragment>
      )
    }

    if (!token) {
      return (
        <ActionButton onClick={() => onRequestSignature(setError)}>
          Verify Wallet <WalletStatusIcon src={WalletDisconnectedIcon} />
        </ActionButton>
      )
    }

    return (
      <BaseAnchor href={`${BackendBaseURL}/discord/register?token=${token}`}>
        <ActionButton>
          Connect Discord <WalletStatusIcon src={WalletDisconnectedIcon} />
        </ActionButton>
      </BaseAnchor>
    )
  }, [active, discordConnect, handleDisconnectDiscord, onRequestSignature, setShowConnectWallet, token])

  const errorDisplay = useMemo(() => {
    switch (error) {
      case "noCode":
        return <ErrorDescription>Unable to connect. Please try again later.</ErrorDescription>
      case "notConnected":
        return <ErrorDescription>Please connect your wallet before connecting to discord.</ErrorDescription>
      case "tokenMissing":
        return <ErrorDescription>No token is presented.</ErrorDescription>
      case "tokenInvalid":
        return <ErrorDescription>There is some issue with the token. Please try again in a while.</ErrorDescription>
      case "siweVerifyError":
        return <ErrorDescription>Something went wrong verifying your wallet. Please try again later.</ErrorDescription>
      default:
        return <></>
    }
  }, [error])

  return (
    <Container>
      <Discord width="10%" />
      <Description>
        Connect your Discord to this wallet to participate in our Discord event! The event rewards will be awarded to
        the address you&apos;re connected to.
      </Description>
      {actionButton}
      {errorDisplay}
    </Container>
  )
}

export default ConnectDiscord
