export const allPuzzleNames = [
  "beast",
  "colossal",
  "luminescence",
  "skeleton",
  "apocalypse",
  "despair",
  "psychic",
  "rage",
  "storm",
] as const
export const allPuzzleBackgrounds = ["#FED073", "#F77878", "#CDE2A0", "#DCCEB7"] as const
export type PuzzleName = typeof allPuzzleNames[number]
export type PuzzleBackground = typeof allPuzzleBackgrounds[number]

// Each completed puzzle is made of 4 puzzle pieces,
// 0-3
export interface SinglePuzzlePiece {
  name: PuzzleName
  background: PuzzleBackground
}
export interface CompletedPuzzle {
  0: SinglePuzzlePiece
  1: SinglePuzzlePiece
  2: SinglePuzzlePiece
  3: SinglePuzzlePiece
}

export const phraseOfTheDay = {
  // 13th Dec 2021
  "1639324800000": "Ship has sailed",
  // 14th
  "1639411200000": "The poetry drowns",
  // 15th
  "1639497600000": "The thorny pattern",
  // 16th
  "1639584000000": "The aching tank",
  // 17th
  "1639670400000": "The coherent twist",
  // 18th
  "1639756800000": "The flawless law",
  // 19th
  "1639843200000": "The festive chocolate",
  // 20th
  "1639929600000": "The tragic letter",
  // 21st
  "1640016000000": "The virtual march",
  // 22nd
  "1640102400000": "The dangerous holiday",
  // 23rd
  "1640188800000": "The sunny pick",
  // 24th
  "1640275200000": "The melted chance",
  // 25th
  "1640361600000": "The untimely message",
}
