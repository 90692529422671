import styled, { css } from "styled-components"
import { ReactComponent as Magnify } from "../../../../assets/icons/magnify.svg"
import { ReactComponent as Minus } from "../../../../assets/icons/minus.svg"
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg"
import { colors } from "../../../../styles"
import sizes from "../../../../utils/sizes"

const Container = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${sizes.md}px) {
    bottom: 72px;
  }
`

const Button = styled.button<{
  roundedBorder?: "top" | "bottom"
}>`
  border: 3px solid ${colors.darkGreen};
  background-color: ${colors.lightGreen};
  padding: 8px;
  z-index: 1;

  ${({ roundedBorder }) => {
    if (roundedBorder === "top") {
      return css`
        border-radius: 8px 8px 0 0px;
        border-bottom: 0;
      `
    } else if (roundedBorder === "bottom") {
      return css`
        border-radius: 0 0 8px 8px;
        border-top: 0;
      `
    }
    return ""
  }}
`

const Divider = styled.div`
  height: 3.5px;
  background-color: ${colors.darkGreen};
`

const MagnifyButton = styled(Magnify)<{ isDisabled?: boolean }>`
  cursor: pointer;
  margin-bottom: 8px;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.6;
      `
    }
    return ""
  }}
`

const iconSize = 24

interface ZoomControlsProps {
  onZoomIn: () => void
  onZoomOut: () => void
  showRenders: boolean
  onToggleShowRenders: () => void
}

const ZoomControls = (props: ZoomControlsProps) => {
  return (
    <Container>
      <MagnifyButton isDisabled={!props.showRenders} onClick={props.onToggleShowRenders} />
      <Button onClick={props.onZoomIn} roundedBorder="top">
        <Plus width={iconSize} height={iconSize} />
      </Button>
      <Divider />
      <Button onClick={props.onZoomOut} roundedBorder="bottom">
        <Minus width={iconSize} height={iconSize} />
      </Button>
    </Container>
  )
}

export default ZoomControls
