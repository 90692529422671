export const LandNames = [
  "Frostenn",
  "Magma",
  "Pollen",
  "Cannon Coast",
  "Violet Everside",
  "Merlade",
  "Shattered Bone",
  "Noctus",
  "Lagoona",
  "Underlune",
] as const
export type LandNamesType = typeof LandNames[number]

export type Landmark = {
  id: string
  name: string
  description: string[]
  credits?: string
}
export interface Land {
  name: LandNamesType
  fullName: string
  color: string

  // Image name used for firebase image
  imageName: string

  // Song name used for display in music player
  fullSongName: string

  // Array of landmarks
  landmarks: Landmark[]
}

type LandNameToLand = {
  [key in LandNamesType]: Land
}

export const Lands: LandNameToLand = {
  Frostenn: {
    name: "Frostenn",
    fullName: "Frostenn",
    imageName: "frostenn",
    fullSongName: "",
    color: "#cde4e0",
    landmarks: [
      {
        id: "7",
        name: "Lisuni Ridge",
        description: [
          "Here sits Mount Lisuni, Mount Ethys, & Mount Hallis, the three talest peaks of the Lisuni Mountain range. Explorers will experience the coldest of weathers within the region here, so it's no easy feat to be able to traverse these parts without a Flame Explorer by your side. With mountainsides filled with whirsil hog dens and hidden ice caverns, these lands aren't for the faint hearted.",
        ],
      },
      {
        id: "8",
        name: "Mammoth's Reach",
        credits: "Rasuki",
        description: [
          "Between tall ice spike pillars, a giant frozen hand sticks out from the terrain, with its fingers grasping towards a star-shaped ice crystal. According to an old Frostenn legend passed down from generation to generation, the story tells of a thousand year winter casted across the Explorer realm byy a malevolent evil spirit.",
          "But, an ancient magic wielding giant who swore its life to protect the realm, used all his might to absorb the wrath and devestation of the curse. In his last breath, he was able to contain all of its power within a frost crystal conjured upon his palm, fulfilling its oath and saving the realm as we know it.",
        ],
      },
      {
        id: "9",
        name: "Glimmerden",
        credits: "illustrium",
        description: [
          "A tall snow cliff right sitting below the giant hand of ice lies the entrance into the lair of snow dragons, dangerous beasts who only roam the vast snowfields under the moonlight. Inside, an icy maze of reflection prevents outsiders from getting into their nests, where their precious snow dragon eggs are being kept.",
        ],
      },
      {
        id: "10",
        name: "? ? ?",
        description: [
          'The frozen lakes of Frostenn are mostly found in the Western parts of Frostenn. Frost-fishing is a common sport here with a shared goal amongst fishermen to catch the legendary Draalfish, a 25-foot long silver frostfish known by the locals as the "silver devil".',
          "It is usually mistaken for large lirfishes, white-colored frostfishes with pink & blue striped meat when cut open. They are as fresh as they come, preseved by the icy lake water.",
        ],
      },
      {
        id: "11",
        name: "Freyr's Well",
        credits: "citrushammer",
        description: [
          "The hot springs of Frostenn are a popular attraction for both locals & travellers alike, a well deserved rest stop after a long trek in the cold. Located by the border of Magma, volcanic rocks from deep beneath the ground naturally heat up the water springs.",
          "A local Frostenn delicacy called lava lugga that is made right here in the springs, a sweet tasting beverage that heats the entire body up with overwhelming warmth to help overcome the freezing weather. Side effects may include increased giddiness & uncontrollabe giggles.",
        ],
      },
    ],
  },
  Magma: {
    name: "Magma",
    fullName: "Magma Isle",
    imageName: "magma",
    fullSongName: "",
    color: "#ca754b",
    landmarks: [
      {
        id: "1",
        name: "The Infernal Twins",
        credits: "D_D_",
        description: [
          "The molten-heart of the isle. Almost everything in the region is powered by the blistering heat of its two active volcanoes.",
        ],
      },
      {
        id: "1A",
        name: "Crucible's Rest",
        credits: "πdpiper",
        description: [
          "The heat from the lava shaped this kingdom into a form that almost resembles a meatball cluster. The interior of the castle has long bridge-like hallways that connect each area. The Crimson Forge sits within this cluster, the ancient workshop where Explorer Piros Dolruhmn can be found hammering away.",
        ],
      },
      {
        id: "2",
        name: "The Cinder Dome",
        credits: "Papercup",
        description: [
          "Explorers raised and trained in the Isle have been known to be skilled in traditional combat, especially with melee weapons like maces and mauls. Many festivals and trials of strength are held daily in the arena. Always lively, never empty, the walls are made of a specific type of rock that reverberates the crowd's roars across the city.",
        ],
      },
      {
        id: "3",
        name: "The Ruby Library",
        description: [
          "A library in an Isle of lava? It would be quite a disaster if the library wasn't encased in a powerful fire proof hex discovered by one of Magma Isle's most respected scholars, Sans 'The Curious'. Being one of the largest libraries with extremely rare collections, The Ruby invites curious Explorers from every region to walk on its red crystal floors.",
        ],
      },
      {
        id: "4",
        name: "Ragaa",
        description: [
          "A sleek fortress with a single altar in its center glows in red light reflected from the golden pillars hovering over it.  A  place that has provoked fear in many, the structure remains today as a mysterious temple in the Isle. Although it is often deserted, tales of sacrifice in the name of a deity fueled with rage cling to its shimmery walls.",
        ],
      },
      {
        id: "5",
        name: "City of Smog",
        description: [
          "A city shrouded in a cloud of fog and hot steam. This area is known to be one of the hottest places in the Realm. An exclusive community of Flames and Golems isolate themselves from the rest of the Isle's inhabitants in these areas.",
        ],
      },
      {
        id: "6",
        name: "Door of Duros",
        credits: "xyn/Serg",
        description: [
          "The leaders of the Magma Isle take great pride in maintaining the natural formations of their land. The only entrance to the partially-underground city is from a well guarded gate made out of igneous rocks.",
        ],
      },
    ],
  },
  Pollen: {
    name: "Pollen",
    fullName: "Pollen",
    imageName: "merlade",
    fullSongName: "",
    color: "#cecb89",
    landmarks: [
      {
        id: "12",
        name: "The Emerald Gardens",
        credits: "Steviepee.eth",
        description: [
          "The emeralds found in the mines are unlike any other. They're are extra shiny, dense and heavier than most precious rocks. Mining a single emerald vein takes ages, but the gem is so valuable that even its dust can be sold for quite an amount as they're said to hold magical properties that can give anyone the strength of a titan and regenerative abilities.",
        ],
      },
      {
        id: "13",
        name: "Gaia's Overpass",
        credits: "supergg101",
        description: [
          "On the sunniest days, shades of green illuminate the cool hills around The Crystalline Bridge. It is formed out of emeralds that are even stronger than the ones found in the mines.",
          "How it was built is a mystery, many believe it was formed naturally, but some believe it was crafted by a Titan deity. Although we may not know the answer for sure, we can still marvel at its glistening beauty",
        ],
      },
      {
        id: "14",
        name: "The Selenology Sphere",
        description: [
          "Click, whirrr, click-  Gentle mechanical sounds can be heard as small bronze statues of varying shapes and sizes move across a large layered-ring contraption.",
          "At its center, a tall, golden statue of a crescent moon can be found. Many scholars and warriors of the Moon frequent this monument to study and perfect their celestial powers. The machine is constantly adjusting itself to the stars and phases of the Moon, showing different readings and arcane abilities.",
        ],
      },
      {
        id: "15",
        name: "Lostlight Ruins",
        credits: "babycakes77",
        description: [
          "This pile of marble used to be a surveying tower. Powered with a trangular shaped gem that emitted a violet light, it's clear it was searching for something deep in the water.",
        ],
      },
    ],
  },
  "Cannon Coast": {
    name: "Cannon Coast",
    fullName: "Cannon Coast",
    imageName: "cannon-coast",
    fullSongName: "",
    color: "#e4d6a5",
    landmarks: [
      {
        id: "24",
        name: "Canon Arms Citadel",
        description: [
          "The citadel overlooks the Centos Sea, safeguarding the region from dangers of the depth's unknown. Explorers that come here to train themselves will have to put their spirits to the test following a strict training regimen under a veteran Kelptain Commander who came from the Underlune.",
        ],
      },
    ],
  },
  "Violet Everside": {
    name: "Violet Everside",
    fullName: "Violet Everside",
    imageName: "violet-everside",
    fullSongName: "",
    color: "#c8c4d2",
    landmarks: [
      {
        id: "17",
        name: "The Moon Guild, of Dark Magic",
        description: [
          "In a dark area deep in the Violet Woods, a small abandoned cottage stands partially soaked up by a small lake. The windows are broken and foggy, the walls struggling to hold up pieces of decayed straw. Most would walk past this building without giving it a second thought, unless you're from the elite coven of dark magic, a small society of dark magic weilders.",
          "Then you would open the water-logged door by its rusty bronze handle and find yourself in one of the most luxurious looking hallways in all of the Realm! Obsidian tiles from floor to ceiling with beautiful silver detailing, you would marvel at its beauty if it weren't for the strong feeling of unease in the air",
        ],
      },
      {
        id: "18",
        name: "Everside Farms",
        description: [
          "The Violet Everside is blessed with a very nutrient-rich soil composition. Crops and flora thrive in every part of the land, especially in the Everside Farms. The land around the crop plots are naturally sloped in a way that minerals from the lilac trees get pooled under the dirt and a bountiful harvest is expected all year round! Farmers markets and events take place around these areas as well.",
        ],
      },
      {
        id: "19",
        name: "Linon's Waystone",
        description: [
          "-Linon the Dazzling Flame-",
          "Most are familiar with Linon's twin Earl, the Eternal Flame. Born out of yellow kindle, Earl is the more cheerful of the pair, Linon was born out of blue kindle and emits more of a gentle light.  Unlike his twin, Linon treats the waystone as more of a sanctuary than a communal centre. It prefers to be alone but will offer its regenerative abilities for explorers who it thinks deserves it.",
        ],
      },
    ],
  },
  Merlade: {
    name: "Merlade",
    fullName: "Merlade",
    imageName: "merlade",
    fullSongName: "",
    color: "#cecb89",
    landmarks: [
      {
        id: "16",
        name: "Genesis Temple",
        description: [
          "The Genesis Temple overlooks the Genesis strait, a passage of water connecting the Centos sea from the east to the Lheios sea in the west. An ancient power resides deep within the temple, the place where all 16 original orders of Loot came to be. The structure itself is built upon a colossal being's mossy head, half burried within the earth as if in deep slumber. Gigantic color shifting crystals are also lodged in the grounds, changing as the seasons go by & affecting the colors of all surrounding nature & life.",
          "The temple has lived through history, with a fallen tower that greets you as you enter. A great domed tholos hall is still well preserved but overgrown with wild plants, with it's circular floor marked with historical drawings reciting the origin story of Loot.",
        ],
      },
      {
        id: "20",
        name: "Cave of the Twins",
        description: [
          "Right on the border where the lands of the Violet Everside & Merlade meet, an omnious tunnel entrance distinguishable by its twin spires sits hidden in the forests right outside castle Merlade. Discovered by a couple of  Explorers who were out foraging for medical herbs in the nearby woods. When you're near, you seem to hear your own voice calling out to you from within the cave.",
        ],
      },
      {
        id: "21",
        name: "Castle Merlade",
        description: [
          "The fortress of the Merladean king. The castle is situated right in the center of Lake Merlade, built on a secluded island only accesible through a drawbridge at the southern castle gate.",
          "Lord Catos lives inside the tall walls, which may seem unnecessary as the region has only known peace. Perhaps the king is just a tad bit shy and would like some privacy from the outer world, a space for him to truly relax in his lonesome.",
        ],
      },
      {
        id: "22",
        name: "Earl's Waystone",
        description: [
          "The place of origin where the story of Earl the Eternal Flame took place:",
          '"The quest for loot can be a lonely one, with each Explorer off on their own paths. On one of the darkest winter nights; when it was the coldest it had ever been, a small flame lit up an entire mountain, determined to bring warmth to the many Explorers he had met & heard stories from. Like a beacon of safety, The Bonfire is now the communal space for all Explorers to feel like they have a home waiting for them, no matter how far they venture."',
          'The Bonfire is the primary gathering place of Explorers within Merlade, with Earl the Eternal Flame always seen sat under a large tree at the top of sunset hill, providing travellers with warmth and advice. "We\'re all family here, the Bonfire welcomes you". Earl greets you with a smile.',
        ],
      },
      {
        id: "23",
        name: "Wizarding School for fellow Felines",
        description: [
          "It is recorded in Siamese Texts that the sacred grounds of The Wizarding School for Fellow Felines was once the very first litter box in the Explorer's Realm.",
          "Dug up and annointed by Grandfur Master Chocoby, his arcane scent still wafts in the school halls 'til this very day. Felines from all over the land come to this legendary academy to partake in the fine arts of Magic, though you may catch an occasional sun-bather every once in a while.",
        ],
      },
    ],
  },
  "Shattered Bone": {
    name: "Shattered Bone",
    fullName: "Shattered Bone",
    imageName: "cannon-coast",
    fullSongName: "",
    color: "#e4d6a5",
    landmarks: [
      {
        id: "25",
        name: "Calamity Cove",
        credits: "Hellfish",
        description: [
          "An old shipwreck pile that still sits on the beach, commonly used by passerbys to take refuge from the occasional sandstorm. Somehow, all shipwrecks seem to wash up here forming a large pile, but how that came to be is still an unknown story.",
        ],
      },
      {
        id: "26",
        name: "Sandy Marketplace",
        description: [
          "The sandy marketplace is one of the busiests places to be, with merchants displaying their wares from all across the Explorer realm.",
        ],
      },
      {
        id: "27",
        name: "Fred the Fish",
        description: ["Fred. Not much is known about Fred besides the fact that he is large, and that he is Fred."],
      },
      {
        id: "28",
        name: "Loot Fishery",
        description: [
          "A facility to fish out chests and crate stashes seen floating on the ocean. Who knows where these items drift from, but whether its treasure or junk, the Loot fishery picks them all up.",
          "This clears the waters of the crates & debris, a working system that prevents the pollution of water surrounding Lagoona, while also benefiting the fishery from the random chance of gold & jewels.",
        ],
      },
    ],
  },
  Noctus: {
    name: "Noctus",
    fullName: "Noctus",
    imageName: "noctus",
    fullSongName: "",
    color: "#726774",
    landmarks: [
      {
        id: "37",
        name: "Noctus Ascendant",
        credits: "πdpiper",
        description: [
          "On the western end of the moon's shadow sits a tall dark tower coursing with arcane power. It's spiral top can be seen from afar, piercing through the shroud of purple mist engulfing the entirety of Noctus.",
          "Noctii mages that frequent the tower are amongst the most powerful there is, their incantations are mostly unheard of by regular mages of the realm. Thus, magic users from all over would take a sorcerer's pilgrimage to the tower, hoping to develop their enchanting and spellworking capabilities & to learn the ways of the Noctus spellcasters.",
        ],
      },
      {
        id: "38",
        name: "Black City Market",
        description: [
          "Gold and green floating lights illuminate the market pathways as masked & hooded figures move from store to store to examine their goods.",
          "Unlike other markets, an eerie silence fills the atmosphere. People seem to speak under their breaths, whispering and uttering away. Spirit charms, runic stones, bottled wisps, arcane scrolls, crystallised herbs, are few of the many things you can get here and no where else.",
        ],
      },
      {
        id: "39",
        name: "Netherglass",
        credits: "Papercup",
        description: [
          "The mirror with no reflection. Stand in front of it long enough and your physical self will begin to vanish.",
        ],
      },
      {
        id: "40",
        name: "City of Spires, Noctus Ministry District",
        description: [
          "The city of spires are a collection of buildings with gothic architecture, each wih their own spired tower rising high up into the sky, with their tips twinkling in the mist. It is an elegant district belonging to the Noctus Ministry, with roads tiled with gleaming black marble, smooth & well polished.",
          "The midnight hall is where the Noctus council members usually gather, sitting on the outer end of a crescent shaped table, attending to important matters while donned in black robes lined with gold floral embroidery.",
        ],
      },
      {
        id: "41",
        name: "Dojo of Self Reflection",
        description: [
          "The dojo of self reflection is no ordinary dojo, a place with mystical energy flowing throughout the training grounds, giving pupils enhanced performance capabilities and strength.",
          "Shadow boxing is taken to the next level where Explorers train with a spirit form of themselves, mirroring all their behaviour and moves. They are taught to discover their own weaknesses & how overcome them. Mysteriously, the Masters of the Dojo are never around. Some say they've ascended into the mirror realm, becoming one with their reflections.",
        ],
      },
    ],
  },
  Lagoona: {
    name: "Lagoona",
    fullName: "Lagoona",
    imageName: "lagoona",
    fullSongName: "Deep Valley",
    color: "#67aa98",
    landmarks: [
      {
        id: "29",
        name: "Algae Forest Station",
        description: [
          "The algae forest stands tall in front of the station, it's entrance guarded by two trident wielding Deeptide Sentinels. This is the only entrance into Lagoona for non-aquatic Explorers, where you'll be casted a spell of respiration to traverse the depths.",
        ],
      },
      {
        id: "29A",
        name: "Deep Sea Market",
        description: [
          "After passing through the station, you'll arrive at the deep sea market where you can find goods and artefacts that can only be found in the ocean. The Lagoona bubble tavern is also located here, a rest house for Explorers with rooms that you can breath normally in. However, you'll need to be casted with another respiration spell, or consume a potion of respiration before leaving.",
        ],
      },
      {
        id: "30",
        name: "Deeptide Magistrate",
        description: [
          "The magistrate building is a grand dome hall with tall classical pillars, sitting right in the center of a large colesium covered in traditional deeptide carvings. It is where the Deeptide magistrate conducts their legislative hearings & where great Explorers are celebrated after a succesful expedition.",
        ],
      },
      {
        id: "31",
        name: "Citadel of the Infinite Sea",
        credits: "O_Excess",
        description: [
          "Built out of beautiful white pearlstone, the capital building of Lagoona is a majestic palace with two tall towers giving a commanding view of the ocean kingdom. Inside, a museum filled ocean relics & heirlooms from past Expeditions are displayed, including a hall of Explorers who braved the dangers of the depths.",
        ],
      },
      {
        id: "32",
        name: "Sea Scholars Academy",
        description: [
          "The place where all deeptide younglings take their first steps to become an Elite Explorer. Here, all students go through the same academic & physical training, where their primary abilities are discovered & honed to its greatest potential.",
          "Whether it's magic or physical combat skills, the school prioritizes on a student's strength under the guidance and tutelage of esteemed veteran Deeptide Explorers.",
        ],
      },
      {
        id: "33",
        name: "Bubble Columns",
        description: [
          "Around the city, vapour geysers produce thick bubble columns which sometimes partially obstructs the city view. Swimming into one launches you up to the surface, an activity that deeptide younglings commonly engage with.",
        ],
      },
      {
        id: "34",
        name: "The Deeptide Guardians Guild",
        description: [
          "On the outskirts of Lagoona, the Deeptide Guardians Guild is built on top of a cliff by a large ravine, where an old underwater shipwreck was used & constructed as part of the guildhall.",
          "The guild is responsible for training Deeptide sentinels & recruiting outstanding Explorers from across the realm, from talented sea foragers to skillfull sea dragon hunters. Explorer Fhallu Pinslow was recruited to this very guild as part of an elite expeditionary unit.",
        ],
      },
    ],
  },
  Underlune: {
    name: "Underlune",
    fullName: "Underlune",
    imageName: "lagoona",
    fullSongName: "",
    color: "#5f828c",
    landmarks: [
      {
        id: "35",
        name: "Dark Steam Tides",
        description: [
          "Clouds of dark steam tides filled the waters of the Underlune, creating a shadowy atmosphere dimly lit by the orange salt lamps scatted across the pathways. The tides also generate strong underwater currents, making it even more difficult to traverse these parts. A single mistep a you might find yourself drifting into the Abyssal Deep.",
          "Most inhabitants here dwell in the sea caves commonly found near the pathways. The Kelptain's Keep resides in one of the larger sea caves, an exclusive Octo community hidden away in the deep.",
        ],
      },
      {
        id: "36",
        name: "Oktharis Weaponcrafting Facility",
        description: [
          "The Oktharis Weaponcrafing facility is a heavily guarded armory built deep & secluded in the depths of Underlune, such that they are able to conduct open water tests of their trademark runic weapons.",
          "Deeptide sentinels are always stationed on its walls & grounds, patrolling both the interior & exterior of the facility. They aren't taking any chances in having their sacred technology stolen from them, as its power might be misused if fallen into the wrong hands.",
        ],
      },
    ],
  },
}
