import React, { useCallback, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg"
import { BackendBaseURL } from "../../../config/constants"
import useFilterExplorers, { ExplorerAttributeFilter } from "../../../hooks/useFilterExplorers"
import { ExplorerMetadataAttribute } from "../../../models/Explorer"
import { colors } from "../../../styles"
import { Button } from "../../common/Button"
import { clickableScale, clickableShrunk, SecondaryText } from "../../common/common"
import Loader from "../../common/Loader"
import { Container } from "../styles"
import TitleBar from "../TitleBar"
import GalleryFilterModal from "./GalleryFilterModal"

const StyledButton = styled(Button)`
  ${clickableScale}
`

const FilterTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border: 1px solid ${colors.darkGreen};
  color: ${colors.darkGreen};
  border-radius: 100px;
  margin-top: 8px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  svg {
    ${clickableShrunk}
    margin-left: 4px;
  }
`

const GalleryItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32%;
  border-radius: 8px;
  padding: 8px;
  margin-top: 12px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${colors.green};
    a {
      color: ${colors.brown};
    }
  }

  :not(:nth-child(3n)) {
    margin-right: 2%;
  }

  a {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    color: ${colors.darkGreen};
  }
`

const ExplorerImg = styled.div<{ backgroundSrc: string }>`
  width: 100%;
  background-image: url(${(props) => props.backgroundSrc});
  background-size: cover;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`

interface GalleryListContentProps {
  filter: ExplorerAttributeFilter
  setFilter: React.Dispatch<React.SetStateAction<ExplorerAttributeFilter>>
}

const GalleryListContent: React.FC<GalleryListContentProps> = ({ filter, setFilter }) => {
  const [showFilterModal, setShowFilterModal] = useState(false)

  const { data, initialLoaded, total, loadMoreData, loading } = useFilterExplorers(filter)

  const updateFilter = useCallback(
    (key: ExplorerMetadataAttribute, value: string[]) => {
      setFilter((currFilters) => ({ ...currFilters, [key]: value }))
    },
    [setFilter],
  )

  const removeFilter = useCallback(
    (removeKey: ExplorerMetadataAttribute, removeValue: string) => {
      setFilter((currFilters) => {
        const filterValues = currFilters[removeKey]
        if (filterValues) {
          const newFilterValues = filterValues.filter((v) => v !== removeValue)
          if (newFilterValues?.length) {
            return {
              ...currFilters,
              [removeKey]: newFilterValues,
            }
          }

          return Object.fromEntries(
            (Object.keys(currFilters) as ExplorerMetadataAttribute[])
              .filter((key) => key !== removeKey)
              .map((key) => [key, currFilters[key]]),
          )
        }
        return currFilters
      })
    },
    [setFilter],
  )

  const filterTags = useMemo(() => {
    const filterKeys = Object.keys(filter) as ExplorerMetadataAttribute[]

    if (filterKeys.length > 0) {
      return filterKeys
        .map((filterKey) => {
          const filterValues = filter[filterKey]
          if (filterValues) {
            return filterValues.map((v) => (
              <FilterTag key={v}>
                {`${filterKey}: ${v}`}{" "}
                <CloseIcon fill={colors.darkGreen} height={12} onClick={() => removeFilter(filterKey, v)} />
              </FilterTag>
            ))
          }
          return undefined
        })
        .flat()
    }

    return <FilterTag>All</FilterTag>
  }, [filter, removeFilter])

  const renderGalleryItems = useCallback((explorers: { id: string; name: string }[]) => {
    if (explorers.length <= 0) {
      return <div className="d-flex w-100 mt-5 justify-content-center">Empty Result</div>
    }

    return explorers.map((item) => (
      <GalleryItem key={item.id}>
        <Link className="d-flex flex-wrap w-100 justify-content-center" to={`/gallery?explorer=${item.id}`}>
          <ExplorerImg backgroundSrc={`${BackendBaseURL}/explorers/${item.id}`} />#{item.id}
        </Link>
      </GalleryItem>
    ))
  }, [])

  return (
    <>
      <GalleryFilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        filter={filter}
        updateFilter={updateFilter}
        removeFilter={removeFilter}
      />
      <Container>
        <TitleBar title="Gallery" />
        <div className="d-flex w-100 align-items-center">
          <div className="d-flex flex-wrap flex-grow-1">{filterTags}</div>
          <StyledButton onClick={() => setShowFilterModal(true)}>Filter</StyledButton>
        </div>
        <div className="d-flex flex-wrap mt-2">
          {!initialLoaded ? (
            <div className="d-flex w-100 justify-content-center mt-5">
              <Loader />
            </div>
          ) : (
            renderGalleryItems(data)
          )}
        </div>
        <div className="d-flex flex-column mt-5 align-items-center">
          {initialLoaded && data.length < total && (
            <StyledButton
              onClick={() => {
                if (!loading) loadMoreData()
              }}
            >
              {loading ? "Loading..." : "Load More"}
            </StyledButton>
          )}
          {initialLoaded && data.length > 0 && (
            <SecondaryText className="mt-4">
              Showing {data.length} of {total} explorers
            </SecondaryText>
          )}
        </div>
      </Container>
    </>
  )
}

export default GalleryListContent
