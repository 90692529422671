import { useMemo } from "react"
import styled, { css } from "styled-components"
import ClassesImage from "../../assets/webp/lore-classes.webp"
import ExplorersImage from "../../assets/webp/lore-explorers.webp"
import { useGlobalState } from "../../store/state"
import { Container } from "./styles"
import TitleBar from "./TitleBar"

const shared = css`
  width: 100%;
  pointer-events: none;
`

const Classes = styled.img.attrs({
  src: ClassesImage,
})`
  ${shared}
`

const Explorers = styled.img.attrs({
  src: ExplorersImage,
})`
  ${shared}
  height: 110vh;
  object-fit: contain;
  object-position: top;
`

const PuzzleHint = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 48px;
  height: 48px;
  margin-left: 8px;
`

const Lore = () => {
  const [navigation] = useGlobalState("modalContentIdNavigation")
  const currentPage = navigation.lore?.currentPage ?? 0

  const currentTitle = useMemo(() => {
    if (currentPage === 0) {
      return "Explorer Classes"
    }
    return "Houses of Power"
  }, [currentPage])

  const CurrentContent = useMemo(() => {
    if (currentPage === 0) {
      return <Classes />
    }
    return <Explorers />
  }, [currentPage])

  return (
    <Container>
      <TitleBar title={currentTitle} />
      {CurrentContent}
    </Container>
  )
}

export default Lore
