import { EXPLORER_METADATA_ATTRIBUTE_MAP, IExplorer, IExplorerAttribute, IExplorerMetadata } from "../models/Explorer"

export const convertIExplorerMetadataToIExplorer = (id: string, metadata: IExplorerMetadata): IExplorer => ({
  id,
  name: metadata.name,
  imageUrl: metadata.image,
  description: metadata.description,
  ...(Object.fromEntries(
    metadata.attributes.map((attribute) => [EXPLORER_METADATA_ATTRIBUTE_MAP[attribute.trait_type], attribute.value]),
  ) as IExplorerAttribute),
})
