import React, { useEffect, useRef, useState } from "react"
import { ImageProps } from "react-bootstrap"
import styled from "styled-components"
import { useLast } from "../../hooks/useLast"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
`
const OverlayImage = styled(Image)`
  position: absolute;
  clippath: inset(0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

interface ProgressiveImage extends ImageProps {
  src: string
  overlaySrc: string
}
const ProgressiveImage = ({ src, overlaySrc, ...others }: ProgressiveImage) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const previousSrc = useLast(src)
  const [highResImageLoaded, setHighResImageLoaded] = useState(false)

  // Reset image loaded
  useEffect(() => {
    if (src && src !== previousSrc) {
      setHighResImageLoaded(false)
    }
  }, [src, previousSrc])

  return (
    <Container>
      <Image
        {...others}
        onLoad={() => {
          setHighResImageLoaded(true)
        }}
        ref={imageRef}
        src={src}
      />
      <OverlayImage {...others} {...(highResImageLoaded && { style: { opacity: "0" } })} src={overlaySrc} />
    </Container>
  )
}

export default ProgressiveImage
