import { AnimatePresence, motion } from "framer-motion"
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react"
import YouTube from "react-youtube"
import styled, { css, keyframes } from "styled-components"
import SparklesGif from "../assets/sparkles.gif"
import SparklesVideo from "../assets/sparkles.webm"
import { BannerImage, CloudsImage, LandscapeImage, MidBGImage, TreeImage, TurnipImage } from "../config/themedAssets"
import useIsMobile from "../hooks/useIsMobile"
import { usePuzzle } from "../hooks/usePuzzle"
import { colors } from "../styles"
import sizes from "../utils/sizes"
import BasicModal from "./common/BasicModal"
import { clickable, clickableShrunk } from "./common/common"
import GifOrWebm from "./GifOrWebm"

const SnowEffect = lazy(() => import("./SnowEffect"))

const { REACT_APP_LIGHTWEIGHT_MODE } = process.env

const common = css`
  position: absolute;
  pointer-events: none;
`

const moveAnim = (moveBy: string) => keyframes`
from {
  transform: translateX(${moveBy});
}

to {
  transform: translateX(0);
}
`

const CloudsContainer = styled.div`
  z-index: -1;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: row;
  animation: ${moveAnim("-450vw")} 140s linear infinite;
  ${REACT_APP_LIGHTWEIGHT_MODE ? "animation: none;" : ""}

  @media (min-width: ${sizes.md}px) {
    animation: ${moveAnim("-300vw")} 140s linear infinite;
    ${REACT_APP_LIGHTWEIGHT_MODE ? "animation: none;" : ""}
  }
`

const Clouds = styled.img.attrs({
  src: CloudsImage,
})`
  width: 450vw;
  height: 34.2vw;

  @media (min-width: ${sizes.md}px) {
    width: 300vw;
    height: 22.8vw;
  }
`

const Landscape = styled.img.attrs({
  src: LandscapeImage,
})<{ started: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -10px;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  transition: 5s transform ease;

  ${(props) => {
    if (!props.started) {
      return `
        transform: scale(1.2) translate(5vw, 5vw);
      `
    }
    return ""
  }}
`

const LandscapeSparkles = styled.div<{ started?: boolean }>`
  position: absolute;
  width: 18vw;
  left: 27.5%;
  bottom: 11.9vw;
  ${clickable}

  transition: opacity 0.5s ease;
  transition-delay: 5s;
  opacity: ${({ started }) => (started ? "1" : "0")};
`

const HintText = styled.span<{ show: boolean }>`
  text-align: center;
  position: absolute;
  bottom: calc(50% + 32px);
  padding: 0 15%;
  color: ${colors.darkGreen};
  font-weight: bold;
  font-size: 1.2rem;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.5s ease-in;
`

const MidBG = styled.img.attrs({
  src: MidBGImage,
})<{ started: boolean }>`
  ${common}
  position: fixed;
  width: 100%;
  bottom: 0;
  transition: 5s transform ease;

  ${(props) => {
    if (!props.started) {
      return `
        transform: scale(1.2) translate(-5vw, 5vw);
      `
    }
    return ""
  }}
`

const Banner = styled.img.attrs({
  src: BannerImage,
})<{ hide: boolean }>`
  ${common}
  // TODO: - This is only for WINTER theme
  width: 35vw;
  top: -1vw;
  left: -1vw;

  transition: transform 1s cubic-bezier(0.68, -0.55, 0.265, 1);
  transform: ${(props) => {
    if (props.hide) {
      return "translateY(-30vmax) translateX(-30vmax)"
    }
    return "translateY(0) translateX(0)"
  }};

  @media (max-width: ${sizes.lg}px) {
    width: 50vw;
  }
`

const Turnip = styled.img.attrs({
  src: TurnipImage,
})<{ hide: boolean; moveDownBy: string }>`
  ${common}
  ${clickableShrunk}
  pointer-events: auto;
  width: 7vw;
  left: 19vw;
  bottom: 7.5vw;
  opacity: ${(props) => (props.hide ? "0" : "1")};
  transition: 0.4s transform ease;
  transform: ${(props) => {
    if (props.hide) {
      return "translateY(200px)"
    } else if (props.moveDownBy) {
      return `translateY(${props.moveDownBy})`
    }
    return "translateY(0)"
  }};

  &:hover {
    transition: 0.2s transform ease;
    transform: translate(10px, -20px);
  }
`

const Tree = styled.img.attrs({
  src: TreeImage,
})<{ hide: boolean }>`
  ${common}
  position: fixed;
  bottom: 6vw;
  right: -4vw;
  height: 90vmin;
  transition: transform 3s ease;
  transform: ${(props) => {
    if (props.hide) {
      return "translateX(120vh)"
    }
    return "translateX(0)"
  }};

  @media (min-width: ${sizes.xl}px) {
    height: 120vh;
    right: -3vw;
  }
`

const YoutubeContainer = styled.div<{ hide: boolean }>`
  transition: 1s opacity ease;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  background-color: black;
`

const PuzzleContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  position: absolute;
`

const PuzzleSymbol = styled.img.attrs(({ src }) => ({
  src,
}))``

interface BackgroundProps {
  started: boolean
  soundOn: boolean
  snowOn: boolean
  setSoundOn: (on: boolean) => void
  moveDownBy: string
}

const Background = (props: BackgroundProps) => {
  const { started, soundOn, setSoundOn, moveDownBy, snowOn } = props

  const [previousSoundOn, setPreviousSoundOn] = useState(soundOn)
  const [showTurnip, setShowTurnip] = useState(false)
  const [showTurnipModal, setShowTurnipModal] = useState(false)
  const [showSparkleHint, setShowSparkleHint] = useState(false)
  const { isMobile } = useIsMobile()
  const { correctPuzzle, getImageAssetForPuzzleName, puzzleSolved, puzzleDisabled } = usePuzzle()

  const [showHint, setShowHint] = useState(false)

  const symbolIcon = useMemo(() => {
    if (correctPuzzle?.[1] && !puzzleSolved) {
      return getImageAssetForPuzzleName(correctPuzzle[1].name)
    }
    return undefined
  }, [correctPuzzle, getImageAssetForPuzzleName, puzzleSolved])

  const onTurnipClick = useCallback(() => {
    setShowTurnipModal(true)
    setPreviousSoundOn(soundOn)
    setSoundOn(false)
  }, [setPreviousSoundOn, soundOn, setSoundOn])

  const onDismissTurnip = useCallback(() => {
    setShowHint(false)
    setShowTurnipModal(false)
    setSoundOn(previousSoundOn)
  }, [previousSoundOn, setSoundOn])

  const onSparklesClick = useCallback(() => {
    setShowSparkleHint(true)
    setTimeout(() => {
      setShowSparkleHint(false)
    }, 5000)
  }, [])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (started) {
      timeout = setTimeout(() => {
        setShowTurnip(true)
      }, 2000)
    }
    return () => clearTimeout(timeout)
  }, [started])

  return (
    <>
      <CloudsContainer>
        <Clouds />
        <Clouds />
      </CloudsContainer>
      <Landscape started={started} />
      {!isMobile && (
        <LandscapeSparkles onClick={onSparklesClick} started={started}>
          <HintText show={showSparkleHint}>I wish I could take a closer look...</HintText>
          <GifOrWebm gifSrc={SparklesGif} videoUrl={SparklesVideo} />
        </LandscapeSparkles>
      )}
      <MidBG started={started} />
      {!isMobile && <Turnip hide={!showTurnip} onClick={onTurnipClick} moveDownBy={moveDownBy} />}
      {!isMobile && <Banner hide={!started} />}
      <Suspense fallback={null}>{snowOn && <SnowEffect />}</Suspense>
      <Tree hide={!started} />

      {/* TURNIP modal */}
      <BasicModal show={showTurnipModal} onClose={onDismissTurnip} height={354} closeButton>
        <AnimatePresence>
          {Boolean(symbolIcon && showHint) && (
            <PuzzleContainer
              transition={{
                duration: 1,
              }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
            >
              <PuzzleSymbol src={symbolIcon} />
            </PuzzleContainer>
          )}
        </AnimatePresence>
        <YoutubeContainer hide={showHint}>
          <YouTube
            videoId="tx2LXzM-Q2A"
            opts={{
              height: `${354 - 32}`,
              width: "100%",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
              },
            }}
            onError={(err) => {
              console.log(err)
            }}
            onEnd={() => {
              if (symbolIcon && !puzzleDisabled) {
                setShowHint(true)
              }
            }}
          />
        </YoutubeContainer>
      </BasicModal>
    </>
  )
}

export default Background
