import { useCallback, useRef, useState } from "react"

type Point = { x: number; y: number }
const ORIGIN = Object.freeze({ x: 0, y: 0 })

/**
 * Track the user's intended panning offset by listening to `mousemove` events
 * once the user has started panning.
 */
interface UsePanProps {
  maxXOffset?: number
  maxYOffset?: number
  scale?: number
  disabled?: boolean
}
export default function usePan(
  props?: UsePanProps,
): [Point, (pageX: number, pageY: number) => void, (pageX: number, pageY: number) => void] {
  const [panState, setPanState] = useState<Point>(ORIGIN)
  const { maxXOffset, maxYOffset, scale } = props ?? {}

  // Track the last observed mouse position on pan.
  const lastPointRef = useRef(ORIGIN)

  const pan = useCallback(
    (pageX: number, pageY: number) => {
      const lastPoint = lastPointRef.current
      const point = { x: pageX, y: pageY }
      lastPointRef.current = point

      // Find the delta between the last mouse position on `mousemove` and the
      // current mouse position.
      //
      // Then, apply that delta to the current pan offset and set that as the new
      // state.
      setPanState((panState) => {
        const delta = {
          x: lastPoint.x - point.x,
          y: lastPoint.y - point.y,
        }
        const offset = {
          x: panState.x + delta.x * (1 / (scale ?? 1)),
          y: panState.y + delta.y * (1 / (scale ?? 1)),
        }
        if (maxYOffset && offset.y > maxYOffset) {
          offset.y = maxYOffset
        }
        if (maxXOffset && offset.x > maxXOffset) {
          offset.x = maxXOffset
        }
        return offset
      })
    },
    [maxYOffset, maxXOffset, scale],
  )

  const evtListener = useCallback(
    (e: MouseEvent) => {
      pan(e.pageX, e.pageY)
    },
    [pan],
  )
  const touchEvtListener = useCallback(
    (e: TouchEvent) => {
      const touch = e.touches[0]
      pan(touch.pageX, touch.pageY)
    },
    [pan],
  )

  // Tear down listeners.
  const endPan = useCallback(() => {
    document.removeEventListener("mousemove", evtListener)
    document.removeEventListener("mouseup", endPan)
    document.removeEventListener("touchmove", touchEvtListener)
    document.removeEventListener("touchend", endPan)
  }, [evtListener, touchEvtListener])

  // Set up listeners.
  const startPan = useCallback(
    (pageX: number, pageY: number) => {
      if (props?.disabled) {
        return
      }
      document.addEventListener("mousemove", evtListener)
      document.addEventListener("touchmove", touchEvtListener)

      document.addEventListener("mouseup", endPan)
      document.addEventListener("touchend", endPan)
      lastPointRef.current = { x: pageX, y: pageY }
    },
    [props, evtListener, endPan, touchEvtListener],
  )

  return [panState, startPan, pan]
}
