import styled from "styled-components"
import ExplorersShowcaseGif from "../../assets/explorers.gif"
import ExplorersShowcaseImage from "../../assets/explorers.webm"
import GifOrWebm from "../GifOrWebm"
import { Body, Container } from "./styles"
import TitleBar from "./TitleBar"

const ExplorersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
`

const About = () => {
  return (
    <Container>
      <TitleBar title="About" />
      <Body>
        <b>Loot Explorers</b> are 8000 unique explorer characters decked out with gear found in the first 8000 genesis
        bags of loot. Putting a twist on the classic RPG style with chunky lines and juicy colors, each Explorer&apos;s
        design is influenced by the loot they carry mixed in with elements such as distinct classes & cool backdrops,
        all attached to uniquely generated name.
      </Body>
      <ExplorersContainer>
        <GifOrWebm gifSrc={ExplorersShowcaseGif} videoUrl={ExplorersShowcaseImage} />
      </ExplorersContainer>
    </Container>
  )
}

export default About
