import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import GrassLeftGif from "../assets/grass-left.gif"
import GrassLeftVid from "../assets/grass-left.webm"
import GrassRightGif from "../assets/grass-right.gif"
import GrassRightVid from "../assets/grass-right.webm"
import GifOrWebm from "./GifOrWebm"

const TRANSITION_DURATION_SECONDS = 4

const shared = css`
  position: absolute;
  pointer-events: none;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 100vw;
  transition: ${TRANSITION_DURATION_SECONDS}s transform ease;
`

const GrassLeftContainer = styled.div<{ hide: boolean }>`
  ${shared}
  bottom: calc(-4.5vw - 10px);
  transform: ${(props) => {
    if (props.hide) {
      return "translateX(-100vw)"
    }
    return "translateX(0)"
  }};
`

const GrassRightContainer = styled.div<{ hide: boolean }>`
  ${shared}
  transform: ${(props) => {
    if (props.hide) {
      return "translateX(100vw)"
    }
    return "translateX(0)"
  }};
`

interface GrassProps {
  started: boolean
}

const Grass = (props: GrassProps) => {
  const { started } = props
  const [hideGrass, setHideGrass] = useState(false)

  useEffect(() => {
    // If started, wait for transition to end and hides the grass
    let timeout: NodeJS.Timeout
    if (started && !hideGrass) {
      timeout = setTimeout(() => {
        setHideGrass(true)
      }, TRANSITION_DURATION_SECONDS * 1000)
    } else if (!started && hideGrass) {
      setHideGrass(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [started, hideGrass])

  if (hideGrass) {
    return null
  }

  return (
    <>
      <GrassLeftContainer hide={started}>
        <GifOrWebm gifSrc={GrassLeftGif} videoUrl={GrassLeftVid} />
      </GrassLeftContainer>
      <GrassRightContainer hide={started}>
        <GifOrWebm gifSrc={GrassRightGif} videoUrl={GrassRightVid} />
      </GrassRightContainer>
    </>
  )
}

export default Grass
