import { RefObject, useCallback, useEffect, useState } from "react"

type ScaleOpts = {
  direction: "up" | "down"
  interval?: number
}

const MIN_SCALE = 0.5
const MAX_SCALE = 3

/**
 * Listen for `wheel` events on the given element ref and update the reported
 * scale state, accordingly.
 */
interface UseScaleProps {
  ref: RefObject<HTMLElement | null>
  minScale: number
  maxScale: number
}
export default function useScale({ ref, minScale = MIN_SCALE, maxScale = MAX_SCALE }: UseScaleProps) {
  // Used to reset the scale when minScale changed
  const [prevMinScale, setPrevMinScale] = useState(minScale)
  const [prevMaxScale, setPrevMaxScale] = useState(maxScale)
  const [scale, setScale] = useState(minScale)

  /**
   * Update the scale by progress %.
   * eg. If minScale is 0, maxScale is 50, then progress of 0.5 with zoomType zoomIn will set the current scale of 25
   * if this function is then called again, with the same parameters, it will set the scale to 50
   * progressIncrement is a number between 0-1
   */
  const updateScaleByProgress = useCallback(
    (progressIncrement: number, zoomType: "zoomIn" | "zoomOut") => {
      const scaleAmount = (maxScale - minScale) * progressIncrement
      let newScale = zoomType === "zoomIn" ? scale + scaleAmount : scale - scaleAmount
      if (newScale > maxScale) {
        newScale = maxScale
      } else if (newScale < minScale) {
        newScale = minScale
      }
      setScale(newScale)
    },
    [scale, maxScale, minScale],
  )

  const updateScale = useCallback(
    ({ direction, interval = 0.06 }: ScaleOpts) => {
      setScale((currentScale) => {
        let scale: number

        // Adjust up to or down to the maximum or minimum scale levels by `interval`.
        if (direction === "up" && currentScale + interval < maxScale) {
          scale = currentScale + interval
        } else if (direction === "up") {
          scale = maxScale
        } else if (direction === "down" && currentScale - interval > minScale) {
          scale = currentScale - interval
        } else if (direction === "down") {
          scale = minScale
        } else {
          scale = currentScale
        }

        return scale
      })
    },
    [maxScale, minScale],
  )

  // Set up an event listener such that on `wheel`, we call `updateScale`.
  useEffect(() => {
    const listener = (e: WheelEvent) => {
      e.preventDefault()

      updateScale({
        direction: e.deltaY < 0 ? "up" : "down",
      })
    }

    // Reset scaling if needed
    if (minScale !== prevMinScale && scale < minScale) {
      setPrevMinScale(minScale)
      setScale(minScale)
    }
    if (maxScale !== prevMaxScale && scale > maxScale) {
      setPrevMaxScale(maxScale)
      setScale(maxScale)
    }

    const elem = ref.current
    elem?.addEventListener("wheel", listener)
    return () => {
      elem?.removeEventListener("wheel", listener)
    }
  }, [ref, updateScale, scale, minScale, maxScale, prevMinScale, prevMaxScale])

  return {
    scale,
    // Exposes update scale to allow any component to update the scale
    updateScaleByProgress,
  }
}
