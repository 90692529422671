import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import EarlImage from "../assets/earl-only.gif"
import GalleryImage from "../assets/icons/gallery.png"
import PastEventsImage from "../assets/icons/past-events.png"
import BigBubbleImage from "../assets/webp/earl/bubble-large.webp"
import SmallBubbleImage from "../assets/webp/earl/bubble-small.webp"
import DotsImage from "../assets/webp/earl/dots.webp"
import DownArrowImage from "../assets/webp/earl/down.webp"
import ArrowRightImage from "../assets/webp/earl/right.webp"
import SoupImage from "../assets/webp/normal-theme/deco/soup.webp"
import Steam1Image from "../assets/webp/normal-theme/deco/steam_1.webp"
import Steam2Image from "../assets/webp/normal-theme/deco/steam_2.webp"
import Steam3Image from "../assets/webp/normal-theme/deco/steam_3.webp"
import useExplorerContract from "../hooks/contracts/useExplorerContract"
import { useNavigation } from "../hooks/useNavigation"
import { usePuzzle } from "../hooks/usePuzzle"
import { useGlobalState } from "../store/state"
import { colors } from "../styles"
import sizes from "../utils/sizes"
import { clickable, clickableShrunk } from "./common/common"

const EarlContainer = styled.div<{
  started: boolean
  moveDownBy?: string
  transitionSeconds: number
}>`
  position: absolute;
  z-index: 2;
  bottom: 13.7vw;
  right: 16.6vw;
  ${(props) => {
    if (props.started) {
      return `
        transform: translate(0, calc(${props.moveDownBy || "0px"})) scale(0.8);
        transition: ${props.transitionSeconds}s transform ease;
      `
    }
    return `
    transition: 0.2s transform ease;
    transform: translate(5.5vw, 1.7vw);
  `
  }}
`

const BubbleContainer = styled.div<{ show: boolean }>`
  position: absolute;

  transition: opacity 0.4s ease, transform 0.4s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};

  pointer-events: ${({ show }) => (show ? "auto" : "none")};
`

const SMALL_BUBBLE_WIDTH_MOBILE = "35vw"
const SMALL_BUBBLE_WIDTH_DESKTOP = "17vw"
const SmallBubbleContainer = styled(BubbleContainer)`
  display: flex;
  align-items: center;
  padding-left: 5vw;
  left: -32vw;
  top: -20vw;
  width: ${SMALL_BUBBLE_WIDTH_MOBILE};
  height: calc(${SMALL_BUBBLE_WIDTH_MOBILE} * 0.58);

  @media (min-width: ${sizes.md}px) {
    padding-left: 2vw;
    left: -15vw;
    top: -8vw;
    width: ${SMALL_BUBBLE_WIDTH_DESKTOP};
    height: calc(${SMALL_BUBBLE_WIDTH_DESKTOP} * 0.58);
  }

  transform: ${({ show }) => {
    if (show) {
      return `scale(1)`
    }
    return `scale(1.3)`
  }};
`

const BIG_BUBBLE_WIDTH_MOBILE = "80vw"
const BIG_BUBBLE_WIDTH_DESKTOP = "40vw"

const BigBubbleContainer = styled(BubbleContainer)`
  display: grid;
  // grid-template-columns: 1fr auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  left: calc(-${BIG_BUBBLE_WIDTH_MOBILE} + 3vw);
  top: calc(-${BIG_BUBBLE_WIDTH_MOBILE} / 3.5);
  width: ${BIG_BUBBLE_WIDTH_MOBILE};
  height: calc(${BIG_BUBBLE_WIDTH_MOBILE} * 0.3649);
  padding-inline: 5vw;
  padding-top: 2vw;
  padding-bottom: 5vw;

  @media (min-width: ${sizes.md}px) {
    padding-inline: 2vw;
    padding-top: 1vw;
    padding-bottom: 3vw;
    left: calc(-${BIG_BUBBLE_WIDTH_DESKTOP});
    top: calc(-${BIG_BUBBLE_WIDTH_DESKTOP} / 4.6);
    width: ${BIG_BUBBLE_WIDTH_DESKTOP};
    height: calc(${BIG_BUBBLE_WIDTH_DESKTOP} * 0.3649);
  }

  transform: ${({ show }) => {
    if (show) {
      return `
        scale(1) translate(0, 0)
      `
    }
    return `scale(0) translate(50%, 50%)`
  }};
`

const BubbleNavItem = styled.button`
  ${clickableShrunk}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Bubble = styled.img.attrs<{ src: string }>(({ src }) => ({
  src,
}))`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
`

const ArrowRight = styled.img.attrs({
  src: ArrowRightImage,
})`
  width: 25%;
`

const Dots = styled.img.attrs({
  src: DotsImage,
})`
  width: 25%;
  margin-left: 3.2vw;

  @media (min-width: ${sizes.md}px) {
    margin-left: 2.2vw;
  }
`

const GalleryPastEventsImg = styled.img`
  height: 7vw;
`

const Divider = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='1' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(137,158,125)' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 2px;
  margin-top: -4px;
  margin-bottom: -8px;
`

const Text = styled.span<{ largeFont?: boolean; smallFont?: boolean }>`
  color: ${colors.darkGreen};
  font-weight: bold;
  font-size: 3.5vw;
  ${({ largeFont, smallFont }) => {
    if (largeFont) {
      return `
        font-size: 5vw;
        text-align: center;
        flex: 1;
      `
    } else if (smallFont) {
      return `
        font-size: 1.6vw;
        text-align: center;
        flex: 1;
      `
    }
    return ""
  }}

  @media (min-width: ${sizes.md}px) {
    font-size: 1.8vw;
    ${({ largeFont, smallFont }) => {
      if (largeFont) {
        return `
          font-size: 3vw;
        `
      } else if (smallFont) {
        return `
          font-size: 1.4vw;
        `
      }
      return ""
    }}
  }
`

const DownArrow = styled.img.attrs({
  src: DownArrowImage,
})`
  width: 8%;
  padding-left: 8px;
`

const EarlImageContainer = styled.img.attrs<{ width: number; started: boolean }>({
  src: EarlImage,
})<{ width: number }>`
  width: ${(props) => props.width}px;
`

const ALT_BUBBLE_WIDTH_DESKTOP = "25vw"
const AlternativeBubbleContainer = styled(BubbleContainer)<{ isClickable?: boolean }>`
  ${({ isClickable }) => {
    if (!isClickable) {
      // No hover effect
      return `
      cursor: default;
      &:hover {
        transform: scale(1) translateY(0);
      }
      `
    }
    return ""
  }}

  align-items: center;
  padding-left: 5vw;
  display: none;
  z-index: 1;

  @media (min-width: ${sizes.md}px) {
    display: flex;
    padding-left: 2vw;
    right: calc(-${ALT_BUBBLE_WIDTH_DESKTOP} + 5vw);
    top: calc(-${ALT_BUBBLE_WIDTH_DESKTOP} / 4.6);
    width: ${ALT_BUBBLE_WIDTH_DESKTOP};
    height: calc(${ALT_BUBBLE_WIDTH_DESKTOP} * 0.3649);
  }
`

const PuzzleHint = styled.img.attrs<{ show: boolean }>(({ src }) => ({
  src,
}))<{ show: boolean }>`
  position: absolute;
  left: 2vw;
  width: 60px;
  height: 60px;

  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  ${({ show }) => {
    if (show) {
      return css`
        transform: translateY(-70px);
        opacity: 1;
      `
    }
    return ""
  }}
`
const AltBubble = styled(Bubble)`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  opacity: 0.8;
`

const SoupSteamContainer = styled.div`
  pointer-events: none;
  position: absolute;
  width: 32vw;
  left: -11vw;
  top: -2.5vw;
`

const Soup = styled.img.attrs({
  src: SoupImage,
})`
  width: 100%;
`

const SoupClickableArea = styled.div`
  ${clickable}
  pointer-events: auto;
  width: 8vw;
  height: 8vw;
  left: 12.5vw;
  position: absolute;
  bottom: 11vw;
  opacity: 0.4;
`

const SteamContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -13vw;
`
const Steam = styled.img``
const steamMove = (moveBy: string) => keyframes`
0% {
  opacity: 0;
  transform: translateY(0);
}
30% {
  opacity: 1;
  transform: translateY(${moveBy});
}
50% {
  opacity: 1;
  transform: translateY(${moveBy});
}
100% {
  opacity: 0;
  transform: translateY(${moveBy});
}
`
const Steam1 = styled(Steam).attrs({
  src: Steam1Image,
})`
  animation: ${steamMove("-7vw")} 3s ease infinite;
  width: 2vw;
  margin-left: 1vw;
`
const Steam2 = styled(Steam).attrs({
  src: Steam2Image,
})`
  animation: ${steamMove("-2vw")} 2s ease infinite;
  width: 1.5vw;
  margin-left: 3vw;
`
const Steam3 = styled(Steam).attrs({
  src: Steam3Image,
})`
  animation: ${steamMove("-10vw")} 5s ease infinite;
  width: 3vw;
`

interface EarlProps {
  started: boolean
  moveDownBy?: string
  earlWidth: number
  transitionSeconds: number
}

interface EarlText {
  text: string
  largeFont?: boolean
}
type EarlTextKey = {
  [key: string]: EarlText
}

const EARL_TEXT: EarlTextKey = {
  default: {
    text: "Hello Explorer! Please connect your wallet",
  },
  mintSoon: {
    text: "Minting will begin shortly! In the meantime, be sure to check out our minting guide in our FAQ",
  },
  lootSoon: {
    text: "OG Loot holders, it's almost time to mint! Be sure to check out our minting guide in our FAQ",
  },
  whitelistSoon: {
    text: "First Explorers, it's almost time to mint! Be sure to check out our minting guide in our FAQ",
  },
  publicSoon: {
    text: "Explorers, It's almost time for the public mint! Be sure to check out our minting guide in our FAQ",
  },
  connectWalletForLoot: {
    text: "Only OG Loot holders are able to mint now! Connect your wallet to see your loot bags",
  },
  connectWalletForWhitelist: {
    text: "Only First Explorers are able to mint now! Connect your wallet too see if you're one of them",
  },
  connectWalletToMint: {
    text: "Explorers are now ready for minting! Connect your wallet now, they're waiting for you!",
  },
  mintNow: {
    text: "MINT NOW!",
    largeFont: true,
  },
  soldOut: {
    text: "That's a wrap, all Explorers have been summoned!",
    largeFont: false,
  },
}

const EARL_ALT_TEXT_WALLET_DISCONNECTED = "Hello Explorer! Please connect your wallet"

// OG LOOT MINT
const EARL_ALT_TEXT_LOOT = [
  "Hey that looks kinda rare!",
  "Wow, I'm impressed!",
  "That's some sweet loot!",
  "Woah! Sweet loot!",
  "That's going to be one great Explorer!",
  "Can't wait to see your Explorers!",
  "Hmm! I'm liking what I see!",
]
const EARL_ALT_TEXT_NO_LOOT = [
  "Not to worry! Public mint is coming really soon!",
  "That's alright, you'll get your chance really soon!",
  "Don't fret, just hold on tight for the public mint!",
]
const EARL_ALT_TEXT_FIRST_EXPLORER = [
  "GM First Explorer!",
  "It's an honor to meet you, First Explorer!",
  "Lead the way First Explorer!",
  "Onward First Explorer, guide us through!",
]
const EARL_ALT_TEXT_PUBLIC = [
  "Are you ready to summon your Explorer?",
  "It's time! Explorers roll out.",
  "Your time has come Explorer!",
]
const EARL_ALT_TEXT_SOLD_OUT = [
  "That's a wrap, all Explorers have been summoned!",
  "All Explorers have left on their merry adventures!",
]
const EARL_ALT_TEXT_TX_PENDING = [
  "Are you excited? I can't wait to see what you get!",
  "Oh boy, I sure can't wait to see em!",
  "Give it a second, they'll be here in no time!",
]
const EARL_ALT_TEXT_TX_COMPLETE = [
  "Your Explorers are all wrapped up, check back in a few days!",
  "It's done! Just a few more days and they'll be revealed!",
]

const Earl = (props: EarlProps) => {
  const [showSpeechBubble, setShowSpeechBubble] = useState(false)
  const [showBigBubble, setShowBigBubble] = useState(false)

  const [earlText, setEarlText] = useState(EARL_TEXT.default)
  const [earlAltText, setEarlAltText] = useState("")
  const [, setShowSoupScene] = useGlobalState("showSoupScene")
  const [, setShowConnectWallet] = useGlobalState("showConnectWallet")
  const [transaction] = useGlobalState("transaction")
  const [currentContentId] = useGlobalState("currentModalContentId")
  const { library, account, active } = useWeb3React()

  const { navigateTo } = useNavigation()

  const { saleStage, bags, lootLoading, proof } = useExplorerContract(library, account)

  const contentShowing = useMemo(() => Boolean(currentContentId), [currentContentId])
  const { correctPuzzle, getImageAssetForPuzzleName, puzzleSolved, puzzleDisabled } = usePuzzle()

  const [showEarlSymbol, setShowEarlSymbol] = useState(false)

  const puzzleSymbol = useMemo(() => {
    if (correctPuzzle?.[3] && !puzzleSolved) {
      return getImageAssetForPuzzleName(correctPuzzle[3].name)
    }
    return undefined
  }, [correctPuzzle, getImageAssetForPuzzleName, puzzleSolved])

  // Skip small bubble when minting
  const skipSmallBubble = useMemo(() => {
    return true
  }, [])

  // Update earl text
  useEffect(() => {
    if (active && account) {
      // SALE STARTED!
      setEarlText(EARL_TEXT.mintNow)
    } else {
      // Connect wallet
      switch (saleStage) {
        case "loot":
          setEarlText(EARL_TEXT.connectWalletForLoot)
          break
        case "whitelist":
          setEarlText(EARL_TEXT.connectWalletForWhitelist)
          break
        case "public":
          setEarlText(EARL_TEXT.connectWalletToMint)
          break
        default:
          break
      }
    }
  }, [saleStage, active, account, library])

  useEffect(() => {
    // Reset when content hidden
    if (!contentShowing) {
      setEarlAltText("")
    }
  }, [contentShowing])

  // Update earl alt-text
  useEffect(() => {
    const isMintingContent =
      currentContentId === "mintLoot" || currentContentId === "mintFirstExplorers" || currentContentId === "mintPublic"
    // IF any of mint content is opened, and wallet is not connected, show disconnected text
    if (isMintingContent && (!active || !account)) {
      setEarlAltText(EARL_ALT_TEXT_WALLET_DISCONNECTED)
      return
    }

    if (!lootLoading && isMintingContent) {
      // If has ongoing transactions, shows that instead
      if (transaction?.state === "processing") {
        setEarlAltText((prev) => {
          return EARL_ALT_TEXT_TX_PENDING.includes(prev)
            ? prev
            : EARL_ALT_TEXT_TX_PENDING[Math.floor(Math.random() * EARL_ALT_TEXT_TX_PENDING.length)]
        })
        return
      } else if (transaction?.state === "completed") {
        setEarlAltText((prev) => {
          return EARL_ALT_TEXT_TX_COMPLETE.includes(prev)
            ? prev
            : EARL_ALT_TEXT_TX_COMPLETE[Math.floor(Math.random() * EARL_ALT_TEXT_TX_COMPLETE.length)]
        })
        return
      }

      switch (currentContentId) {
        case "mintLoot":
          if (bags.length) {
            // If the previous text is already talking about users' loot,
            // say the same thing again.
            setEarlAltText((prev) => {
              return EARL_ALT_TEXT_LOOT.includes(prev)
                ? prev
                : EARL_ALT_TEXT_LOOT[Math.floor(Math.random() * EARL_ALT_TEXT_LOOT.length)]
            })
          } else {
            setEarlAltText((prev) => {
              return EARL_ALT_TEXT_NO_LOOT.includes(prev)
                ? prev
                : EARL_ALT_TEXT_NO_LOOT[Math.floor(Math.random() * EARL_ALT_TEXT_NO_LOOT.length)]
            })
          }
          break
        case "mintFirstExplorers":
          if (proof) {
            // If the previous text is already talking about users' loot,
            // say the same thing again.
            setEarlAltText((prev) => {
              return EARL_ALT_TEXT_FIRST_EXPLORER.includes(prev)
                ? prev
                : EARL_ALT_TEXT_FIRST_EXPLORER[Math.floor(Math.random() * EARL_ALT_TEXT_FIRST_EXPLORER.length)]
            })
          } else {
            setEarlAltText((prev) => {
              return EARL_ALT_TEXT_NO_LOOT.includes(prev)
                ? prev
                : EARL_ALT_TEXT_NO_LOOT[Math.floor(Math.random() * EARL_ALT_TEXT_NO_LOOT.length)]
            })
          }
          break
        case "mintPublic":
          setEarlAltText((prev) => {
            return EARL_ALT_TEXT_PUBLIC.includes(prev)
              ? prev
              : EARL_ALT_TEXT_PUBLIC[Math.floor(Math.random() * EARL_ALT_TEXT_PUBLIC.length)]
          })
          break
        default:
          break
      }
    }
  }, [currentContentId, bags, lootLoading, active, account, transaction, proof])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (props.started) {
      timeout = setTimeout(() => {
        setShowSpeechBubble(true)
      }, props.transitionSeconds)
    }
    return () => clearTimeout(timeout)
  }, [props.started, props.transitionSeconds])

  const onSmallBubbleClick = useCallback(() => {
    setShowBigBubble(true)
  }, [])

  const onBigBubbleClick = useCallback(() => {
    setShowBigBubble(false)

    if (earlText.text === EARL_TEXT.default.text) {
      setShowConnectWallet(true)
    } else if (saleStage) {
      switch (saleStage) {
        case "loot":
          navigateTo("mintLoot")
          break
        case "whitelist":
          navigateTo("mintFirstExplorers")
          break
        case "public":
          navigateTo("mintPublic")
          break
        default:
          break
      }
    }
  }, [saleStage, navigateTo, earlText, setShowConnectWallet])

  const onAlternativeBubbleClick = useCallback(() => {
    if (earlAltText === EARL_ALT_TEXT_WALLET_DISCONNECTED) {
      setShowConnectWallet(true)
    }
  }, [setShowConnectWallet, earlAltText])

  const onSecretEarlClick = useCallback(() => {
    if (!puzzleDisabled) {
      setShowEarlSymbol(true)
    }
  }, [puzzleDisabled])

  const onGalleryClick = useCallback(() => {
    navigateTo("gallery")
  }, [navigateTo])

  const onPastEventsClick = useCallback(() => {
    navigateTo("pastEvents")
  }, [navigateTo])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (showEarlSymbol) {
      timeout = setTimeout(() => {
        setShowEarlSymbol(false)
      }, 5000)
    }
    return () => clearTimeout(timeout)
  }, [showEarlSymbol])

  return (
    <EarlContainer started={props.started} moveDownBy={props.moveDownBy} transitionSeconds={props.transitionSeconds}>
      <SoupSteamContainer>
        <SteamContainer>
          <Steam1 />
          <Steam2 />
          <Steam3 />
        </SteamContainer>
        <Soup />
        <SoupClickableArea onClick={() => setShowSoupScene(true)} />
      </SoupSteamContainer>
      {Boolean(puzzleSymbol) && <PuzzleHint show={showEarlSymbol} src={puzzleSymbol} />}
      <EarlImageContainer width={props.earlWidth} onClick={onSecretEarlClick} />
      {!skipSmallBubble && (
        <SmallBubbleContainer show={showSpeechBubble && !showBigBubble && !contentShowing} onClick={onSmallBubbleClick}>
          <Bubble src={SmallBubbleImage} />
          <ArrowRight />
          <Dots />
        </SmallBubbleContainer>
      )}
      <BigBubbleContainer show={showSpeechBubble && !contentShowing}>
        <Bubble src={BigBubbleImage} />
        <BubbleNavItem onClick={onGalleryClick}>
          <GalleryPastEventsImg src={GalleryImage} />
          <Text largeFont={earlText.largeFont}>Gallery</Text>
        </BubbleNavItem>
        {/* <Divider />
        <BubbleNavItem onClick={onPastEventsClick}>
          <GalleryPastEventsImg src={PastEventsImage} />
          <Text largeFont={earlText.largeFont}>Past Event</Text>
        </BubbleNavItem> */}
      </BigBubbleContainer>

      {/* Only in 1 scenario that alternative bubble is clickable */}
      <AlternativeBubbleContainer
        isClickable={earlAltText === EARL_ALT_TEXT_WALLET_DISCONNECTED}
        show={Boolean(showSpeechBubble && earlAltText)}
        onClick={onAlternativeBubbleClick}
      >
        <AltBubble src={BigBubbleImage} />
        <Text smallFont={true}>{earlAltText}</Text>
      </AlternativeBubbleContainer>
    </EarlContainer>
  )
}

export default Earl
