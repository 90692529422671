import { Land, LandNamesType } from "../models/Map"

const bucketBaseUrl = "https://firebasestorage.googleapis.com/v0/b/lootexplorers-feff0.appspot.com/o/landing-site"

export const getLandImageUrl = (land?: Land) => {
  if (!land) return ""
  return `${bucketBaseUrl}%2Fmap%2F${land.imageName}.webp?alt=media`
}

// TODO: - Enable songs here
const AVAILABLE_SONGS: LandNamesType[] = ["Lagoona", "Magma"]
export const getLandSongUrl = (land?: Land) => {
  if (!land) return ""
  if (!AVAILABLE_SONGS.includes(land.name)) return ""
  return `${bucketBaseUrl}%2Fmap%2Faudio%2F${land.imageName}.mp3?alt=media`
}
