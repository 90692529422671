import React from "react"
import { Accordion, Form } from "react-bootstrap"
import styled from "styled-components"
import metadataAttributeSummary from "../../../data/metadata-attributes-summary.json"
import { ExplorerAttributeFilter } from "../../../hooks/useFilterExplorers"
import { ExplorerMetadataAttribute, ExplorerMetadataAttributeSummary } from "../../../models/Explorer"
import { colors } from "../../../styles"
import BasicModal from "../../common/BasicModal"
import { scrollbarStyle } from "../../common/common"

const MetadataAttributeSummary = metadataAttributeSummary as ExplorerMetadataAttributeSummary

const Header = styled.div`
  padding-bottom: 32px;
  text-align: center;
`

const Title = styled.h1`
  color: ${colors.brown};
  font-weight: bold;
  font-size: 1.5rem;
`

const Scrollable = styled.div`
  ${scrollbarStyle}
  overflow-y: auto;
`

interface GalleryFilterModalProps {
  show: boolean
  onClose: () => void
  filter: ExplorerAttributeFilter
  updateFilter: (key: ExplorerMetadataAttribute, value: string[]) => void
  removeFilter: (key: ExplorerMetadataAttribute, value: string) => void
}

const GalleryFilterModal: React.FC<GalleryFilterModalProps> = ({
  show,
  onClose,
  filter,
  updateFilter,
  removeFilter,
}) => {
  return (
    <BasicModal show={show} onClose={onClose} height={650}>
      <div className="d-flex flex-column h-100">
        <Header>
          <Title>Filter</Title>
        </Header>
        <Scrollable>
          <Accordion>
            {(Object.keys(MetadataAttributeSummary) as ExplorerMetadataAttribute[]).map((attribute) => (
              <Accordion.Item eventKey={attribute} key={attribute}>
                <Accordion.Header>{attribute}</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    {Object.keys(MetadataAttributeSummary[attribute]).map((val) => (
                      <Form.Check
                        key={val}
                        id={`${attribute}-${val}`}
                        value={val}
                        checked={filter[attribute]?.includes(val) || false}
                        label={`${val} (${MetadataAttributeSummary[attribute][val]})`}
                        onChange={(e) => {
                          e.target.checked
                            ? updateFilter(attribute, Array.from(new Set([val, ...(filter[attribute] || [])])))
                            : removeFilter(attribute, val)
                        }}
                      />
                    ))}
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Scrollable>
      </div>
    </BasicModal>
  )
}

export default GalleryFilterModal
