// Retrieve loot bags of current owner
import { BigNumber } from "@ethersproject/bignumber"
import { useCallback, useEffect } from "react"
import { Loot } from "../codegen"
import { isDevelopment } from "../config/env"
import { Bag } from "../models/Loot"
import { useGlobalState } from "../store/state"
import useLootContract from "./contracts/useLootContract"

export const useLootBags = (library?: any, ownerAddress?: string | null) => {
  const loot = useLootContract(library)
  const [loading, setLoading] = useGlobalState("bagsLoading")
  const [bags, setBags] = useGlobalState("bags")

  const load = useCallback(
    async (contract: Loot, address: string) => {
      setLoading(true)
      const balance = (await contract.balanceOf(address))?.toString()
      if (balance && !isNaN(Number(balance))) {
        // Get list of token ids belonging to owner.
        const tokenIdPromises: Promise<BigNumber>[] = []
        for (let index = 0; index < Number(balance); index++) {
          tokenIdPromises.push(contract.tokenOfOwnerByIndex(address, index))
        }

        // Now retrieve tokens belonging to owner
        const bagsPromises: Promise<Bag>[] = tokenIdPromises.map(async (idPromise) => {
          return idPromise.then(async (id) => {
            const [chest, foot, hand, head, neck, ring, waist, weapon] = await Promise.all([
              contract.getChest(id),
              contract.getFoot(id),
              contract.getHand(id),
              contract.getHead(id),
              contract.getNeck(id),
              contract.getRing(id),
              contract.getWaist(id),
              contract.getWeapon(id),
            ])
            return {
              id: id.toNumber(),
              weapon,
              chest,
              head,
              waist,
              foot,
              hand,
              neck,
              ring,
            }
          })
        })
        try {
          const bags = await Promise.all(bagsPromises)
          setBags(bags)
        } catch (error) {
          console.log(contract, address)
          console.log("ERROR RETRIEVING BAGS:", error)
          setBags([])
        }
      }
      setLoading(false)
    },
    [setBags, setLoading],
  )

  useEffect(() => {
    if (ownerAddress && loot) {
      load(loot, ownerAddress)
    }
  }, [ownerAddress, load, loot, setBags])

  return {
    loading,
    bags,
  }
}
