import styled, { css, keyframes } from "styled-components"
import { colors } from "../../styles"

export const clickable = css`
  cursor: pointer;
`

export const clickableScale = css`
  ${clickable}
  transition: 0.2s transform ease;
  &:hover {
    transform: scale(0.9);
  }
`

export const clickableShrunk = css`
  ${clickable}
  transition: 0.2s transform ease;
  &:hover {
    transform: scale(0.9) translateY(5%);
  }
`

export const crystalFloat = (floatTranslate = "-5px") => keyframes`
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(${floatTranslate});
	}
	100% {
		transform: translatey(0px);
	}
`

export const crystalRotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(-20deg);
	}
	75% {
		transform: rotate(45deg);
	}
	100% {
		transform: rotate(0deg);
	}
`

export const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.brown};
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.brown}1A;
    border-radius: 100px;
  }
`

export const BaseAnchor = styled.a`
  text-decoration: none;
`

export const Title = styled.span<{
  color?: string
  fontSize?: number
  lineHeight?: number
  letterSpacing?: number
  normalCased?: boolean
  fontWeight?: number
}>`
  color: ${(props) => props.color || colors.brown};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "normal"};
  ${(props) => (!props.normalCased ? `text-transform: uppercase;` : ``)}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px;` : ``)}
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight}px;` : ``)}
  ${(props) => (props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px;` : "")}
`

export const Subtitle = styled.span<{
  color?: string
  fontSize?: number
  lineHeight?: number
  letterSpacing?: number
  normalCased?: boolean
  fontWeight?: number
}>`
  color: ${(props) => props.color || colors.brown};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: ${(props) => props.fontSize || 12}px;
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight}px;` : ``)}
  letter-spacing: ${(props) => props.letterSpacing || 1.5}px;
  ${(props) => (!props.normalCased ? `text-transform: uppercase;` : ``)}
`

export const PrimaryText = styled.span<{
  color?: string
  fontSize?: number
  lineHeight?: number
  fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || 16}px;
  line-height: ${(props) => props.lineHeight || 24}px;
  color: ${(props) => props.color || colors.brown};
`

export const SecondaryText = styled.span<{
  color?: string
  fontSize?: number
  lineHeight?: number
  fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || 14}px;
  line-height: ${(props) => props.lineHeight || 20}px;
  color: ${(props) => props.color || colors.darkGreen};
`
