import { useNavigation } from "../hooks/useNavigation"
import MapScene from "./content/fullscreen/MapScene/MapScene"
import ProfileCropperScene from "./content/fullscreen/ProfileCropper/ProfileCropperScene"

export interface IModalContentPage {
  id: string
  content: JSX.Element
}

export const fullscreenContentIds = ["profile-pic", "map"] as const
export type FullscreenContentIdType = typeof fullscreenContentIds[number]
type ContentIdsToContentPage = {
  [key in FullscreenContentIdType]: IModalContentPage
}

export const contents: ContentIdsToContentPage = {
  "profile-pic": {
    id: "profile-pic",
    content: <ProfileCropperScene />,
  },
  map: {
    id: "map",
    content: <MapScene />,
  },
}

const FullscreenContent = () => {
  const { currentFullscreenContentId, navigateToFullscreen } = useNavigation()
  return currentFullscreenContentId ? contents[currentFullscreenContentId]?.content : null
}

export default FullscreenContent
