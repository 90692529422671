import { useCallback } from "react"
import { FullscreenContentIdType } from "../components/FullscreenContent"
import { ContentIdType } from "../components/ModalContentPage"
import { useGlobalState } from "../store/state"

export const useNavigation = () => {
  const [browserHistory] = useGlobalState("browserHistory")
  const [currentModalContentId, setCurrentModalContentId] = useGlobalState("currentModalContentId")
  const [currentFullscreenContentId, setCurrentFullscreenContentId] = useGlobalState("currentFullscreenContentId")

  const navigateTo = useCallback(
    (modalId?: ContentIdType) => {
      setCurrentModalContentId(modalId)
      setCurrentFullscreenContentId(undefined)
      const newLocation = modalId ? `/${modalId}` : "/"
      if (browserHistory.location.pathname !== newLocation) {
        browserHistory.push(newLocation)
      }
    },
    [setCurrentModalContentId, browserHistory, setCurrentFullscreenContentId],
  )

  const navigateToFullscreen = useCallback(
    (id?: FullscreenContentIdType) => {
      setCurrentFullscreenContentId(id)
      setCurrentModalContentId(undefined)
      const newLocation = id ? `/${id}` : "/"
      if (browserHistory.location.pathname !== newLocation) {
        browserHistory.push(newLocation)
      }
    },
    [setCurrentFullscreenContentId, browserHistory, setCurrentModalContentId],
  )

  return {
    currentModalContentId,
    currentFullscreenContentId,
    navigateTo,
    navigateToFullscreen,
  }
}
