import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { BackendBaseURL } from "../config/constants"
import { useGlobalState } from "../store/state"

const useFetchDiscordConnect = () => {
  const { active, account } = useWeb3React()
  const [, setDiscordConnect] = useGlobalState("discordConnect")
  const [, setCounter] = useState(0)

  useEffect(() => {
    if (!active) {
      setDiscordConnect({
        loading: false,
        data: { connected: false },
      })
      return
    }

    const multicall = async () => {
      /**
       * Set loading and keep track of counter
       */
      let currentCounter: number
      setDiscordConnect((prev) => ({ ...prev, loading: true }))
      setCounter((counter) => {
        currentCounter = counter + 1
        return currentCounter
      })

      const { data } = await axios.get(`${BackendBaseURL}/discord`, {
        params: { publicAddress: account },
      })

      setCounter((counter) => {
        if (counter === currentCounter) {
          setDiscordConnect({
            loading: false,
            data,
          })
        }

        return counter
      })
    }

    multicall()
  }, [account, active, setDiscordConnect])
}

export default useFetchDiscordConnect
