// Header at the top of the screen.
//Contains the logo, toggle, and some navigation items
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { useCallback, useMemo, useRef } from "react"
import styled, { css } from "styled-components"
import AboutImage from "../assets/icons/about.webp"
import GenericIconImage from "../assets/icons/button.webp"
import DiscordAddImage from "../assets/icons/discord-add.png"
import { ReactComponent as ErrorIcon } from "../assets/icons/error.svg"
import FAQImage from "../assets/icons/faq.webp"
import LoreImage from "../assets/icons/lore.webp"
import SnowOffImage from "../assets/icons/snow-off.webp"
import SnowOnImage from "../assets/icons/snow-on.webp"
import SoundOffImage from "../assets/icons/sound-off.webp"
import SoundOnImage from "../assets/icons/sound-on.webp"
import WalletDisconnectedIcon from "../assets/icons/wallet/wallet-offline.png"
import WalletConnectedIcon from "../assets/icons/wallet/wallet-online.png"
import LogoImage from "../assets/webp/logo.webp"
import { socialInfos, socials } from "../config/info"
import useIsMobile from "../hooks/useIsMobile"
import { useNavigation } from "../hooks/useNavigation"
import { useGlobalState } from "../store/state"
import { colors } from "../styles"
import sizes from "../utils/sizes"
import { shortenAddress } from "../utils/strings"
import { Button } from "./common/Button"
import { clickable, clickableShrunk } from "./common/common"
import Logo from "./Logo"
import { ContentIdType } from "./ModalContentPage"

const ICON_WIDTH = "60px"

const headerItems = ["discord"] as const
type HeaderItem = typeof headerItems[number]

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 8px;
`

const BottomSection = styled.div<{ hide: boolean; bottomBarHeight: number }>`
  pointer-events: auto;
  position: relative;
  height: ${(props) => props.bottomBarHeight}px;

  transition: 0.2s all ease;
  transition-delay: 1s;
  transform: ${({ hide }) => {
    if (hide) {
      return "translateY(140px)"
    }
    return "translateY(0)"
  }};
`

const Bar = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 0.9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${colors.green};
`

interface BarLeftProps {
  right: number
  borderWidth: number
}
const BarBorderLeft = styled.div<BarLeftProps>`
  position: absolute;
  left: 0;
  right: ${(props) => props.right}px;
  top: 0;
  bottom: 0;
  border-top: ${(props) => props.borderWidth}px solid ${colors.darkGreen};
`

interface BarRightProps {
  width: number
  borderWidth: number
}
const BarBorderRight = styled.div<BarRightProps>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${(props) => props.width}px;
  border-top: ${(props) => props.borderWidth}px solid ${colors.darkGreen};
`

const NavElements = styled.div``

const Hideable = styled.div<{ hide: boolean }>`
  transition: 0.5s all ease;

  transform: ${({ hide }) => {
    if (hide) {
      return "translateX(100px)"
    }
    return "translateX(0)"
  }};
`

const SocialsContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-left: 32px;

  @media (max-width: ${sizes.md}px) {
    padding-left: 8px;
  }
`

const IconContainer = styled.div`
  ${clickableShrunk}
  margin-bottom: 8px;
  pointer-events: auto;
`

const ConnectWalletSoundContainer = styled.div`
  display: flex;
`

const ConnectWalletButton = styled(Button)`
  margin-right: 8px;
  margin-top: 3px;
`

const SocialItem = styled.a`
  margin-right: 16px;
`

const WalletStatusIcon = styled.img.attrs(({ src }) => ({ src }))`
  width: 24px;
  height: 24px;
  margin-left: 4px;
`

interface HeaderProps {
  started?: boolean
  soundOn: boolean
  toggleSound: () => void
  snowOn: boolean
  toggleSnow: () => void
}

const SoundIcon = styled.img.attrs<{ soundOn: boolean }>((props) => ({
  src: props.soundOn ? SoundOnImage : SoundOffImage,
}))<{ soundOn: boolean }>`
  width: ${ICON_WIDTH};
  height: ${ICON_WIDTH};
`

const SnowIcon = styled.img.attrs<{ snowOn: boolean }>((props) => ({
  src: props.snowOn ? SnowOnImage : SnowOffImage,
}))<{ snowOn: boolean }>`
  width: ${ICON_WIDTH};
  height: ${ICON_WIDTH};
`

const NavIcon = styled.img.attrs<{ src: string }>(({ src }) => ({
  src,
}))<{ src: string }>`
  width: ${ICON_WIDTH};
  height: ${ICON_WIDTH};
`

const GenericIconContainer = styled.div`
  width: ${ICON_WIDTH};
  height: ${ICON_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGreen};
  font-weight: bold;
`
const GenericIconBg = styled.img.attrs({
  src: GenericIconImage,
})`
  width: 100%;
  position: absolute;
  z-index: -1;
`

const MobileLogoIcon = styled.img.attrs<{ started: boolean }>({
  src: LogoImage,
})<{ started: boolean }>`
  ${({ started }) => (started ? clickable : "")}
  pointer-events: auto;
  width: 100%;

  opacity: ${({ started }) => (started ? "1" : "0")};
`

const MobileLogoContainer = styled.div<{ hide: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 10vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 0.4s ease;
  opacity: ${({ hide }) => (hide ? "0" : "1")};
`
const InnerMobileLogoContainer = styled.div`
  width: 50vmin;
`
const MobileSocialContainer = styled.div<{ show?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;

  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
`

const MobileSocialIcon = styled.a<{ show?: boolean }>`
  ${({ show }) =>
    show
      ? clickable
      : css`
          cursor: default;
        `}
  padding-left: 8px;
  padding-right: 8px;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
`

const MobileText = styled.p`
  text-align: center;
  color: ${colors.brown};
  padding: 8px;
  padding-top: 16px;
  font-size: 0.8rem;
  font-weight: bold;
`

const LOGO_REMAINDER = 8
const BORDER_WIDTH = 3

const Header = (props: HeaderProps) => {
  const { started, soundOn, toggleSound } = props
  const { error, active, account } = useWeb3React()
  const { navigateTo, currentModalContentId, currentFullscreenContentId } = useNavigation()
  const [, setShowConnectWallet] = useGlobalState("showConnectWallet")
  const [currentModalContent] = useGlobalState("currentModalContentId")
  const [showTelescopeScene] = useGlobalState("showTelescopeScene")
  const [showSoupScene] = useGlobalState("showSoupScene")
  const contentShowing = useMemo(
    () => Boolean(currentModalContent || showTelescopeScene || showSoupScene),
    [currentModalContent, showTelescopeScene, showSoupScene],
  )

  const availableSocials = useRef(socials.filter((name) => !socialInfos[name].hide)).current
  const { isMobile, screenWidth } = useIsMobile()

  const LOGO_WIDTH = isMobile ? 52 : 80
  const LOGO_PADDING = isMobile ? 4 : 8
  const BOTTOM_BAR_HEIGHT = isMobile ? 40 : 50
  const socialIconWidth = isMobile ? 24 : 35

  const onWalletClick = useCallback(() => {
    setShowConnectWallet(true)
  }, [setShowConnectWallet])

  const onLogoClicked = useCallback(() => {
    navigateTo(undefined)
  }, [navigateTo])

  const SoundButton = useMemo(() => {
    return (
      <IconContainer>
        <SoundIcon soundOn={soundOn} onClick={toggleSound} />
      </IconContainer>
    )
  }, [soundOn, toggleSound])

  // Hide snow button after Christmas
  // const SnowButton = useMemo(() => {
  //   return (
  //     <IconContainer>
  //       <SnowIcon snowOn={snowOn} onClick={toggleSnow} />
  //     </IconContainer>
  //   )
  // }, [snowOn, toggleSnow])

  const Wallet = useMemo(() => {
    let text = "Connect Wallet"
    if (active && account) {
      text = shortenAddress(account)
    }
    return (
      <IconContainer>
        <ConnectWalletButton onClick={onWalletClick}>
          {text}
          {Boolean(error && error instanceof UnsupportedChainIdError) ? (
            <ErrorIcon width={20} height={20} style={{ marginLeft: "4px" }} />
          ) : Boolean(active && account) ? (
            <WalletStatusIcon src={WalletConnectedIcon} />
          ) : (
            <WalletStatusIcon src={WalletDisconnectedIcon} />
          )}
        </ConnectWalletButton>
      </IconContainer>
    )
  }, [onWalletClick, account, active, error])

  const renderMobileSocials = useCallback(
    (show?: boolean) => {
      return (
        <MobileSocialContainer show={show}>
          {availableSocials.map((socialName) => {
            const info = socialInfos[socialName]
            const width = screenWidth * 0.5 * 0.2
            return (
              info.alternativeIcon && (
                <MobileSocialIcon
                  key={socialName}
                  show={show}
                  href={info.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <info.alternativeIcon key={socialName} width={width} height={width} />
                </MobileSocialIcon>
              )
            )
          })}
        </MobileSocialContainer>
      )
    },
    [availableSocials, screenWidth],
  )

  const renderNavItem = useCallback(
    (title: string, contentId: ContentIdType) => {
      const onClick = () => {
        navigateTo(contentId)
      }

      let Elem = (
        <GenericIconContainer onClick={onClick}>
          <GenericIconBg />
          {title}
        </GenericIconContainer>
      )
      switch (contentId) {
        case "faq":
          Elem = <NavIcon src={FAQImage} onClick={onClick} />
          break
        case "lore":
          Elem = <NavIcon src={LoreImage} onClick={onClick} />
          break
        case "about":
          Elem = <NavIcon src={AboutImage} onClick={onClick} />
          break
        case "discord":
          Elem = <NavIcon src={DiscordAddImage} onClick={onClick} />
          break
        default:
          break
      }

      return <IconContainer key={title}>{Elem}</IconContainer>
    },
    [navigateTo],
  )

  const renderNavElements = useCallback(() => {
    return (
      <NavElements>
        <Hideable hide={Boolean(currentModalContentId)}>
          {[renderNavItem("FAQ", "faq"), renderNavItem("Discord", "discord")]}
          {isMobile && [renderNavItem("Lore", "lore"), renderNavItem("About", "about")]}
        </Hideable>
      </NavElements>
    )
  }, [isMobile, currentModalContentId, renderNavItem])

  const renderSocialIcons = useCallback(() => {
    const socialElems = availableSocials.map((socialName) => {
      const info = socialInfos[socialName]
      return (
        <SocialItem key={socialName} href={info.url} target="_blank" rel="noreferrer noopener">
          <info.icon width={socialIconWidth} height={socialIconWidth} />
        </SocialItem>
      )
    })
    return <SocialsContainer>{socialElems}</SocialsContainer>
  }, [socialIconWidth, availableSocials])

  return (
    <Container>
      <TopSection>
        <ConnectWalletSoundContainer>
          {started && Wallet}
          {started && SoundButton}
          {started && !currentFullscreenContentId}
        </ConnectWalletSoundContainer>

        {isMobile && !currentFullscreenContentId && (
          <MobileLogoContainer hide={contentShowing}>
            <InnerMobileLogoContainer>
              <MobileLogoIcon started={Boolean(started && !contentShowing)} />
              {renderMobileSocials(started && !contentShowing)}
              {started && <MobileText>Please view on a desktop browser for the best experience.</MobileText>}
            </InnerMobileLogoContainer>
          </MobileLogoContainer>
        )}

        {started && !currentFullscreenContentId && renderNavElements()}
      </TopSection>

      {/* BOTTOM BAR */}
      {!isMobile && (
        <BottomSection hide={!started} bottomBarHeight={BOTTOM_BAR_HEIGHT}>
          <Bar>
            <BarBorderLeft right={LOGO_WIDTH + LOGO_PADDING * 2 + LOGO_REMAINDER} borderWidth={BORDER_WIDTH} />
            <BarBorderRight width={LOGO_REMAINDER} borderWidth={BORDER_WIDTH} />
          </Bar>
          {renderSocialIcons()}
          <Logo
            onLogoClicked={onLogoClicked}
            bottomBarHeight={BOTTOM_BAR_HEIGHT}
            logoMetrics={{
              remainder: LOGO_REMAINDER,
              borderWidth: BORDER_WIDTH,
              logoWidth: LOGO_WIDTH,
              logoPadding: LOGO_PADDING,
              logoBottom: isMobile ? 10 : 12,
              circleHeight: isMobile ? 16 : 32,
            }}
          />
        </BottomSection>
      )}
    </Container>
  )
}

export default Header
