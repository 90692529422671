import { useWeb3React } from "@web3-react/core"
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion"
import { useEffect, useState } from "react"
import styled from "styled-components"
import BorderImage from "../../../../assets/telescope/border.png"
import { useExplorerSubgraph } from "../../../../hooks/useExplorerSubgraph"
import { colors } from "../../../../styles"
import sizes from "../../../../utils/sizes"
import ExplorerItems from "./ExplorerItems"

const BG_ANIM_DURATION = 0.2
const ANIM_DURATION = 0.5

const Container = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color ${BG_ANIM_DURATION}s ease;
  background-color: ${({ show }) => {
    if (show) {
      return colors.soupBg
    }
    return "transparent"
  }};
`

const BorderContainer = styled(motion.div)`
  position: absolute;
  width: 50vw;
  height: 10vw;
  bottom: 60px;

  max-width: 500px;
  max-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${sizes.md}px) {
    width: 80vw;
    height: 16vw;
    bottom: 10px;
  }
`
const BorderBG = styled.img.attrs({
  src: BorderImage,
})`
  position: absolute;
  width: 100%;
  height: 100%;
`
const Text = styled(motion.span)`
  z-index: 1;
  color: ${colors.darkGreen};
  font-size: 0.7rem;
  font-weight: bold;
  margin: 10px 40px;
  text-align: center;

  @media (min-width: ${sizes.md}px) {
    font-size: 1rem;
  }
`

const typicalFadeAnimProps: HTMLMotionProps<any> = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const ProfileCropperScene = () => {
  const { account } = useWeb3React()
  const { explorers, loading } = useExplorerSubgraph(account ?? undefined)
  const [showTransition, setShowTransition] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTransition(true)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Container show={showTransition}>
      {account && <ExplorerItems key={JSON.stringify(explorers)} explorers={explorers} />}
      <AnimatePresence>
        {!account ? (
          <BorderContainer key="connectWallet" {...typicalFadeAnimProps}>
            <BorderBG />
            <Text>PLEASE CONNECT YOUR WALLET</Text>
          </BorderContainer>
        ) : loading ? (
          <BorderContainer key="loading" {...typicalFadeAnimProps}>
            <BorderBG />
            <Text>LOADING YOUR EXPLORERS...</Text>
          </BorderContainer>
        ) : (
          <BorderContainer key="loaded" {...typicalFadeAnimProps}>
            <BorderBG />
            <Text>CLICK TO DOWNLOAD A PROFILE PIC READY EXPLORER!</Text>
          </BorderContainer>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default ProfileCropperScene
