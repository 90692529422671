import CryptoJS from "crypto-js"
import { CompletedPuzzle } from "../models/Puzzle"

const LAST_LAUNCHED_KEY = "LAST_LAUNCHED"
const PUZZLE_KEY = "PUZZLE1-3"
const SOUND_PREFERENCE_KEY = "SOUND_PREFERENCE"
const SIWE_TOKEN_PREFIX_KEY = "SIWE"

const { REACT_APP_ENCRYPTION_KEY } = process.env

export const isFirstLaunch = () => {
  return !getLastLaunchedDate()
}

export const getLastLaunchedDate = () => {
  const lastLaunched = localStorage.getItem(LAST_LAUNCHED_KEY)
  if (lastLaunched) {
    return new Date(lastLaunched)
  }
  return undefined
}

export const updateLastLaunchedDate = () => {
  const date = new Date()
  localStorage.setItem(LAST_LAUNCHED_KEY, date.toISOString())
  return date
}

// Encrypted puzzle
interface StoredPuzzle {
  puzzle: CompletedPuzzle
  solved: boolean
  discordName?: string
}

export const getDecryptedPuzzle = () => {
  try {
    const str = localStorage.getItem(PUZZLE_KEY)
    if (str) {
      const storedPuzzle: StoredPuzzle = JSON.parse(
        CryptoJS.AES.decrypt(str, REACT_APP_ENCRYPTION_KEY ?? "").toString(CryptoJS.enc.Utf8),
      )
      return storedPuzzle
    }
    return undefined
  } catch (err) {
    /**
     * At one point, REACT_APP_ENCRYPTION_KEY had been changed, and therefore we need to clear
     * the encrypted puzzle so that it does not result in error
     **/
    clearPuzzle()
    return undefined
  }
}

export const clearPuzzle = () => {
  localStorage.removeItem(PUZZLE_KEY)
}

export const storeAndEncryptPuzzle = (puzzle: CompletedPuzzle, solved = false, discordName?: string) => {
  const puzzleSolved: StoredPuzzle = {
    puzzle,
    solved,
    discordName,
  }
  // Encrypt puzzle
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(puzzleSolved), REACT_APP_ENCRYPTION_KEY ?? "").toString()
  localStorage.setItem(PUZZLE_KEY, encrypted)
  return puzzle
}

/**
 * Return true if prefer sound on, false if not.
 * Default is true
 */
export const getSoundPreferences = () => {
  const soundOn = localStorage.getItem(SOUND_PREFERENCE_KEY)
  return soundOn === null ? true : soundOn === "1"
}

export const updateSoundPreferences = (soundOn: boolean) => {
  localStorage.setItem(SOUND_PREFERENCE_KEY, soundOn ? "1" : "0")
  return soundOn
}

export const getSiweToken = (account: string) => {
  return localStorage.getItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`)
}

export const setSiweToken = (account: string, token: string) => {
  localStorage.setItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`, token)

  return token
}

export const clearSiweToken = (account: string) => {
  localStorage.removeItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`)
}
