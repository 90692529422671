export const isIos = () => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

// DETECT BROWSERS
const userAgentString = navigator.userAgent
export const isChrome = userAgentString.indexOf("Chrome") > -1
export const isFirefox = userAgentString.indexOf("Firefox") > -1
export const isSafari = userAgentString.indexOf("Safari") > -1 && !isChrome
