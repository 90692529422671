import { motion } from "framer-motion"
import React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import CloseButtonImage from "../../assets/icons/button-close.webp"
import PageBottomImage from "../../assets/webp/page-bottom.webp"
import PageTopImage from "../../assets/webp/page-top.webp"
import { colors } from "../../styles"
import sizes from "../../utils/sizes"
import { clickableShrunk } from "./common"

const StyledModal = styled(Modal)<{ height: number }>`
  background-color: rgba(0, 0, 0, 0.6);

  .modal-dialog {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    transition: min-height 0.25s;
    min-height: ${(props) => props.height}px;
    overflow: visible;
    background-color: transparent;
    border: none;
  }
`

const ModalContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 16px;
`
const CloseButton = styled.img.attrs({
  src: CloseButtonImage,
})`
  ${clickableShrunk}
  width: 60px;
  height: 60px;

  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 1;

  @media (max-width: ${sizes.sm}px) {
    width: 40px;
    height: 40px;
    top: -10px;
    right: -10px;
  }
`

const PageBg = styled.div`
  position: absolute;
  width: 108%;
  height: 108%;
  left: -4%;
  top: -4%;
`

const PageImage = styled.img`
  width: 100%;
  height: 50%;
`
const PageTop = styled(PageImage).attrs({ src: PageTopImage })``
const PageBottom = styled(PageImage).attrs({ src: PageBottomImage })``

const PageBgBorder = styled.div`
  position: absolute;
  bottom: -5%;
  left: -0.05%;
  height: 1%;
  width: 100.25%;
  background-color: ${colors.brown};
`

const ChildrenContainer = styled.div`
  z-index: 1;
  height: 100%;
`

interface BasicModalProps {
  show: boolean
  height: number
  onClose: () => void
  closeButton?: boolean
  backButton?: {
    onClick: () => void
  }
}

const BasicModal: React.FC<BasicModalProps> = ({ show, height, closeButton, onClose, children }) => (
  <StyledModal show={show} centered height={height} onHide={onClose}>
    <Modal.Body>
      <ModalContent>
        <PageBg>
          <PageTop />
          <PageBottom />
        </PageBg>
        <ChildrenContainer>{children}</ChildrenContainer>
      </ModalContent>
    </Modal.Body>
    {closeButton && <CloseButton onClick={onClose} />}
  </StyledModal>
)

export default BasicModal
