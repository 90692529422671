import { useCallback, useEffect, useMemo, useRef } from "react"
import ReactTooltip from "react-tooltip"
import styled, { css, keyframes } from "styled-components"
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg"
import ArrowClose from "../../../../assets/webp/map/arrow-close.webp"
import ArrowOpen from "../../../../assets/webp/map/arrow-open.webp"
import CannonCoastPreview from "../../../../assets/webp/map/land-images/cannon-coast-small.webp"
import FrostennPreview from "../../../../assets/webp/map/land-images/frostenn-small.webp"
import LagoonaPreview from "../../../../assets/webp/map/land-images/lagoona-small.webp"
import MagmaPreview from "../../../../assets/webp/map/land-images/magma-small.webp"
import MerladePreview from "../../../../assets/webp/map/land-images/merlade-small.webp"
import NoctusPreview from "../../../../assets/webp/map/land-images/noctus-small.webp"
import VioletEversidePreview from "../../../../assets/webp/map/land-images/violet-everside-small.webp"
import { landDescription } from "../../../../config/info"
import { useLast } from "../../../../hooks/useLast"
import { LandNamesType, Lands } from "../../../../models/Map"
import { colors } from "../../../../styles"
import { getLandImageUrl } from "../../../../utils/cdn"
import sizes from "../../../../utils/sizes"
import { clickable, scrollbarStyle } from "../../../common/common"
import ProgressiveImage from "../../../common/ProgressiveImage"

const Container = styled.div<{ hide: boolean }>`
  cursor: auto;
  display: flex;
  position: absolute;
  z-index: 1;
  right: 16px;
  left: 16px;
  top: 80px;
  bottom: 16px;
  color: ${colors.brown2};

  @media (min-width: ${sizes.md}px) {
    bottom: 88px;
    left: unset;
    width: 30vw;
    min-width: 350px;
    max-width: 550px;
  }

  transition: transform 0.4s ease;
  ${({ hide }) => {
    if (hide) {
      return css`
        transform: translateX(95%);
      `
    }
    return ""
  }}
`
const Arrow = styled.img`
  position: absolute;
  z-index: 1;
  width: 100px;
  left: -50px;
  height: 100%;
  object-fit: contain;
  ${clickable}
  &:hover {
    opacity: 0.6;
  }
`
const Content = styled.div<{ hide: boolean }>`
  ${scrollbarStyle}
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${colors.lightGray2}ED;

  // Additional padding
  padding-bottom: 10vh;

  transition: opacity 0.4s ease;
  ${({ hide }) => {
    if (hide) {
      return css`
        opacity: 0;
      `
    }
    return ""
  }}
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0 8px 8px;
  text-transform: uppercase;
`

const ColorIndicator = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color ?? colors.darkGreen};
`

const LandNameLabel = styled.div<{ hasCredits?: boolean }>`
  font-size: 1.2rem;
  margin-left: 10px;
  flex: 1;
  ${({ hasCredits }) =>
    hasCredits &&
    css`
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-thickness: 1.5px;
      text-underline-offset: 2px;
    `}
`

export const fadeIn = keyframes`
	to {
    opacity: 0.6;
  }
`

const ImageContainer = styled.div<{ animate?: boolean }>`
  ${({ animate }) => {
    if (animate) {
      return css`
        opacity: 1;
        animation: ${fadeIn} 1s infinite alternate;
      `
    }
    return ""
  }}
`

const ListenContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  padding-bottom: 0;
`

const ListenButton = styled.button<{ disabledState?: boolean }>`
  display: flex;
  align-items: center;
  color: ${colors.brown2};
  background-color: rgba(0, 0, 0, 0);
  padding: 0 8px;

  opacity: ${(props) => (props.disabledState ? 0.4 : 1)};
`

const Text = styled.div`
  padding: 16px 8px 48px 48px;
`

interface LandMenuProps {
  containerRef?: React.Ref<HTMLDivElement>
  selectedItem?: {
    land: LandNamesType
    // Optionally provides a landmarkId to show description for that land mark instead
    landmarkId?: string
  }
  onSelectItem?: (item?: { land: LandNamesType; landmarkId?: string }) => void
  onPlaySong?: () => void
  canPlaySong?: boolean
}

const LandMenu = (props: LandMenuProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const previousLand = useLast(props.selectedItem)

  const imageWidth = containerRef.current?.offsetWidth ?? 0
  const imageHeight = imageWidth * 0.5625

  // Stop listeners from getting picked up by parents
  useEffect(() => {
    const fakeListener = (e: Event) => {
      e.stopPropagation()
    }

    const container = containerRef.current
    container?.addEventListener("mousemove", fakeListener)
    container?.addEventListener("touchmove", fakeListener)
    container?.addEventListener("wheel", fakeListener)

    return () => {
      container?.removeEventListener("mousemove", fakeListener)
      container?.removeEventListener("touchmove", fakeListener)
      container?.removeEventListener("wheel", fakeListener)
    }
  }, [])

  // Scrolls to top whenever land changes
  useEffect(() => {
    if (props.selectedItem && props.selectedItem !== previousLand) {
      contentRef.current?.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [props.selectedItem, previousLand])

  const onArrowClick = useCallback(() => {
    // Hide menu if have selected land.
    if (props.selectedItem) {
      props.onSelectItem?.(undefined)
    } else if (previousLand) {
      props.onSelectItem?.(previousLand)
    } else {
      // Default to open Frostenn
      props.onSelectItem?.({
        land: "Frostenn",
      })
    }
  }, [props, previousLand])

  const displayItem = useMemo(() => props.selectedItem ?? previousLand, [props, previousLand])
  const currentLandmark = useMemo(() => {
    if (displayItem && displayItem?.landmarkId) {
      const land = Lands[displayItem.land].landmarks.find((l) => l.id === displayItem.landmarkId)
      return land
    }
    return undefined
  }, [displayItem])

  const landNameAndCredits: {
    name: string
    credits?: string
  } = useMemo(() => {
    if (!displayItem) {
      return {
        name: "",
      }
    }
    const land = Lands[displayItem.land]
    if (currentLandmark) {
      return {
        name: `${currentLandmark.name} (${land.fullName})`,
        credits: currentLandmark.credits,
      }
    }
    return {
      name: land.fullName,
    }
  }, [displayItem, currentLandmark])

  const descriptions = useMemo(() => {
    if (!displayItem) {
      return ""
    }
    if (currentLandmark) {
      return currentLandmark.description.map((desc) => <p key={desc}>{desc}</p>)
    }
    return landDescription[displayItem.land].map((desc) => <p key={desc}>{desc}</p>)
  }, [displayItem, currentLandmark])

  const previewLandImage = useMemo(() => {
    switch (displayItem?.land) {
      case "Cannon Coast":
        return CannonCoastPreview
      case "Shattered Bone":
        return CannonCoastPreview
      case "Frostenn":
        return FrostennPreview
      case "Lagoona":
        return LagoonaPreview
      case "Magma":
        return MagmaPreview
      case "Merlade":
        return MerladePreview
      case "Pollen":
        return MerladePreview
      case "Violet Everside":
        return VioletEversidePreview
      case "Noctus":
        return NoctusPreview
      case "Underlune":
        return LagoonaPreview
      default:
        return null
    }
  }, [displayItem])

  return (
    <Container ref={containerRef} hide={!props.selectedItem}>
      <ReactTooltip key={landNameAndCredits.name} />

      <Arrow onClick={onArrowClick} src={props.selectedItem ? ArrowClose : ArrowOpen} />
      <Content ref={contentRef} hide={!props.selectedItem}>
        <Title>
          <ColorIndicator color={displayItem ? Lands[displayItem.land].color : undefined} />
          <LandNameLabel
            data-tip={landNameAndCredits.credits ? `Credits: ${landNameAndCredits.credits}` : undefined}
            hasCredits={!!landNameAndCredits.credits}
          >
            {landNameAndCredits.name}
          </LandNameLabel>
        </Title>
        <ImageContainer
          style={{
            width: `${imageWidth}px`,
            height: `${imageHeight}px`,
            minWidth: `${imageWidth}px`,
            minHeight: `${imageHeight}px`,
          }}
        >
          <ProgressiveImage
            alt={displayItem?.landmarkId ?? displayItem?.land}
            src={getLandImageUrl(displayItem ? Lands[displayItem.land] : undefined)}
            overlaySrc={previewLandImage}
          />
        </ImageContainer>
        <ListenContainer>
          <ListenButton disabled={!props.canPlaySong} disabledState={!props.canPlaySong} onClick={props.onPlaySong}>
            <PlayIcon width={16} height={16} style={{ marginRight: "4px" }} />
            {!props.canPlaySong ? "Coming soon" : "Listen"}
          </ListenButton>
        </ListenContainer>
        <Text>{descriptions}</Text>
      </Content>
    </Container>
  )
}

export default LandMenu
