import React from "react"
import styled from "styled-components"
import LoaderImage from "../../assets/loading.gif"

const Container = styled.div``
const Image = styled.img.attrs({
  src: LoaderImage,
})`
  width: 100%;
`
const Text = styled.p`
  text-align: center;
`

interface LoaderProps {
  containerStyle?: React.CSSProperties
}

const Loader = (props: LoaderProps) => {
  return (
    <Container style={props.containerStyle}>
      <Image />
      <Text>Loading...</Text>
    </Container>
  )
}

export default Loader
