import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { isDevelopment, supportedChainIDs } from "../config/env"

export const injectedConnector = new InjectedConnector({
  supportedChainIds: supportedChainIDs,
})

/**
 * A bug causes wallet connect connector to stuck forever upon second invoke
 * Possibly workaround as getting new connector after every connect
 * More about issue: https://github.com/NoahZinsmeister/web3-react/pull/130
 */
export const getWalletConnectConnector = () =>
  new WalletConnectConnector({
    supportedChainIds: supportedChainIDs,
    rpc: isDevelopment()
      ? { 4: process.env.REACT_APP_TESTNET_URI || "", 1: process.env.REACT_APP_MAINNET_URI || "" }
      : { 1: process.env.REACT_APP_MAINNET_URI || "" },
    qrcode: true,
    pollingInterval: 5000,
  })
