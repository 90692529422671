/**
 * Component to either render gif or video based on
 * the browser. This component has 100% width and height, it
 * must be wrapped in a container
 */

import ReactPlayer from "react-player"
import styled from "styled-components"
import { isSafari } from "../utils/os"

const Player = styled(ReactPlayer).attrs<{ url: string }>(({ url }) => ({
  loop: true,
  playing: true,
  muted: true,
  width: "100%",
  height: "100%",
  url,
}))``

const Gif = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
`

interface GifOrWebmProps {
  gifSrc: string
  videoUrl: string
}

const GifOrWebm = (props: GifOrWebmProps) => {
  if (isSafari) {
    return <Gif src={props.gifSrc} />
  }
  return <Player url={props.videoUrl} />
}

export default GifOrWebm
