import styled, { css } from "styled-components"
import { colors } from "../../styles"
import sizes from "../../utils/sizes"

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`
const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  flex: 1;
  color: ${colors.brown};

  @media (max-width: ${sizes.sm}px) {
    font-size: 1.5rem;
  }
`

interface TitleProps {
  title: string
  containerStyle?: React.CSSProperties
}
const TitleBar = (props: TitleProps) => {
  const { title, containerStyle } = props
  return (
    <TitleContainer style={containerStyle}>
      <Title>{title}</Title>
    </TitleContainer>
  )
}

export default TitleBar
