import { useCallback, useEffect, useMemo, useState } from "react"
import Marquee from "react-fast-marquee"
import styled, { css, keyframes } from "styled-components"
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg"
import { ReactComponent as PauseIcon } from "../../../../assets/icons/pause.svg"
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg"
import useScreenSize from "../../../../hooks/useScreenSize"
import { colors } from "../../../../styles"
import sizes from "../../../../utils/sizes"
import { clickable } from "../../../common/common"

const CONTAINER_WIDTH = 130

const Container = styled.div<{ showing: boolean }>`
  cursor: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.lightGray2};
  color: ${colors.brown2};

  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 20vw;
  bottom: 0;
  left: 0;

  @media (min-width: ${sizes.md}px) {
    flex-direction: column;
    bottom: 72px;
    left: 72px;
    width: ${CONTAINER_WIDTH}px;
    height: ${CONTAINER_WIDTH * 1.3}px;
  }

  transition: all 0.3s ease;
  opacity: ${({ showing }) => (showing ? 1 : 0)};
  transform: translateY(${({ showing }) => (showing ? 0 : "10%")});
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;

  @media (min-width: ${sizes.md}px) {
    position: relative;
  }
`

const CloseButton = styled.div`
  padding: 0 4px 8px 8px;
  position: absolute;
  ${clickable}
`

const DiscContainer = styled.div`
  position: relative;
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  // Fixes overflow issues on Safari https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;

  @media (min-width: ${sizes.md}px) {
    margin-left: 0;
    margin-top: 16px;
    width: ${CONTAINER_WIDTH * 0.7}px;
    height: ${CONTAINER_WIDTH * 0.7}px;
  }
`
export const rotate = (deg: string) => keyframes`
	to { 
    transform: rotate(${deg});
  }
`
const Background = styled.div<{
  image?: string
  animate?: boolean
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${({ image }) => image});
  ${({ animate }) => {
    if (animate) {
      return css`
        animation: ${rotate("365deg")} 6s linear infinite;
      `
    }
    return css`
      animation: ${rotate("0deg")} 6s linear infinite;
    `
  }}
`
const BlurEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(8px);
`
const WhiteCircle = styled.div`
  width: ${CONTAINER_WIDTH * 0.1}px;
  height: ${CONTAINER_WIDTH * 0.1}px;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px;

  @media (min-width: ${sizes.md}px) {
    flex-direction: row;
  }
`
const PlayPauseContainer = styled.div`
  ${clickable}
  z-index: 1;
`
const SongName = styled.div`
  width: 100%;
  color: ${colors.brown2};
  font-size: 4vw;

  @media (min-width: ${sizes.md}px) {
    font-size: 0.8rem;
  }
`

interface MusicPlayerProps {
  isPlaying: boolean
  name: string
  onTogglePlayPause: (forcePause?: boolean) => void
  backgroundImage?: string
}

const MusicPlayer = (props: MusicPlayerProps) => {
  const { width } = useScreenSize()

  const [isShowing, setIsShowing] = useState<boolean>()

  const marqueeWidth = useMemo(() => {
    if (width <= sizes.md) {
      return "65vw"
    }
    return `${CONTAINER_WIDTH * 0.7}px`
  }, [width])

  const listenIconProps = useMemo(() => {
    if (width <= sizes.md) {
      return {
        width: 24,
        height: 24,
        style: { marginLeft: "16px", marginRight: "4px" },
      }
    }
    return {
      width: 16,
      height: 16,
      style: { marginRight: "4px" },
    }
  }, [width])

  // Shows UI ALWAYS if the song is playing
  useEffect(() => {
    if (props.isPlaying) {
      setIsShowing(true)
    }
  }, [isShowing, props.isPlaying])

  // Pause song, and hides UI.
  const onClose = useCallback(() => {
    props.onTogglePlayPause(true)
    setTimeout(() => {
      setIsShowing(undefined)
    }, 100)
  }, [props])

  return (
    <Container showing={!!isShowing}>
      <CloseContainer onClick={onClose}>
        <CloseButton>
          <CloseIcon width={12} height={12} />
        </CloseButton>
      </CloseContainer>
      <DiscContainer>
        <Background animate={props.isPlaying} image={props.backgroundImage}>
          <BlurEffect />
        </Background>
        <WhiteCircle />
      </DiscContainer>
      <DetailsContainer>
        <PlayPauseContainer onClick={() => props.onTogglePlayPause()}>
          {props.isPlaying ? <PauseIcon {...listenIconProps} /> : <PlayIcon {...listenIconProps} />}
        </PlayPauseContainer>
        <Marquee
          style={{
            width: marqueeWidth,
          }}
          gradient={false}
        >
          <SongName>{props.name}</SongName>&nbsp;
        </Marquee>
      </DetailsContainer>
    </Container>
  )
}

export default MusicPlayer
