// import html2canvas from "html2canvas"
import domtoimage from "dom-to-image"
import { motion, useAnimation, Variants } from "framer-motion"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Spinner } from "react-bootstrap"
import styled, { css } from "styled-components"
import { IExplorer } from "../../../../models/Explorer"
import { borderColors, colors } from "../../../../styles"
import { clickableScale } from "../../../common/common"
import Loader from "../../../common/Loader"

const Container = styled(motion.div)`
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-height: 100vh;
  padding: 20vh 0;
`

const OuterBox = styled.div<{ isLoading: boolean }>`
  position: relative;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        pointer-events: none;
      `
    } else {
      return clickableScale
    }
  }}
`

const Box = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 350px;
  background-color: #f4eeea;
`

const CircleBorder = styled.div<{ borderColor: string }>`
  position: absolute;
  width: 95%;
  height: 95%;
  border: 3px solid ${(props) => props.borderColor};
  border-radius: 999px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const ExplorerImage = styled.img`
  width: 170%;
  height: 170%;
  object-fit: cover;
`

const Downloading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const itemVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  visible: (delayRef) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: delayRef.current },
  }),
}

interface GridItemProps {
  explorer: IExplorer
  delayPerPixel: number
  i: number
  originIndex: number
  originOffset: React.MutableRefObject<{
    top: number
    left: number
  }>
  onDownloadExplorer: (elementId: string) => void
  isDownloading?: boolean
}

const ExplorerItem = ({
  explorer,
  delayPerPixel,
  i,
  originIndex,
  originOffset,
  onDownloadExplorer,
  isDownloading,
}: GridItemProps) => {
  const delayRef = useRef(0)
  const [imageLoaded, setImageLoaded] = useState(false)
  const offset = useRef({ top: 0, left: 0 })
  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const elementId = useMemo(() => {
    return `profile-pic-cropper-${explorer.id}`
  }, [explorer])

  const borderColor = useMemo(() => {
    if (explorer.origins) {
      return (borderColors as any)[explorer.origins] || borderColors.Default
    }
    return borderColors.Default
  }, [explorer])

  // The measurement for all elements happens in the layoutEffect cycle
  // This ensures that when we calculate distance in the effect cycle
  // all elements have already been measured
  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    offset.current = {
      top: element.offsetTop,
      left: element.offsetLeft,
    }

    if (i === originIndex) {
      originOffset.current = offset.current
    }
  }, [delayPerPixel, i, originIndex, originOffset])

  useEffect(() => {
    const dx = Math.abs(offset.current.left - originOffset.current.left)
    const dy = Math.abs(offset.current.top - originOffset.current.top)
    const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
    delayRef.current = d * delayPerPixel
  }, [delayPerPixel, originOffset])

  return (
    <OuterBox isLoading={!imageLoaded} onClick={() => onDownloadExplorer(elementId)}>
      <Box id={elementId} ref={ref} variants={itemVariants} custom={delayRef}>
        {!imageLoaded && (
          <Loader
            containerStyle={{
              width: "100px",
              color: colors.brown,
              position: "absolute",
            }}
          />
        )}
        <CircleBorder borderColor={borderColor}>
          <ExplorerImage
            onLoad={() => {
              setImageLoaded(true)
            }}
            onError={(error) => {
              setImageLoaded(true)
              console.log("ERROR LOADING IMAGE", error)
            }}
            src={explorer.imageUrl}
          />
        </CircleBorder>
      </Box>
      {isDownloading && (
        <Downloading>
          <Spinner animation="border" />
        </Downloading>
      )}
    </OuterBox>
  )
}

interface ExplorerItemsProps {
  explorers: IExplorer[]
}

const ExplorerItems = ({ explorers }: ExplorerItemsProps) => {
  const originOffset: React.MutableRefObject<{
    top: number
    left: number
  }> = useRef({ top: 0, left: 0 })
  const controls = useAnimation()
  // Array of ids that is being downloaded right now
  const [downloadingIds, setDownloadingIds] = useState<string[]>([])

  useEffect(() => {
    controls.start("visible")
  }, [controls])

  const onDownloadExplorer = useCallback(async (elementId: string, explorerId: string) => {
    const croppedElem = document.querySelector(`#${elementId}`) as HTMLElement
    if (croppedElem) {
      try {
        console.log("DOWNLOADING")
        setDownloadingIds((prev) => {
          return [...prev, explorerId]
        })
        const dataUrl = await domtoimage.toPng(croppedElem)
        console.log(dataUrl)

        const downloadLink = document.createElement("a")
        downloadLink.href = dataUrl
        downloadLink.download = `${explorerId}.png`
        downloadLink.click()
      } catch (error) {
        console.log("ERROR", error)
      } finally {
        setDownloadingIds((prev) => {
          return prev.filter((id) => id !== explorerId)
        })
      }
    }
  }, [])

  return (
    // We set variants to be an object to force variant propagation - this is a bug
    // with variants and useAnimation()
    // https://github.com/framer/motion/issues/191
    <Container initial="hidden" animate={controls} variants={{}}>
      {explorers.map((explorer, i) => (
        <ExplorerItem
          key={explorer.id}
          i={i}
          isDownloading={downloadingIds.includes(explorer.id)}
          explorer={explorer}
          originIndex={Math.floor(explorers.length / 2)}
          delayPerPixel={0.0001}
          originOffset={originOffset}
          onDownloadExplorer={(elemId) => onDownloadExplorer(elemId, explorer.id)}
        />
      ))}
    </Container>
  )
}

export default ExplorerItems
