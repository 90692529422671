import { isDevelopment, isProduction, isStaging } from "../config/env"
import externalAddresses from "../config/externalAddresses.json"

type EtherscanLinkType = "tx" | "address"
export const getEtherscanUrl = (type: EtherscanLinkType = "tx", string: string) => {
  return isProduction() ? `https://etherscan.io/${type}/${string}` : `https://rinkeby.etherscan.io/${type}/${string}`
}

export const openseaCollectionUrl = isDevelopment()
  ? "https://testnets.opensea.io/collection/test-cevlmend5m"
  : "https://opensea.io/collection/loot-explorer"

export const openseaAssetBaseUrl = isDevelopment()
  ? `https://testnets.opensea.io/assets/${externalAddresses.rinkeby.lootexplorers}`
  : `https://opensea.io/assets/${externalAddresses.mainnet.lootexplorers}`

/**
 * Converts ipfs://something/1 to https://ipfs.io/something/1
 */
export const ipfsRawUrlToWebUrl = (url: string) => {
  if (url.includes("ipfs://")) {
    const [, path] = url.split("ipfs://")
    return `https://lootexplorers.infura-ipfs.io/ipfs/${path}`
  }
  return url
}
