import { Log } from "@ethersproject/abstract-provider"
import { BigNumber } from "@ethersproject/bignumber"

export class UnrecognizedEventType extends Error {}

export type EventType = "Transfer"

/**
 * Given a transaction log, process it an returns result in a meaningful way
 * @param log
 */
export const processLog = (log: Log, eventType: EventType) => {
  switch (eventType) {
    case "Transfer":
      return {
        fromAddress: BigNumber.from(log.topics[1]).toString(),
        toAddress: BigNumber.from(log.topics[2]).toString(),
        tokenId: BigNumber.from(log.topics[3]).toNumber(),
      }
  }
}
