import { useCallback, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as ETHIcon } from "../../../assets/icons/eth.svg"
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus.svg"
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg"
import MintCount1 from "../../../assets/webp/minting/explorercount_1.webp"
import MintCount2 from "../../../assets/webp/minting/explorercount_2.webp"
import MintCount3 from "../../../assets/webp/minting/explorercount_3.webp"
import MintCount4 from "../../../assets/webp/minting/explorercount_4.webp"
import MintCount5 from "../../../assets/webp/minting/explorercount_5.webp"
import { GeneralMintingInfo } from "../../../hooks/contracts/useExplorerContract"
import { useNavigation } from "../../../hooks/useNavigation"
import { useGlobalState } from "../../../store/state"
import { colors } from "../../../styles"
import { clickable, clickableScale, crystalFloat } from "../../common/common"
import { EmptyContainer, EmptyText, MintAmountText, MintButton, MintButtonContent, MintMessage } from "./styles"

const ICON_SIZE = "24px"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PickerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 16px;
`

const DisclaimerMessage = styled.div`
  font-size: 0.7rem;
  padding-top: 4px;
`

const AddMinusButton = styled.div<{ side: "left" | "right"; isDisabled?: boolean }>`
  ${clickable}
  height: 50px;
  width: 50px;
  background-color: ${colors.lightGreen};
  border: 3.5px solid ${colors.darkGreen};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ side }) => {
    if (side === "left") {
      return css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      `
    }
    return css`
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `
  }}

  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        pointer-events: none;
        opacity: 0.5;
      `
    }
    return ""
  }}
`

const Hover = styled.div`
  ${clickableScale}
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AmountInput = styled.input<{ isDisabled?: boolean }>`
  outline: none;
  text-align: center;
  font-size: 2rem;
  width: 30vw;
  max-width: 300px;
  border-left: none;
  border-right: none;
  border-top: 3.5px solid ${colors.darkGreen};
  border-bottom: 3.5px solid ${colors.darkGreen};

  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        pointer-events: none;
        opacity: 0.5;
      `
    }
    return ""
  }}
`

const ExplorerMintCountImage = styled.img.attrs(({ src }) => {
  src
})`
  width: 90%;
  animation: ${crystalFloat()} 6s ease-in-out infinite;
`

interface PublicWhitelistMintProps {
  generalMintingInfo: GeneralMintingInfo
  walletActive: boolean
  mintFunction: (amount: number) => Promise<void>

  // If provided, means only whitelisted can mint
  whitelist?: {
    isWhitelisted: boolean
  }
}

const PublicWhitelistMint = (props: PublicWhitelistMintProps) => {
  const { generalMintingInfo, mintFunction, whitelist } = props

  const [amount, setAmount] = useState<number | undefined>(generalMintingInfo.maxMintPerTransaction)
  const [showMaxMintMessage, setShowMaxMintMessage] = useState(false)
  const [transaction] = useGlobalState("transaction")
  const { navigateTo } = useNavigation()

  const transactionStarted = useMemo(() => {
    return transaction?.state === "started"
  }, [transaction])

  const mintDisabled = useMemo(() => {
    return !amount || amount > generalMintingInfo.maxMintPerTransaction || transactionStarted
  }, [amount, generalMintingInfo.maxMintPerTransaction, transactionStarted])

  const MintCountGraphic = useMemo(() => {
    if (amount === 5) {
      return <ExplorerMintCountImage src={MintCount5} />
    } else if (amount === 4) {
      return <ExplorerMintCountImage src={MintCount4} />
    } else if (amount === 3) {
      return <ExplorerMintCountImage src={MintCount3} />
    } else if (amount === 2) {
      return <ExplorerMintCountImage src={MintCount2} />
    }
    return <ExplorerMintCountImage src={MintCount1} />
  }, [amount])

  const onAdd = useCallback(() => {
    setShowMaxMintMessage(false)
    if (amount === undefined) {
      setAmount(1)
    } else if (amount + 1 > generalMintingInfo.maxMintPerTransaction) {
      setShowMaxMintMessage(true)
    } else {
      setAmount(amount + 1)
    }
  }, [amount, generalMintingInfo.maxMintPerTransaction])

  const onMinus = useCallback(() => {
    setShowMaxMintMessage(false)
    if (amount === undefined) {
      setAmount(1)
    } else if (amount - 1 > 0) {
      setAmount(amount - 1)
    }
  }, [amount])

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (!value) {
        // Empty string, set to undefined
        setAmount(undefined)
      } else {
        const num = Number(value)
        if (!isNaN(num)) {
          if (num > generalMintingInfo.maxMintPerTransaction) {
            setShowMaxMintMessage(true)
            setAmount(generalMintingInfo.maxMintPerTransaction)
          } else {
            setAmount(num)
          }
        }
      }
    },
    [generalMintingInfo.maxMintPerTransaction],
  )

  const onMintClick = useCallback(async () => {
    if (!amount) {
      return
    }
    await mintFunction(amount)
  }, [amount, mintFunction])

  if (whitelist && !whitelist.isWhitelisted) {
    return (
      <EmptyContainer>
        <EmptyText>
          It&apos;s Phase 2 of our minting schedule. Only First Explorers can mint now. Make sure to check you&apos;ve
          connected the right wallet!
          <br />
          <br />
          <i style={{ fontSize: "1rem" }}>Public mint starting on 23rd Dec 1:00pm EST</i>
        </EmptyText>
      </EmptyContainer>
    )
  }

  return (
    <Container>
      <PickerContainer>
        <AddMinusButton isDisabled={transactionStarted} side="left" onClick={onMinus}>
          <Hover>
            <MinusIcon width={ICON_SIZE} height={ICON_SIZE} />
          </Hover>
        </AddMinusButton>
        <AmountInput
          disabled={transactionStarted}
          isDisabled={transactionStarted}
          value={amount ? String(amount) : ""}
          onChange={onInputChange}
        />
        <AddMinusButton isDisabled={transactionStarted} side="right" onClick={onAdd}>
          <Hover>
            <PlusIcon width={ICON_SIZE} height={ICON_SIZE} />
          </Hover>
        </AddMinusButton>
      </PickerContainer>

      <MintButton disabled={mintDisabled} isDisabled={mintDisabled} onClick={onMintClick}>
        <MintButtonContent>
          MINT <MintAmountText>{amount ?? 0}</MintAmountText> EXPLORER(S) for
          <MintAmountText>{generalMintingInfo.displayPricePerMint * (amount ?? 0)}</MintAmountText>
          <ETHIcon width={24} height={24} />
          {transactionStarted ? (
            <MintMessage>
              <b>Pending wallet confirmation...</b>
            </MintMessage>
          ) : showMaxMintMessage ? (
            <MintMessage>
              You can only mint up to <b>{generalMintingInfo.maxMintPerTransaction}</b> Explorers
            </MintMessage>
          ) : null}
        </MintButtonContent>
      </MintButton>
      <DisclaimerMessage>
        Before minting, please make sure you&apos;ve read our&nbsp;
        <a href="#" onClick={() => navigateTo("faq")}>
          FAQ and Terms of Conditions
        </a>
      </DisclaimerMessage>
      {MintCountGraphic}
    </Container>
  )
}

export default PublicWhitelistMint
