import { useCallback } from "react"
import styled from "styled-components"
import C1 from "../../../../assets/webp/map/landmarks/c_1.webp"
import C2 from "../../../../assets/webp/map/landmarks/c_2.webp"
import C3 from "../../../../assets/webp/map/landmarks/c_3.webp"
import C4 from "../../../../assets/webp/map/landmarks/c_4.webp"
import C5 from "../../../../assets/webp/map/landmarks/c_5.webp"
import F1A from "../../../../assets/webp/map/landmarks/f_1a.webp"
import F1B from "../../../../assets/webp/map/landmarks/f_1b.webp"
import F1C from "../../../../assets/webp/map/landmarks/f_1c.webp"
import F2 from "../../../../assets/webp/map/landmarks/f_2.webp"
import L1 from "../../../../assets/webp/map/landmarks/l_1.webp"
import L2 from "../../../../assets/webp/map/landmarks/l_2.webp"
import L3 from "../../../../assets/webp/map/landmarks/l_3.webp"
import L4 from "../../../../assets/webp/map/landmarks/l_4.webp"
import L5 from "../../../../assets/webp/map/landmarks/l_5.webp"
import L6 from "../../../../assets/webp/map/landmarks/l_6.webp"
import L7A from "../../../../assets/webp/map/landmarks/l_7a.webp"
import L7B from "../../../../assets/webp/map/landmarks/l_7b.webp"
import L8 from "../../../../assets/webp/map/landmarks/l_8.webp"
import M1A from "../../../../assets/webp/map/landmarks/m_1a.webp"
import M1B from "../../../../assets/webp/map/landmarks/m_1b.webp"
import M2 from "../../../../assets/webp/map/landmarks/m_2.webp"
import M3 from "../../../../assets/webp/map/landmarks/m_3.webp"
import M4 from "../../../../assets/webp/map/landmarks/m_4.webp"
import M5 from "../../../../assets/webp/map/landmarks/m_5.webp"
import M6 from "../../../../assets/webp/map/landmarks/m_6.webp"
import M7 from "../../../../assets/webp/map/landmarks/m_7.webp"
import M8 from "../../../../assets/webp/map/landmarks/m_8.webp"
import M9 from "../../../../assets/webp/map/landmarks/m_9.webp"
import M10 from "../../../../assets/webp/map/landmarks/m_10.webp"
import M11 from "../../../../assets/webp/map/landmarks/m_11.webp"
import MG1 from "../../../../assets/webp/map/landmarks/mg_1.webp"
import MG2 from "../../../../assets/webp/map/landmarks/mg_2.webp"
import MG3 from "../../../../assets/webp/map/landmarks/mg_3.webp"
import MG4 from "../../../../assets/webp/map/landmarks/mg_4.webp"
import MG5 from "../../../../assets/webp/map/landmarks/mg_5.webp"
import MG6A from "../../../../assets/webp/map/landmarks/mg_6a.webp"
import MG6B from "../../../../assets/webp/map/landmarks/mg_6b.webp"
import N1 from "../../../../assets/webp/map/landmarks/n_1.webp"
import N2 from "../../../../assets/webp/map/landmarks/n_2.webp"
import N3 from "../../../../assets/webp/map/landmarks/n_3.webp"
import N4 from "../../../../assets/webp/map/landmarks/n_4.webp"
import N5 from "../../../../assets/webp/map/landmarks/n_5.webp"
import { clickableScale, clickableShrunk } from "../../../common/common"
import { labelClickSound, labelHoverSound } from "./sound"

const Container = styled.div`
  position: absolute;
  overflow: visible;
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const ClickableContainer = styled.div`
  ${clickableShrunk}
  position: absolute;
  pointer-events: auto;
`

const BaseImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
`
const Magma1 = styled(ClickableContainer)`
  width: 208px;
  height: 80px;
  left: 430px;
  top: 212px;
`
const Magma2 = styled(ClickableContainer)`
  height: 70px;
  width: 100px;
  left: 545px;
  top: 195px;
`
const Magma3 = styled(ClickableContainer).attrs({
  src: MG3,
})`
  width: 105px;
  height: 85px;
  left: 650px;
  top: 180px;
`
const Magma4 = styled(ClickableContainer).attrs({
  src: MG4,
})`
  width: 59px;
  height: 52px;
  left: 727px;
  top: 136px;
`
const Magma5 = styled(ClickableContainer).attrs({
  src: MG5,
})`
  width: 48px;
  height: 60px;
  left: 622px;
  top: 132px;
`
const Magma6A = styled(ClickableContainer).attrs({
  src: MG6A,
})`
  width: 230px;
  height: 110px;
  left: 484px;
  top: 33px;
`
const Magma6B = styled(ClickableContainer).attrs({
  src: MG6B,
})`
  width: 130px;
  height: 85px;
  left: 500px;
  top: 105px;
`
const Frostenn1A = styled(ClickableContainer).attrs({
  src: F1A,
})`
  width: 170px;
  height: 100px;
  left: 112px;
  top: 186px;
`
const Frostenn1B = styled(ClickableContainer).attrs({
  src: F1B,
})`
  width: 130px;
  height: 70px;
  left: 245px;
  top: 224px;
`
const Frostenn1C = styled(ClickableContainer).attrs({
  src: F1C,
})`
  width: 100px;
  height: 90px;
  left: 278px;
  top: 158px;
`
const Frostenn2 = styled(ClickableContainer).attrs({
  src: F2,
})`
  width: 110px;
  height: 85px;
  left: 400px;
  top: 255px;
`
const Merlade1A = styled(ClickableContainer).attrs({
  src: M1A,
})`
  width: 120px;
  height: 70px;
  left: 324px;
  top: 395px;
`
const Merlade1B = styled(ClickableContainer).attrs({
  src: M1B,
})`
  width: 153px;
  height: 73px;
  left: 372px;
  top: 368px;
`
const Merlade2 = styled(ClickableContainer).attrs({
  src: M2,
})`
  width: 64px;
  height: 48px;
  left: 442px;
  top: 465px;
`
const Merlade3 = styled(ClickableContainer).attrs({
  src: M3,
})`
  width: 118px;
  height: 63px;
  left: 380px;
  top: 537px;
`
const Merlade4 = styled(ClickableContainer).attrs({
  src: M4,
})`
  width: 70px;
  height: 40px;
  left: 535px;
  top: 577px;
`
const Merlade5 = styled(ClickableContainer).attrs({
  src: M5,
})`
  width: 60px;
  height: 58px;
  left: 602px;
  top: 506px;
`
const Merlade6 = styled(ClickableContainer).attrs({
  src: M6,
})`
  width: 112px;
  height: 64px;
  left: 475px;
  top: 650px;
`
const Merlade7 = styled(ClickableContainer).attrs({
  src: M7,
})`
  width: 68px;
  height: 40px;
  left: 395px;
  top: 688px;
`
const Merlade8 = styled(ClickableContainer).attrs({
  src: M8,
})`
  width: 85px;
  height: 50px;
  left: 233px;
  top: 690px;
`
const Merlade9 = styled(ClickableContainer).attrs({
  src: M9,
})`
  width: 60px;
  height: 40px;
  left: 202px;
  top: 627px;
`
const Merlade10 = styled(ClickableContainer).attrs({
  src: M10,
})`
  width: 38px;
  height: 38px;
  left: 182px;
  top: 562px;
`
const Merlade11 = styled(ClickableContainer).attrs({
  src: M11,
})`
  width: 130px;
  height: 65px;
  left: 230px;
  top: 484px;
`
const Coast1 = styled(ClickableContainer).attrs({
  src: C1,
})`
  width: 75px;
  height: 70px;
  left: 710px;
  top: 560px;
`
const Coast2 = styled(ClickableContainer).attrs({
  src: C2,
})`
  width: 60px;
  height: 30px;
  left: 678px;
  top: 761px;
`
const Coast3 = styled(ClickableContainer).attrs({
  src: C3,
})`
  width: 165px;
  height: 78px;
  left: 727px;
  top: 785px;
`
const Coast4 = styled(ClickableContainer).attrs({
  src: C4,
})`
  width: 50px;
  height: 50px;
  left: 550px;
  top: 766px;
`
const Coast5 = styled(ClickableContainer).attrs({
  src: C5,
})`
  width: 68px;
  height: 60px;
  left: 400px;
  top: 723px;
`
const Lagoona1 = styled(ClickableContainer).attrs({
  src: L1,
})`
  width: 72px;
  height: 72px;
  right: 172px;
  top: 535px;
`
const Lagoona2 = styled(ClickableContainer).attrs({
  src: L2,
})`
  width: 125px;
  height: 73px;
  right: 89px;
  top: 647px;
`
const Lagoona3 = styled(ClickableContainer).attrs({
  src: L3,
})`
  width: 55px;
  height: 40px;
  right: 233px;
  top: 622px;
`
const Lagoona4 = styled(ClickableContainer).attrs({
  src: L4,
})`
  width: 60px;
  height: 50px;
  right: 287px;
  top: 579px;
`
const Lagoona5 = styled(ClickableContainer).attrs({
  src: L5,
})`
  width: 63px;
  height: 63px;
  right: 335px;
  top: 536px;
`
const Lagoona6 = styled(ClickableContainer).attrs({
  src: L6,
})`
  width: 65px;
  height: 65px;
  right: 398px;
  top: 553px;
`
const Lagoona7A = styled(ClickableContainer).attrs({
  src: L7A,
})`
  width: 82px;
  height: 82px;
  right: 427px;
  top: 636px;
`
const Lagoona7B = styled(ClickableContainer).attrs({
  src: L7B,
})`
  width: 85px;
  height: 60px;
  right: 360px;
  top: 626px;
`
const Lagoona8 = styled(ClickableContainer).attrs({
  src: L8,
})`
  width: 260px;
  height: 115px;
  right: 118px;
  top: 693px;
`
const Noctus1 = styled(ClickableContainer).attrs({
  src: N1,
})`
  width: 45px;
  height: 100px;
  right: 381px;
  top: 66px;
`
const Noctus2 = styled(ClickableContainer).attrs({
  src: N2,
})`
  width: 28px;
  height: 28px;
  right: 306px;
  top: 60px;
`
const Noctus3 = styled(ClickableContainer).attrs({
  src: N3,
})`
  width: 115px;
  height: 100px;
  right: 184px;
  top: 33px;
`
const Noctus4 = styled(ClickableContainer).attrs({
  src: N4,
})`
  width: 55px;
  height: 55px;
  right: 274px;
  top: 109px;
`
const Noctus5 = styled(ClickableContainer).attrs({
  src: N5,
})`
  width: 80px;
  height: 60px;
  right: 145px;
  top: 176px;
`

const Landmarks: React.FC<{
  onLandmarkIdClicked: (id: string) => void
}> = ({ onLandmarkIdClicked }) => {
  const onLandmarkHover = useCallback(() => {
    try {
      labelHoverSound.currentTime = 0
      labelHoverSound.volume = 0.5
      labelHoverSound.play()
    } catch (error) {
      // Do nothing
    }
  }, [])

  const onLandmarkClick = useCallback(
    (landmarkId: string) => {
      try {
        labelClickSound.currentTime = 0
        labelClickSound.volume = 0.5
        labelClickSound.play()
      } catch (error) {
        // Do nothing
      }
      onLandmarkIdClicked(landmarkId)
    },
    [onLandmarkIdClicked],
  )

  return (
    <Container>
      <Magma1 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("6")}>
        <BaseImage src={MG1} />
      </Magma1>
      <Magma2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("2")}>
        <BaseImage src={MG2} />
      </Magma2>
      <Magma3 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("5")}>
        <BaseImage src={MG3} />
      </Magma3>
      <Magma4 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("4")}>
        <BaseImage src={MG4} />
      </Magma4>
      <Magma6A onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("1")}>
        <BaseImage src={MG6A} />
      </Magma6A>
      <Magma6B onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("1A")}>
        <BaseImage src={MG6B} />
      </Magma6B>
      <Magma5 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("3")}>
        <BaseImage src={MG5} />
      </Magma5>
      <Frostenn1A onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("7")}>
        <BaseImage src={F1A} />
      </Frostenn1A>
      <Frostenn1B onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("9")}>
        <BaseImage src={F1B} />
      </Frostenn1B>
      <Frostenn1C onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("8")}>
        <BaseImage src={F1C} />
      </Frostenn1C>
      <Frostenn2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("11")}>
        <BaseImage src={F2} />
      </Frostenn2>
      <Merlade1A onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("13")}>
        <BaseImage src={M1A} />
      </Merlade1A>
      <Merlade1B onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("12")}>
        <BaseImage src={M1B} />
      </Merlade1B>
      <Merlade2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("14")}>
        <BaseImage src={M2} />
      </Merlade2>
      <Merlade3 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("16")}>
        <BaseImage src={M3} />
      </Merlade3>
      <Merlade4 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("22")}>
        <BaseImage src={M4} />
      </Merlade4>
      <Merlade5 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("23")}>
        <BaseImage src={M5} />
      </Merlade5>
      <Merlade6 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("21")}>
        <BaseImage src={M6} />
      </Merlade6>
      <Merlade7 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("20")}>
        <BaseImage src={M7} />
      </Merlade7>
      <Merlade8 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("19")}>
        <BaseImage src={M8} />
      </Merlade8>
      <Merlade9 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("18")}>
        <BaseImage src={M9} />
      </Merlade9>
      <Merlade10 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("17")}>
        <BaseImage src={M10} />
      </Merlade10>
      <Merlade11 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("15")}>
        <BaseImage src={M11} />
      </Merlade11>
      <Coast1 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("24")}>
        <BaseImage src={C1} />
      </Coast1>
      <Coast2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("25")}>
        <BaseImage src={C2} />
      </Coast2>
      <Coast3 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("28")}>
        <BaseImage src={C3} />
      </Coast3>
      <Coast4 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("26")}>
        <BaseImage src={C4} />
      </Coast4>
      <Coast5 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("27")}>
        <BaseImage src={C5} />
      </Coast5>
      <Lagoona1 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("34")}>
        <BaseImage src={L1} />
      </Lagoona1>
      <Lagoona3 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("33")}>
        <BaseImage src={L3} />
      </Lagoona3>
      <Lagoona4 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("32")}>
        <BaseImage src={L4} />
      </Lagoona4>
      <Lagoona5 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("31")}>
        <BaseImage src={L5} />
      </Lagoona5>
      <Lagoona6 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("30")}>
        <BaseImage src={L6} />
      </Lagoona6>
      <Lagoona7A onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("29")}>
        <BaseImage src={L7A} />
      </Lagoona7A>
      <Lagoona7B onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("29A")}>
        <BaseImage src={L7B} />
      </Lagoona7B>
      <Lagoona8 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("35")}>
        <BaseImage src={L8} />
      </Lagoona8>
      <Lagoona2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("36")}>
        <BaseImage src={L2} />
      </Lagoona2>
      <Noctus1 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("37")}>
        <BaseImage src={N1} />
      </Noctus1>
      <Noctus2 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("39")}>
        <BaseImage src={N2} />
      </Noctus2>
      <Noctus3 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("40")}>
        <BaseImage src={N3} />
      </Noctus3>
      <Noctus4 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("38")}>
        <BaseImage src={N4} />
      </Noctus4>
      <Noctus5 onMouseEnter={onLandmarkHover} onClick={() => onLandmarkClick("41")}>
        <BaseImage src={N5} />
      </Noctus5>
    </Container>
  )
}

export default Landmarks
