import styled from "styled-components"
import { colors } from "../../../styles"
import sizes from "../../../utils/sizes"
import { clickableShrunk } from "../../common/common"

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: 5vh;
`

export const EmptyText = styled.h3`
  padding: 0 3vw;
  font-size: 1.5rem;
`

export const MintButton = styled.button<{ isDisabled?: boolean; isError?: boolean }>`
  max-width: 50vw;
  font-weight: bold;
  font-size: 1rem;
  padding: 4px 8px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${sizes.md}px) {
    font-size: 1.3rem;
    padding: 8px 16px;
    max-width: 450px;
  }

  ${({ isDisabled }) => (!isDisabled ? clickableShrunk : "")}
  color: ${({ isError, isDisabled }) => (isError ? colors.red : isDisabled ? colors.gray : colors.darkGreen)};
  background-color: ${({ isError, isDisabled }) =>
    isError ? colors.lightGreen : isDisabled ? colors.lightGray : colors.lightGreen};
  border: 3px solid ${({ isError, isDisabled }) => (isError ? colors.red : isDisabled ? colors.gray : colors.darkGreen)};
`

export const MintButtonContent = styled.div``

export const MintMessage = styled.div`
  font-size: 0.8rem;
  font-weight: normal;
`

export const MintAmountText = styled.span`
  font-size: 2rem;
  padding: 0 8px;
`
