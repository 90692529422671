import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import MerladeSong from "../../../../assets/sounds/bgm (normal).mp3"
import MapBgImage from "../../../../assets/webp/map/bg.webp"
import CannonCoastPreview from "../../../../assets/webp/map/land-images/cannon-coast-small.webp"
import FrostennPreview from "../../../../assets/webp/map/land-images/frostenn-small.webp"
import LagoonaPreview from "../../../../assets/webp/map/land-images/lagoona-small.webp"
import MagmaPreview from "../../../../assets/webp/map/land-images/magma-small.webp"
import MerladePreview from "../../../../assets/webp/map/land-images/merlade-small.webp"
import NoctusPreview from "../../../../assets/webp/map/land-images/noctus-small.webp"
import VioletEversidePreview from "../../../../assets/webp/map/land-images/violet-everside-small.webp"
import ReturnImage from "../../../../assets/webp/map/return.webp"
import { useBgm } from "../../../../hooks/useBgm"
import { useNavigation } from "../../../../hooks/useNavigation"
import usePan from "../../../../hooks/usePan"
import useScale from "../../../../hooks/useScale"
import useScreenSize from "../../../../hooks/useScreenSize"
import { LandNamesType, Lands as AllLands } from "../../../../models/Map"
import { colors } from "../../../../styles"
import { getLandSongUrl } from "../../../../utils/cdn"
import sizes from "../../../../utils/sizes"
import { clickableShrunk } from "../../../common/common"
import LandMenu from "./LandMenu"
import Lands from "./Lands"
import MusicPlayer from "./MusicPlayer"
import ZoomControls from "./ZoomControls"

const BG_ANIM_DURATION = 0.2

const Container = styled.div<{ show: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  transition: background-color ${BG_ANIM_DURATION}s ease;
  background-color: ${({ show }) => {
    if (show) {
      return colors.soupBg
    }
    return "transparent"
  }};
`

const MapContainerScale = styled.div<{ scale: number }>`
  pointer-events: none;
  overflow: visible;
  width: 100%;
  height: 100%;

  transition: transform 0.1s ease;
  ${(props) => {
    return css`
      transform: scale(${props.scale});
    `
  }}
`

const MapContainer = styled.div`
  overflow: visible;
  width: 100%;
  height: 100%;
`
const MapBG = styled.img.attrs({
  src: MapBgImage,
})`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ReturnButton = styled.img.attrs({
  src: ReturnImage,
})`
  ${clickableShrunk}
  position: absolute;
  top: 80px;
  left: 16px;
  width: 160px;

  @media (min-width: ${sizes.md}px) {
    top: 16px;
  }
`

const MapScene = () => {
  const pannableContainerRef = useRef<HTMLDivElement>(null)
  const mapContainerRef = useRef<HTMLDivElement>(null)

  const { pause } = useBgm()
  const { navigateToFullscreen } = useNavigation()

  const [currentSong, setCurrentSong] = useState<HTMLAudioElement>()
  // Track playing state in a different state, because using `paused` on HTMLAudioElement is not reliable
  const [currentSongPlaying, setCurrentSongPlaying] = useState<boolean>(false)
  const [currentSongLand, setCurrentSongLand] = useState<LandNamesType>()
  const [showRenders, setShowRenders] = useState(false)

  const [showTransition, setShowTransition] = useState(false)
  const [currentSelectedItem, setCurrentSelectedItem] = useState<{
    land: LandNamesType
    landmarkId?: string
  }>()

  const { width, height } = useScreenSize()

  const playingSongLandBg = useMemo(() => {
    switch (currentSongLand) {
      case "Cannon Coast":
        return CannonCoastPreview
      case "Shattered Bone":
        return CannonCoastPreview
      case "Frostenn":
        return FrostennPreview
      case "Lagoona":
        return LagoonaPreview
      case "Magma":
        return MagmaPreview
      case "Merlade":
        return MerladePreview
      case "Pollen":
        return MerladePreview
      case "Violet Everside":
        return VioletEversidePreview
      case "Noctus":
        return NoctusPreview
      case "Underlune":
        return LagoonaPreview
      default:
        return null
    }
  }, [currentSongLand])

  const currentLandSongUrl = useMemo(() => {
    if (currentSelectedItem) {
      const song =
        currentSelectedItem.land === "Merlade" ? MerladeSong : getLandSongUrl(AllLands[currentSelectedItem.land])
      return song
    }
    return ""
  }, [currentSelectedItem])

  const minScale = useMemo(() => {
    // Fixed dimensions of the map
    const imageSize = {
      width: 1420.5,
      height: 917.5,
    }
    const padding = 100
    const smallestScreenSize = width < height ? width : height
    const largestImageSize = imageSize.width > imageSize.height ? imageSize.width : imageSize.height
    return smallestScreenSize / (largestImageSize + padding)
  }, [width, height])
  const maxScale = 2
  const { scale, updateScaleByProgress } = useScale({
    ref: pannableContainerRef,
    minScale,
    maxScale,
  })
  const [offset, startPan] = usePan({ scale })

  const scaleProgress = (scale - minScale) / (maxScale - minScale)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTransition(true)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const onPlaySong = useCallback(() => {
    pause()
    currentSong?.pause()

    if (currentSelectedItem) {
      // Create new audio if needed.
      if (currentSong) {
        currentSong.src = currentLandSongUrl
        setCurrentSongLand(currentSelectedItem.land)
        currentSong.play()
      } else {
        const audio = new Audio(currentLandSongUrl)

        const onPlayListener = () => {
          setCurrentSongPlaying(true)
        }

        const onPauseListener = () => {
          setCurrentSongPlaying(false)
        }

        const onEndListener = () => {
          audio.currentTime = 0
          audio.play()
        }
        audio.addEventListener("play", onPlayListener)
        audio.addEventListener("pause", onPauseListener)
        audio.addEventListener("ended", onEndListener)
        setCurrentSongLand(currentSelectedItem.land)
        setCurrentSong(audio)
        setCurrentSong((prev) => {
          prev?.play().catch((e) => console.error(e))
          return prev
        })
      }
    }
  }, [pause, currentSong, currentLandSongUrl, currentSelectedItem])

  const onTogglePlayPause = useCallback(
    (forcePause?: boolean) => {
      if (!currentSong) {
        return
      }

      if (forcePause) {
        currentSong.pause()
      } else if (currentSong.paused) {
        currentSong.play().catch((e) => console.error(e))
      } else {
        currentSong.pause()
      }
    },
    [currentSong],
  )

  const onSelectItem = useCallback((item?: { land: LandNamesType; landmarkId?: string }) => {
    setCurrentSelectedItem(item)
  }, [])

  const onSelectLand = useCallback(
    (land?: LandNamesType) => {
      onSelectItem(land ? { land } : undefined)
    },
    [onSelectItem],
  )

  const onSelectLandmark = useCallback(
    (id?: string) => {
      if (id) {
        const matchingLand = Object.entries(AllLands).reduce(
          (prev, next) => {
            if (prev.land) {
              return prev
            } else if (next[1].landmarks.some((landmark) => landmark.id === id)) {
              return {
                land: next[0],
                landmarkId: id,
              }
            }
            return {
              land: "",
              landmarkId: "",
            }
          },
          {
            land: "",
            landmarkId: "",
          },
        )

        if (matchingLand.land) {
          setCurrentSelectedItem({
            land: matchingLand.land as LandNamesType,
            landmarkId: matchingLand.landmarkId || undefined,
          })
        }
      } else if (currentSelectedItem) {
        setCurrentSelectedItem({
          land: currentSelectedItem.land,
          landmarkId: undefined,
        })
      }
    },
    [currentSelectedItem],
  )

  const onReturnClick = useCallback(() => {
    navigateToFullscreen(undefined)

    // Cleanup states
    onTogglePlayPause(true)
  }, [navigateToFullscreen, onTogglePlayPause])

  return (
    <Container
      show={showTransition}
      ref={pannableContainerRef}
      onMouseDown={(e) => {
        startPan(e.pageX, e.pageY)
      }}
      onTouchStart={(e) => {
        const touch = e.touches[0]
        startPan(touch.pageX, touch.pageY)
      }}
    >
      <MapBG />
      <MapContainerScale scale={scale}>
        <MapContainer
          ref={mapContainerRef}
          style={{
            transform: `translate(${-offset.x}px, ${-offset.y}px)`,
          }}
        >
          <Lands
            parentRef={mapContainerRef}
            scaleProgress={scaleProgress}
            showRendersOnly={showRenders}
            onSelectLand={onSelectLand}
            onSelectLandmark={onSelectLandmark}
          />
        </MapContainer>
      </MapContainerScale>
      <ZoomControls
        // Zooming in or out increases scaling by 15%
        onZoomIn={() => updateScaleByProgress(0.15, "zoomIn")}
        onZoomOut={() => updateScaleByProgress(0.15, "zoomOut")}
        showRenders={showRenders}
        onToggleShowRenders={() => setShowRenders(!showRenders)}
      />
      <LandMenu
        selectedItem={currentSelectedItem}
        onSelectItem={onSelectItem}
        canPlaySong={!!currentLandSongUrl}
        onPlaySong={onPlaySong}
      />
      <MusicPlayer
        isPlaying={!!currentSong && currentSongPlaying}
        backgroundImage={playingSongLandBg}
        name={currentSongLand ? `${AllLands[currentSongLand].fullSongName} - ${currentSongLand}` : ""}
        onTogglePlayPause={onTogglePlayPause}
      />
      <ReturnButton onClick={onReturnClick} />
    </Container>
  )
}

export default MapScene
