import styled from "styled-components"
import { colors } from "../../styles"

export const Container = styled.div`
  padding: 0 16px;
  color: ${colors.brown};
`
export const Body = styled.div`
  text-align: left;
`
