import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import { Signer } from "ethers"
import { useCallback, useEffect, useState } from "react"
import { generateNonce, SiweMessage } from "siwe"
import { BackendBaseURL } from "../config/constants"
import { getDefaultChainID } from "../config/env"
import { useGlobalState } from "../store/state"
import { clearSiweToken, getSiweToken, setSiweToken } from "../utils/storage"

const useSiwe = () => {
  const { account, library } = useWeb3React()
  const [token, setToken] = useGlobalState("siweToken")

  useEffect(() => {
    setToken((account && getSiweToken(account)) || undefined)
  }, [account, setToken])

  const onRequestSignature = useCallback(
    async (onError: (error: string) => void) => {
      if (!account || !library) {
        return
      }

      const message = new SiweMessage({
        domain: window.location.host,
        address: account,
        statement: "Verify wallet ownership",
        uri: window.location.origin,
        version: "1",
        chainId: getDefaultChainID(),
        nonce: generateNonce(),
        expirationTime: new Date(Date.now() + 1000 * 60 * 60).toISOString(),
      })
      const signer = library.getSigner() as Signer

      try {
        const signature = await signer.signMessage(message.prepareMessage())

        const { data } = await axios.post<{ success: boolean; token: string }>(`${BackendBaseURL}/siwe-verify`, {
          signature,
          message,
        })
        if (!data.success) {
          onError("siweVerifyError")
        }

        setToken(setSiweToken(account, data.token))
      } catch (err) {
        onError("siweVerifyError")
      }
    },
    [account, library, setToken],
  )

  const resetToken = useCallback(() => {
    if (!account) {
      return
    }

    clearSiweToken(account)
    setToken(undefined)
  }, [account, setToken])

  return { token, onRequestSignature, resetToken }
}

export default useSiwe
