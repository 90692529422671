import { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg"
import { ReactComponent as ETHIcon } from "../../../assets/icons/eth.svg"
import { GeneralMintingInfo } from "../../../hooks/contracts/useExplorerContract"
import { Bag } from "../../../models/Loot"
import { useGlobalState } from "../../../store/state"
import { colors } from "../../../styles"
import sizes from "../../../utils/sizes"
import { clickableScale } from "../../common/common"
import Loader from "../../common/Loader"
import { EmptyContainer, EmptyText, MintAmountText, MintButton, MintButtonContent, MintMessage } from "./styles"

const ETH_ICON_SIZE = 16
const CHECKMARK_ICON_SIZE = 21

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3vh;
`

const SelectLootsContainer = styled.div<{ isDisabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;

  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  @media (min-width: ${sizes.md}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const FloatingMintButton = styled(MintButton)`
  position: absolute;
  bottom: calc(6vh + 6vw);
`

const LootBag = styled.div<{ selected?: boolean; alreadyMinted?: boolean }>`
  ${({ alreadyMinted }) => {
    if (!alreadyMinted) {
      return css`
        // OVERRIDE TRANSFORM
        ${clickableScale}
        transition: 0.2s all ease;
        &:hover {
          transform: scale(0.97);
        }
      `
    }
    return ""
  }}

  position: relative;
  background-color: black;
  color: white;
  font-family: "EB Garamond";
  text-align: left;
  padding: 12px;
  border-radius: 8px;
  aspect-ratio: 1;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  // Returns 0px border to ensure there's an animation
  ${({ selected }) => {
    if (selected) {
      return css`
        box-shadow: 0px 0px 0px 5px ${colors.green} inset;
        @media (min-width: ${sizes.md}px) {
          box-shadow: 0px 0px 0px 3px ${colors.green} inset;
        }
      `
    }
    return `box-shadow:0px 0px 0px 0px ${colors.green} inset;`
  }}
`
const LootText = styled.div`
  font-size: 1rem;

  @media (min-width: ${sizes.md}px) {
    font-size: 1vw;
  }

  @media (min-width: ${sizes.xxl}px) {
    font-size: 1rem;
  }
`

const CheckmarkContainer = styled.div<{ selected?: boolean }>`
  width: 26px;
  height: 26px;
  background-color: ${({ selected }) => (selected ? colors.lightGreen : "white")};
  border-radius: 999px;
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BagMintedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(244, 240, 241, 0.8);

  color: black;
  font-size: 2rem;
  font-weight: bold;
`

interface OGMintProps {
  bags: Bag[]
  mintedBagIds: number[]
  lootLoading: boolean
  generalMintingInfo: GeneralMintingInfo
  walletActive: boolean

  mintFunction: (lootIds: number[]) => Promise<void>
}

const OGMint = (props: OGMintProps) => {
  const { bags, mintedBagIds, generalMintingInfo, lootLoading, walletActive, mintFunction } = props

  const [transaction] = useGlobalState("transaction")
  const [selectedLootIds, setSelectedLootIds] = useState<number[]>([])
  const [showSelectedTooManyLoot, setShowSelectedTooManyLoot] = useState(false)

  // Auto dismiss error after awhile
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (showSelectedTooManyLoot) {
      timeout = setTimeout(() => {
        setShowSelectedTooManyLoot(false)
      }, 3000)
    }
    return () => clearTimeout(timeout)
  }, [showSelectedTooManyLoot])

  // Remove minted bags from selectedLootIds
  useEffect(() => {
    if (mintedBagIds.length) {
      setSelectedLootIds((prev) => {
        return prev.filter((id) => !mintedBagIds.includes(id))
      })
    }
  }, [mintedBagIds])

  const selectLootId = useCallback(
    (id: number) => {
      setShowSelectedTooManyLoot(false)
      if (selectedLootIds.includes(id)) {
        // Remove
        setSelectedLootIds([...selectedLootIds].filter((i) => id !== i))
      } else if (selectedLootIds.length >= generalMintingInfo.maxMintPerTransaction) {
        setShowSelectedTooManyLoot(true)
      } else {
        // Add
        setSelectedLootIds(Array.from(new Set([...selectedLootIds, id])))
      }
    },
    [selectedLootIds, generalMintingInfo],
  )

  const onMintClick = useCallback(async () => {
    if (!selectedLootIds.length) {
      return
    }
    await mintFunction(selectedLootIds)
  }, [selectedLootIds, mintFunction])

  const RenderMintButton = useMemo(() => {
    if (!walletActive) {
      return null
    }

    let mintMessage = null
    if (transaction?.state === "started") {
      mintMessage = (
        <MintMessage>
          <b>Pending wallet confirmation...</b>
        </MintMessage>
      )
    } else if (showSelectedTooManyLoot) {
      mintMessage = (
        <MintMessage>
          You can only select up to <b>{generalMintingInfo.maxMintPerTransaction}</b> Explorers
        </MintMessage>
      )
    }

    return (
      <FloatingMintButton
        disabled={Boolean(!selectedLootIds.length || lootLoading || transaction?.state === "started")}
        isDisabled={Boolean(!selectedLootIds.length || lootLoading || transaction?.state === "started")}
        isError={showSelectedTooManyLoot}
        onClick={onMintClick}
      >
        {lootLoading ? (
          "Please wait..."
        ) : Boolean(selectedLootIds.length) ? (
          <MintButtonContent>
            MINT <MintAmountText>{selectedLootIds.length}</MintAmountText> EXPLORER(S) for
            <MintAmountText>{generalMintingInfo.displayPricePerMint * selectedLootIds.length}</MintAmountText>
            <ETHIcon width={ETH_ICON_SIZE * 1.5} height={ETH_ICON_SIZE * 1.5} />
            {mintMessage}
          </MintButtonContent>
        ) : (
          "Select a bag"
        )}
      </FloatingMintButton>
    )
  }, [
    selectedLootIds,
    generalMintingInfo,
    onMintClick,
    showSelectedTooManyLoot,
    lootLoading,
    walletActive,
    transaction,
  ])

  if (!bags.length && lootLoading) {
    return (
      <LoadingContainer>
        <Loader containerStyle={{ width: "80%" }} />
      </LoadingContainer>
    )
  }

  return (
    <Container>
      {!bags.length ? (
        <EmptyContainer>
          <EmptyText>
            It&apos;s Phase 1 of our minting schedule. Only OG Loot holders can mint right now!
            <br />
            <br />
            <i style={{ fontSize: "1rem" }}>
              Whitelist mint starting on 23rd Dec 12:00am EST
              <br />
              Public mint starting on 23rd Dec 1:00pm EST
            </i>
          </EmptyText>
        </EmptyContainer>
      ) : (
        <>
          <SelectLootsContainer isDisabled={transaction?.state === "started"}>
            {bags.map((bag) => {
              const bagSelected = selectedLootIds.includes(bag.id)
              const bagMinted = mintedBagIds.includes(bag.id)
              return (
                <LootBag
                  key={bag.id}
                  selected={bagSelected}
                  onClick={() => !bagMinted && selectLootId(bag.id)}
                  alreadyMinted={bagMinted}
                >
                  <LootText>
                    <b>Bag #{bag.id}</b>
                    <br />
                  </LootText>
                  <LootText>{bag.weapon}</LootText>
                  <LootText>{bag.chest}</LootText>
                  <LootText>{bag.head}</LootText>
                  <LootText>{bag.waist}</LootText>
                  <LootText>{bag.foot}</LootText>
                  <LootText>{bag.hand}</LootText>
                  <LootText>{bag.neck}</LootText>
                  <LootText>{bag.ring}</LootText>
                  {bagMinted ? (
                    <BagMintedOverlay>Already minted</BagMintedOverlay>
                  ) : (
                    <CheckmarkContainer selected={bagSelected}>
                      {bagSelected && <CheckIcon width={CHECKMARK_ICON_SIZE} height={CHECKMARK_ICON_SIZE} />}
                    </CheckmarkContainer>
                  )}
                </LootBag>
              )
            })}
          </SelectLootsContainer>
          {RenderMintButton}
        </>
      )}
    </Container>
  )
}

export default OGMint
