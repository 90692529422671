import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { BackendBaseURL } from "../config/constants"
import { ExplorerMetadataAttribute } from "../models/Explorer"

const perPage = 30
export type ExplorerAttributeFilter = Partial<{ [filter in ExplorerMetadataAttribute]: string[] }>

const makeQueryObj = (filter: ExplorerAttributeFilter) => {
  const queryObj: Partial<{ [filter in ExplorerMetadataAttribute]: string }> = {}
  Object.keys(filter).forEach((filterKey) => {
    const fKey = filterKey as ExplorerMetadataAttribute
    const filterValues = filter[fKey]
    if (filterValues && filterValues.length) {
      queryObj[fKey] = filterValues.join(",")
    }
  })
  return queryObj
}

const useFilterExplorers = (filter: ExplorerAttributeFilter) => {
  const [data, setData] = useState<{ id: string; name: string }[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [initialLoaded, setInitialLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  const [, setCounter] = useState(0)

  const loadInitialData = useCallback(async (filter: ExplorerAttributeFilter) => {
    setLoading(true)
    setInitialLoaded(false)

    /**
     * First, we set counter. This keep track if we are searching the latest data
     */
    let currentCount: number
    setCounter((prev) => {
      currentCount = prev + 1
      return prev + 1
    })

    const { data } = await axios.get<{ data: { id: string; name: string }[]; total: number }>(
      `${BackendBaseURL}/explorers-filter`,
      {
        params: { ...makeQueryObj(filter), page: 1, perPage },
      },
    )

    setCounter((prev) => {
      if (prev === currentCount) {
        setData(data.data)
        setPage(1)
        setTotal(data.total)
        setLoading(false)
        setInitialLoaded(true)
      }

      return prev
    })
  }, [])

  useEffect(() => {
    loadInitialData(filter)
  }, [filter, loadInitialData])

  const loadMoreData = useCallback(async () => {
    const currentPage = page

    if (currentPage * perPage >= total) {
      return
    }

    setLoading(true)

    /**
     * First, we set counter. This keep track if we are searching the latest data
     */
    let currentCount: number
    setCounter((prev) => {
      currentCount = prev + 1
      return prev + 1
    })

    const { data } = await axios.get<{ data: { id: string; name: string }[]; total: number }>(
      `${BackendBaseURL}/explorers-filter`,
      {
        params: { ...makeQueryObj(filter), page: currentPage + 1, perPage },
      },
    )

    setCounter((prev) => {
      if (prev === currentCount) {
        setData((currData) => [...currData, ...data.data])
        setPage(currentPage + 1)
        setLoading(false)
      }

      return prev
    })
  }, [filter, page, total])

  return { data, initialLoaded, total, loadMoreData, loading }
}

export default useFilterExplorers
