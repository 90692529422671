import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import styled, { css, keyframes } from "styled-components"
import HideoutsIcon from "../assets/icons/hideouts.webp"
import HideoutsBgIcon from "../assets/icons/hideouts-bg.webp"
import LetterImage from "../assets/letter.gif"
import LetterVideo from "../assets/letter.webm"
import MailboxClosed from "../assets/mailbox/mailbox_closed.png"
import MailboxOpened from "../assets/mailbox/mailbox_opened.png"
import { isProduction } from "../config/env"
import { ForegroundImage, LetterStillImage } from "../config/themedAssets"
import { useBgm } from "../hooks/useBgm"
import useFetchDiscordConnect from "../hooks/useDiscordConnect"
import useEagerConnect from "../hooks/useEagerConnect"
import useIsMobile from "../hooks/useIsMobile"
import { useNavigation } from "../hooks/useNavigation"
import { usePuzzle } from "../hooks/usePuzzle"
import useScreenSize from "../hooks/useScreenSize"
import Home from "../pages/Home"
import { useGlobalState } from "../store/state"
import { isIos } from "../utils/os"
import { getSoundPreferences, isFirstLaunch, updateLastLaunchedDate, updateSoundPreferences } from "../utils/storage"
import Background from "./Background"
import { clickable, clickableScale } from "./common/common"
import WalletConnectModal from "./common/wallet/WalletConnectModal"
import Earl from "./Earl"
import FullscreenContent from "./FullscreenContent"
import GifOrWebm from "./GifOrWebm"
import Grass from "./Grass"
import Header from "./Header"
import MailboxPosterModal from "./MailboxPosterModal"
import StartGraphics from "./StartGraphics"
import StumpControls from "./StumpControls"

const TelescopeScene = lazy(() => import("./TelescopeScene"))
const SoupScene = lazy(() => import("./SoupScene"))

const PRE_START_SCALE = 1.2
const FOREGROUND_START_TRANSITION_S = 2
const MOVE_DOWN_Y = "6vw"

const Root = styled.div<{ screenHeight: number }>`
  height: ${(props) => props.screenHeight}px;
`

const NavigationContainer = styled.div<{ height: number }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

const Foreground = styled.img.attrs<{ started: boolean; moveDown: boolean }>({
  src: ForegroundImage,
})<{ started: boolean; moveDown: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  pointer-events: none;
  z-index: 1;

  transition: ${FOREGROUND_START_TRANSITION_S}s transform ease;
  transform: ${(props) => {
    let transform = ""
    if (props.started) {
      transform += "scale(1)"
    } else {
      transform += `scale(${PRE_START_SCALE})`
    }
    if (props.moveDown) {
      transform += ` translateY(${MOVE_DOWN_Y})`
    }
    return transform
  }};
`

const HideoutsButtonContainer = styled.a<{ started: boolean }>`
  ${clickableScale}

  position: absolute;
  z-index: 4;
  bottom: 87vh;
  left: ${(props) => (props.started ? "5vh" : "-8vh")};
  height: 8vh;
  width: 8vh;
  transition: 2s left ease-in-out, 0.2s transform ease;
`

const rotateAnim = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

const HideoutsButtonImg = styled.img<{ rotate?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.rotate
      ? css`
          animation: ${rotateAnim} 8s linear infinite;
        `
      : ""}
`

const MailboxContainer = styled.img<{ moveDown: boolean }>`
  position: absolute;
  bottom: 9vw;
  left: 28vw;
  width: 12.5vw;
  display: flex;
  transition: 0.5s transform ease-in-out;

  transform: ${(props) => {
    if (props.moveDown) {
      return "translateY(25vh)"
    }
    return "translateY(0)"
  }};

  &:hover {
    transform: ${(props) => {
      if (props.moveDown) {
        return "scale(0.9) translateY(10vh)"
      }
      return "scale(0.9) translateY(0)"
    }};
  }
`

const LetterContainer = styled.div<{ hide: boolean; moveDown: boolean }>`
  position: relative;
  z-index: 2;
  transition: opacity 0.4s ease ${FOREGROUND_START_TRANSITION_S}s, transform ${FOREGROUND_START_TRANSITION_S}s ease;
  opacity: ${(props) => {
    if (props.hide) {
      return "0"
    }
    return "1"
  }};

  transform: ${(props) => {
    if (props.moveDown) {
      return `translateY(${MOVE_DOWN_Y})`
    }
    return "translateY(0)"
  }};
`

const LetterInnerContainer = styled.div<{ hide: boolean }>`
  ${({ hide }) => (hide ? "" : clickable)}
  position: absolute;
  bottom: 1.7vw;
  right: 8vw;
  width: 14vw;
  pointer-events: auto;
`

const LetterStill = styled.img.attrs<{ hide: boolean }>({
  src: LetterStillImage,
})<{ hide: boolean }>`
  ${({ hide }) => (hide ? "" : clickable)}
  position: absolute;
  bottom: 6.4vw;
  right: 10.5vw;
  width: 8.5vw;
  pointer-events: auto;
`

const IMAGE_ASPECT_RATIO = 1080 / 1920
const EARL_ASPECT_RATIO = 207 / 1920
const HEADER_HEIGHT = 80

const RootApp = () => {
  const { height: screenHeight, width: screenWidth } = useScreenSize()
  const navigationHeight = screenWidth * IMAGE_ASPECT_RATIO + 10
  const earlWidth = screenWidth * EARL_ASPECT_RATIO

  const [started, setStarted] = useState(false)
  const [snowOn, setSnowOn] = useState(false)
  const [mailboxImg, setMailboxImg] = useState(MailboxClosed)
  const [showMailboxModal, setShowMailboxModal] = useState(false)
  const [animateLetter, setAnimateLetter] = useState(false)
  const [showTelescopeScene] = useGlobalState("showTelescopeScene")
  const [showSoupScene] = useGlobalState("showSoupScene")
  const [history] = useGlobalState("browserHistory")

  // Trigger puzzle to be generated
  usePuzzle()
  useEagerConnect()
  useFetchDiscordConnect()

  const { play, pause, isPlaying } = useBgm()
  const { currentModalContentId, currentFullscreenContentId, navigateTo } = useNavigation()
  const { isMobile } = useIsMobile()

  const isModalPresented = useMemo(() => {
    return Boolean(started && currentModalContentId)
  }, [started, currentModalContentId])

  useEffect(() => {
    // Show start button if user is first launch
    // On development, always show start button
    if (!isFirstLaunch()) {
      setStarted(true)
    }
    updateLastLaunchedDate()

    // Toggle between animating and stopping letter
    // letter gif takes 2580ms to complete
    const interval = setInterval(
      () => {
        setAnimateLetter(!animateLetter)
      },
      animateLetter ? 2580 * 2 : 2580 * 4,
    )

    return () => {
      clearInterval(interval)
    }
  }, [animateLetter, currentModalContentId, navigateTo])

  // Auto-play when started and soundOn and is NOT iOS.
  useEffect(() => {
    const preferSoundOn = getSoundPreferences()
    if (started && !isIos() && preferSoundOn) {
      play().catch(() => {
        console.log("Error playing BGM")
      })
    }
    return () => {
      pause()
    }
  }, [started, play, pause])

  const onStart = useCallback(() => {
    setStarted(true)
  }, [])

  const onToggleSound = useCallback(
    (on?: boolean) => {
      // If on is provided, set it to preference
      if (on === true) {
        play()
        updateSoundPreferences(true)
      } else if (on === false) {
        pause()
        updateSoundPreferences(false)
      } else if (!isPlaying) {
        // If on is undefined, just toggle
        updateSoundPreferences(true)
        play()
      } else {
        updateSoundPreferences(false)
        pause()
      }
    },
    [play, pause, isPlaying],
  )

  const onToggleSnow = useCallback(() => {
    setSnowOn((prev) => !prev)
  }, [])

  const onLetterClick = useCallback(() => {
    navigateTo("about")
  }, [navigateTo])

  return (
    <Root id="appRoot" screenHeight={screenHeight}>
      <Background
        started={started}
        soundOn={isPlaying}
        snowOn={snowOn}
        setSoundOn={onToggleSound}
        moveDownBy={isModalPresented ? MOVE_DOWN_Y : "0"}
      />
      {!started ? (
        <StartGraphics onStart={onStart} />
      ) : (
        <Router history={history}>
          <div style={{ paddingTop: HEADER_HEIGHT, height: screenHeight, overflow: "hidden" }}>
            <Switch>
              <Route path="/" exact>
                <Home headerHeight={HEADER_HEIGHT} />
              </Route>
              <Route path="/:page" exact>
                <Home headerHeight={HEADER_HEIGHT} />
              </Route>
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
        </Router>
      )}
      {!currentFullscreenContentId && (
        <>
          <NavigationContainer height={navigationHeight}>
            <Foreground started={started} moveDown={isModalPresented} />
            <StumpControls
              hide={!started}
              moveDownBy={isModalPresented ? (isMobile ? `calc(${MOVE_DOWN_Y} * 10)` : MOVE_DOWN_Y) : "0"}
            />
            <Earl
              started={started}
              moveDownBy={isModalPresented ? MOVE_DOWN_Y : undefined}
              earlWidth={earlWidth}
              transitionSeconds={FOREGROUND_START_TRANSITION_S}
            />
            <HideoutsButtonContainer
              started={started}
              href={
                isProduction()
                  ? "https://hideouts.lootexplorers.quest/"
                  : "https://staging-campgrounds.lootexplorers.quest/"
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="d-flex w-100 h-100 position-relative">
                <HideoutsButtonImg src={HideoutsBgIcon} rotate />
                <HideoutsButtonImg src={HideoutsIcon} />
              </div>
            </HideoutsButtonContainer>
            {started && (
              <MailboxContainer
                moveDown={isModalPresented}
                src={mailboxImg}
                onMouseEnter={() => setMailboxImg(MailboxOpened)}
                onMouseOut={() => setMailboxImg(MailboxClosed)}
                alt="Mailbox"
                role="button"
                onClick={() => setShowMailboxModal(true)}
              />
            )}
            <MailboxPosterModal show={showMailboxModal} onClose={() => setShowMailboxModal(false)} />

            {animateLetter ? (
              <LetterContainer hide={!started} onClick={onLetterClick} moveDown={isModalPresented}>
                <LetterInnerContainer hide={!started}>
                  <GifOrWebm gifSrc={LetterImage} videoUrl={LetterVideo} />
                </LetterInnerContainer>
              </LetterContainer>
            ) : (
              <LetterContainer hide={!started} onClick={onLetterClick} moveDown={isModalPresented}>
                <LetterStill hide={!started} />
              </LetterContainer>
            )}
            <Grass started={started} />
          </NavigationContainer>
          <Suspense fallback={null}>
            {started && showTelescopeScene && <TelescopeScene />}
            {started && showSoupScene && <SoupScene />}
            {started && <WalletConnectModal />}
          </Suspense>
        </>
      )}
      <FullscreenContent />
      <Header
        started={started}
        soundOn={isPlaying}
        toggleSound={onToggleSound}
        snowOn={snowOn}
        toggleSnow={onToggleSnow}
      />
    </Root>
  )
}

export default RootApp
