import { useCallback, useEffect } from "react"
import { useGlobalState } from "../store/state"

/**
 * Global hook for the BGM
 */
export const useBgm = () => {
  const [audio] = useGlobalState("backgroundMusic")
  const [isPlaying, setIsPlaying] = useGlobalState("isBackgroundMusicPlaying")

  const play = useCallback(async () => {
    await audio.play()
    setIsPlaying(true)
  }, [audio, setIsPlaying])

  const pause = useCallback(() => {
    audio.pause()
    setIsPlaying(false)
  }, [audio, setIsPlaying])

  const onEndListener = useCallback(async () => {
    if (audio.paused) {
      audio.currentTime = 0
      await play()
    }
  }, [play, audio])

  useEffect(() => {
    audio.addEventListener("ended", onEndListener)
  }, [audio, play, pause, onEndListener])

  return {
    play,
    pause,
    isPlaying,
  }
}
