import About from "./content/About"
import Faq from "./content/Faq"
import GalleryView from "./content/Gallery/GalleryView"
import Lore from "./content/Lore"
import MintContent from "./content/Minting/MintContent"
import Social from "./content/social/Social"

export interface IModalContentProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>
}

export interface IModalContentPage {
  id: string
  renderContent: (props: IModalContentProps) => JSX.Element
}

export const contentIds = [
  "lore",
  "about",
  "faq",
  "mintLoot",
  "mintFirstExplorers",
  "mintPublic",
  "discord",
  "gallery",
  "pastEvents",
] as const
export type ContentIdType = typeof contentIds[number]
export type ContentIdsToContentPage = {
  [key in ContentIdType]: IModalContentPage
}

export const contents: ContentIdsToContentPage = {
  lore: {
    id: "lore",
    renderContent: () => <Lore />,
  },
  about: {
    id: "about",
    renderContent: () => <About />,
  },
  faq: {
    id: "faq",
    renderContent: () => <Faq />,
  },
  mintLoot: {
    id: "mintLoot",
    renderContent: () => <MintContent type="og" />,
  },
  mintFirstExplorers: {
    id: "mintFirstExplorers",
    renderContent: () => <MintContent type="whitelist" />,
  },
  mintPublic: {
    id: "mintPublic",
    renderContent: () => <MintContent type="public" />,
  },
  discord: {
    id: "discord",
    renderContent: () => <Social />,
  },
  gallery: {
    id: "gallery",
    renderContent: () => <GalleryView />,
  },
  pastEvents: {
    id: "pastEvents",
    renderContent: () => <Social />,
  },
}
