import { TransactionReceipt } from "@ethersproject/abstract-provider"
import { createBrowserHistory, History } from "history"
import { createGlobalState } from "react-hooks-global-state"
import Song from "../assets/sounds/bgm.mp3"
import { Loot } from "../codegen"
import { FullscreenContentIdType } from "../components/FullscreenContent"
import { ContentIdType } from "../components/ModalContentPage"
import { Bag } from "../models/Loot"
import { CompletedPuzzle } from "../models/Puzzle"

export type ModalContentIdNavigation = {
  [key in ContentIdType]?: {
    totalPages: number
    currentPage: number
  }
}

export type SaleStage = "lootSoon" | "loot" | "whitelistSoon" | "whitelist" | "publicSoon" | "public"
export interface FirebaseAdminConfig {
  submissionLimit: number
}

interface GlobalStore {
  browserHistory: History

  // Global BGM
  backgroundMusic: HTMLAudioElement
  isBackgroundMusicPlaying: boolean

  bagsLoading: boolean
  bags: Bag[]

  currentModalContentId?: ContentIdType
  modalContentIdNavigation: ModalContentIdNavigation

  currentFullscreenContentId?: FullscreenContentIdType

  // SCENES
  showTelescopeScene: boolean
  showSoupScene: boolean

  showConnectWallet: boolean
  lootContract?: Loot
  saleStage?: SaleStage

  transaction?: {
    state: "started" | "processing" | "completed" | "error"
    hash?: string // Only available during state = "processing" | "completed"
    receipt?: TransactionReceipt // Only available during state = "completed"
    error?: string // Any errors
  }

  // Firebase config
  firebaseAdminConfig?: FirebaseAdminConfig

  // PUZZLE
  totalPuzzleSolved: number // total amount of puzzle solved
  puzzle?: CompletedPuzzle
  collectDiscord: boolean
  discordName?: string
  puzzleSolved: boolean

  discordConnect: {
    loading: boolean
    data: { connected: boolean; accounts?: Array<{ username: string }> }
  }

  // SIWE
  siweToken?: string
}

export const initialState: GlobalStore = {
  browserHistory: createBrowserHistory(),

  // Global BGM
  backgroundMusic: new Audio(Song),
  isBackgroundMusicPlaying: false,

  bagsLoading: false,
  bags: [],
  currentModalContentId: undefined,
  modalContentIdNavigation: {
    lore: {
      totalPages: 2,
      currentPage: 0,
    },
  },
  currentFullscreenContentId: undefined,

  // SCENES
  showTelescopeScene: false,
  showSoupScene: false,

  showConnectWallet: false,
  lootContract: undefined,
  saleStage: undefined,
  transaction: undefined,

  firebaseAdminConfig: undefined,
  totalPuzzleSolved: 0,
  puzzle: undefined,
  collectDiscord: false,
  discordName: undefined,
  puzzleSolved: false,

  // Discord
  discordConnect: {
    loading: true,
    data: { connected: false },
  },

  // SIWE
  siweToken: undefined,
}

export const { useGlobalState } = createGlobalState(initialState)
