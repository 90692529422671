import { motion, useAnimation } from "framer-motion"
import { useCallback, useState } from "react"
import styled from "styled-components"
import LogoImage from "../assets/webp/logo.webp"
import StartImage from "../assets/webp/start.webp"
import useIsMobile from "../hooks/useIsMobile"
import { colors } from "../styles"
import sizes from "../utils/sizes"
import { clickable } from "./common/common"

const ANIM_DURATION = 0.6

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
`
const LogosContainer = styled.div<{ animate: boolean }>`
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  position: absolute;
  width: 50vw;

  transition: top ${ANIM_DURATION}s ease-in-out;
  transition-delay: 0.2s;
  top: ${({ animate }) => (animate ? "10vh" : "25vh")};

  @media (min-width: ${sizes.md}px) {
    width: 30vmin;
    top: 25vh;
  }
`

const Logo = styled.img.attrs<{ fade: boolean }>({
  src: LogoImage,
})<{ fade: boolean }>`
  object-fit: contain;
  width: 100%;

  transition: opacity ${ANIM_DURATION}s ease-in-out;
  transition-delay: 0.2s;
  opacity: ${({ fade }) => (fade ? 0 : 1)};
`
const Start = styled.img.attrs({
  src: StartImage,
})`
  ${clickable};
  width: 100%;
  object-fit: contain;
  transition: 0.2s all ease;

  &:hover {
    transform: scale(0.9);
  }
`
const MobileText = styled.p`
  text-align: center;
  color: ${colors.brown};
  padding: 8px;
`

interface StartGraphicsProps {
  onStart: () => void
}

const StartGraphics = (props: StartGraphicsProps) => {
  const { onStart } = props
  const startControls = useAnimation()
  const { isMobile } = useIsMobile()
  const [startLogoAnim, setStartLogoAnim] = useState(false)

  const onStartClick = useCallback(async () => {
    setStartLogoAnim(true)
    const startAnimPromise = startControls.start({
      opacity: -1,
      transition: {
        delay: 0.2,
        duration: ANIM_DURATION,
        ease: "easeInOut",
      },
    })
    await startAnimPromise
    onStart()
  }, [startControls, onStart])

  return (
    <Container>
      <LogosContainer animate={startLogoAnim}>
        <Logo fade={!isMobile && startLogoAnim} />
        <motion.div animate={startControls}>
          <Start onClick={onStartClick} />
          {isMobile && <MobileText>Please view on a desktop browser for the best experience.</MobileText>}
        </motion.div>
      </LogosContainer>
    </Container>
  )
}

export default StartGraphics
