import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { getAllegianceLogoSrc } from "../../../assets/logo/allegiance/allegiance"
import { getOriginsLogoSrc } from "../../../assets/logo/origins/origins"
import { getRaceLogoSrc } from "../../../assets/logo/race/allegiance"
import ReturnImage from "../../../assets/webp/map/return.webp"
import { BackendBaseURL } from "../../../config/constants"
import useExplorerData from "../../../hooks/useExplorerData"
import { EXPLORER_ATTRIBUTE_NAME_MAP, explorerAttributes } from "../../../models/Explorer"
import sizes from "../../../utils/sizes"
import { clickableShrunk, PrimaryText, SecondaryText, Subtitle, Title } from "../../common/common"
import Loader from "../../common/Loader"

const ExplorerImage = styled.img`
  width: 100%;

  @media (min-width: ${sizes.md}px) {
    width: 50%;
  }
`

const ExplorerPrimaryInfoContainer = styled.div.attrs({ className: "d-flex flex-column align-items-start" })`
  width: 100%;
  margin-top: 24px;

  @media (min-width: ${sizes.md}px) {
    margin-top: 0px;
    padding-left: 24px;
    width: 50%;
  }
`

const ExplorerName = styled(Title)`
  text-align: left;
`

const ExplorerBadgeContainer = styled.div.attrs({ className: "w-100 mt-4" })`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

const ExplorerBadge = styled.div.attrs({ className: "d-flex flex-wrap justify-content-center" })``

const ExplorerBadgeIcon = styled.div.attrs({ className: "mt-3 mb-1" })<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  width: 100%;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`

const ExplorerAttributesContainer = styled.div.attrs({ className: "d-flex flex-wrap w-100 mt-4" })``

const ExplorerAttribute = styled.div.attrs({ className: "d-flex flex-column align-items-start mb-1" })`
  margin-right: 8px;
  border: 1px solid black;
  padding: 4px 8px;
  border-radius: 8px;
`

const ReturnButton = styled.img.attrs({
  src: ReturnImage,
})`
  ${clickableShrunk}

  width: 160px;
`

interface GalleryExplorerContentProps {
  explorerId: string
}

const GalleryExplorerContent: React.FC<GalleryExplorerContentProps> = ({ explorerId }) => {
  const { data: explorer, loading } = useExplorerData(explorerId)

  if (!explorer || loading) {
    return (
      <div className="d-flex justify-content-center flex-wrap p-5">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex mb-3">
        <Link to={`/gallery`}>
          <ReturnButton />
        </Link>
      </div>
      <div className="d-flex flex-wrap align-items-start p-3">
        <ExplorerImage src={`${BackendBaseURL}/explorers/${explorerId}`} />
        <ExplorerPrimaryInfoContainer>
          <Subtitle fontSize={24} fontWeight={500} className="mb-1">
            #{explorerId}
          </Subtitle>
          <ExplorerName fontSize={32} normalCased>
            {explorer.name}
          </ExplorerName>
          <ExplorerBadgeContainer>
            <ExplorerBadge>
              <Title normalCased fontSize={14}>
                Race
              </Title>
              <ExplorerBadgeIcon imageUrl={getRaceLogoSrc(explorer.race)} />
              <SecondaryText>{explorer.race}</SecondaryText>
            </ExplorerBadge>
            <ExplorerBadge>
              <Title normalCased fontSize={14}>
                Allegiance
              </Title>
              <ExplorerBadgeIcon imageUrl={getAllegianceLogoSrc(explorer.allegiance)} />
              <SecondaryText>{explorer.allegiance}</SecondaryText>
            </ExplorerBadge>
            <ExplorerBadge>
              <Title normalCased fontSize={14}>
                Origins
              </Title>
              <ExplorerBadgeIcon imageUrl={getOriginsLogoSrc(explorer.origins)} />
              <SecondaryText>{explorer.origins}</SecondaryText>
            </ExplorerBadge>
          </ExplorerBadgeContainer>
        </ExplorerPrimaryInfoContainer>

        <ExplorerAttributesContainer>
          {explorerAttributes
            .filter((attribute) =>
              Boolean(explorer[attribute] && !["race", "allegiance", "origins"].includes(attribute)),
            )
            .map((attribute) => (
              <ExplorerAttribute key={attribute}>
                <Subtitle>{EXPLORER_ATTRIBUTE_NAME_MAP[attribute]}</Subtitle>
                <Title fontSize={16} fontWeight={600} normalCased>
                  {explorer[attribute]}
                </Title>
              </ExplorerAttribute>
            ))}
        </ExplorerAttributesContainer>

        <div className="d-flex flex-column align-items-start mt-5">
          <PrimaryText style={{ textAlign: "left" }}>{explorer.description}</PrimaryText>
        </div>
      </div>
    </>
  )
}

export default GalleryExplorerContent
