import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import ArrowLeftImage from "../../assets/icons/arrow-left.webp"
import ArrowRightImage from "../../assets/icons/arrow-right.webp"
import CloseButtonImage from "../../assets/icons/button-close.webp"
import PageImage from "../../assets/webp/page.webp"
import { useNavigation } from "../../hooks/useNavigation"
import { ModalContentIdNavigation, useGlobalState } from "../../store/state"
import { colors } from "../../styles"
import sizes from "../../utils/sizes"
import { contents } from "../ModalContentPage"
import { clickableScale, clickableShrunk, scrollbarStyle } from "./common"

const ANIM_DURATION = 0.4

const Container = styled.div<{ top: number }>`
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div<{ show: boolean }>`
  position: relative;
  height: 100%;
  transition: ${ANIM_DURATION}s transform ease;

  width: 95vw;

  padding: 5% 16px 20% 16px;
  transform: ${({ show }) => {
    if (show) {
      return "translateY(0)"
    }
    return "translateY(110%)"
  }};

  @media (min-width: ${sizes.md}px) {
    width: 80vw;
  }

  @media (min-width: ${sizes.lg}px) {
    width: 70vw;
  }

  @media (min-width: ${sizes.xl}px) {
    width: 60vw;
  }

  @media (min-width: ${sizes.xxl}px) {
    width: 55vw;
  }

  @media (min-width: ${sizes.xxxl}px) {
    width: 45vw;
  }
`

const PageBackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: -1;
`
const PageBackground = styled.img.attrs({
  src: PageImage,
})`
  width: 100%;
`
const PageBackgroundContinuation = styled.div`
  height: 300vh;
  background-color: ${colors.pale};

  margin: 0 3vw 0 3vw;
  border-left: 0.62vw solid ${colors.brown};
  border-right: 0.62vw solid ${colors.brown};

  @media (min-width: ${sizes.md}px) {
    margin: 0 2.15vw 0 2.15vw;
    border-left: 0.4vw solid ${colors.brown};
    border-right: 0.4vw solid ${colors.brown};
  }

  @media (min-width: ${sizes.xxl}px) {
    margin: 0 1.95vw 0 1.95vw;
    border-left: 0.42vw solid ${colors.brown};
    border-right: 0.42vw solid ${colors.brown};
  }

  @media (min-width: ${sizes.xxxl}px) {
    margin: 0 1.44vw 0 1.44vw;
    border-left: 0.28vw solid ${colors.brown};
    border-right: 0.28vw solid ${colors.brown};
  }
`

const Scrollable = styled.div`
  ${scrollbarStyle}
  height: 90vh;
  overflow-y: auto;
  padding-bottom: 50%;
  margin: 0 2.2vw;

  @media (min-width: ${sizes.md}px) {
    margin: -2vw 2.15vw 0 2.15vw;
    padding-bottom: 40%;
  }

  @media (min-width: ${sizes.xxl}px) {
    padding-bottom: 40%;
  }
`

const CloseButton = styled.img.attrs({
  src: CloseButtonImage,
})`
  ${clickableShrunk}
  width: 5vw;
  height: 5vw;
  position: absolute;
  top: -1vw;
  right: -1vw;

  @media (max-width: ${sizes.sm}px) {
    width: 40px;
    height: 40px;
    top: -10px;
    right: -10px;
  }
`

const arrowStyles = css`
  width: 10vw;
  position: absolute;
  top: 38.5vw;

  @media (min-width: ${sizes.md}px) {
    width: 6vw;
    top: 28vw;
  }

  @media (min-width: ${sizes.xxl}px) {
    top: 25vw;
  }

  @media (min-width: ${sizes.xxxl}px) {
    width: 4.5vw;
    top: 18.2vw;
  }
`
const ArrowLeft = styled.img.attrs<{ enabled: boolean }>({
  src: ArrowLeftImage,
})<{ enabled: boolean }>`
  ${clickableScale}
  ${arrowStyles}
  left: -6vw;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.3)};

  @media (min-width: ${sizes.md}px) {
    left: -3.5vw;
  }

  @media (min-width: ${sizes.xxxl}px) {
    left: -2.655vw;
  }
`
const ArrowRight = styled.img.attrs<{ enabled: boolean }>({
  src: ArrowRightImage,
})<{ enabled: boolean }>`
  ${clickableScale}
  ${arrowStyles}
  right: -6.5vw;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.3)};

  @media (min-width: ${sizes.md}px) {
    right: -3.8vw;
  }

  @media (min-width: ${sizes.xxxl}px) {
    right: -2.855vw;
  }
`

interface ModalProps {
  top: number
}

const Modal: React.FC<ModalProps> = (props) => {
  const { top } = props
  const [showModal, setShowModal] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const { currentModalContentId, navigateTo } = useNavigation()
  const [currentContentId, setCurrentContentId] = useState(currentModalContentId)
  const [contentNavigation, setContentNavigation] = useGlobalState("modalContentIdNavigation")

  const navigation = useMemo(() => {
    if (currentModalContentId) {
      return contentNavigation[currentModalContentId]
    }
    return undefined
  }, [currentModalContentId, contentNavigation])

  const onNextPage = useCallback(() => {
    if (!currentModalContentId) {
      return
    }
    const nextPageNumber = contentNavigation[currentModalContentId]
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        contentNavigation[currentModalContentId]!.currentPage + 1
      : 0
    const newNavigation: ModalContentIdNavigation = {
      ...contentNavigation,
      [currentModalContentId]: {
        ...contentNavigation[currentModalContentId],
        currentPage: nextPageNumber,
      },
    }
    setContentNavigation(newNavigation)
  }, [contentNavigation, currentModalContentId, setContentNavigation])

  const onPrevPage = useCallback(() => {
    if (!currentModalContentId) {
      return
    }
    const previousPageNumber = contentNavigation[currentModalContentId]
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        contentNavigation[currentModalContentId]!.currentPage - 1
      : 0
    const newNavigation: ModalContentIdNavigation = {
      ...contentNavigation,
      [currentModalContentId]: {
        ...contentNavigation[currentModalContentId],
        currentPage: previousPageNumber,
      },
    }
    setContentNavigation(newNavigation)
  }, [contentNavigation, currentModalContentId, setContentNavigation])

  const ModalContent = useMemo(() => {
    if (currentContentId) {
      return contents[currentContentId].renderContent({ scrollContainerRef })
    }
    return null
  }, [currentContentId])

  /**
   * Whenever the next content changes:
   * 1. Hide modal
   * 2. setCurrentContentId to the next
   * 3. Show modal
   */
  useEffect(() => {
    setShowModal(false)

    const timeout = setTimeout(() => {
      setCurrentContentId(currentModalContentId)
      setShowModal(Boolean(currentModalContentId))
    }, (ANIM_DURATION / 2) * 1000)
    return () => clearTimeout(timeout)
  }, [currentModalContentId, setCurrentContentId])

  const onClose = useCallback(() => {
    navigateTo(undefined)
  }, [navigateTo])

  return (
    <Container top={top}>
      <Content show={showModal}>
        <PageBackgroundContainer>
          <PageBackground />
          <PageBackgroundContinuation />
        </PageBackgroundContainer>
        <CloseButton onClick={onClose} />
        <Scrollable ref={scrollContainerRef}>{ModalContent}</Scrollable>
        {navigation && (
          <>
            <ArrowLeft enabled={navigation.currentPage > 0} onClick={onPrevPage} />
            <ArrowRight enabled={navigation.currentPage < navigation.totalPages - 1} onClick={onNextPage} />
          </>
        )}
      </Content>
    </Container>
  )
}

export default Modal
