import axios from "axios"
import { chunk } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { BackendBaseURL, getSubgraphBaseUrl } from "../config/constants"
import { IExplorer, IExplorerMetadata } from "../models/Explorer"
import { convertIExplorerMetadataToIExplorer } from "../utils/explorer"

/**
 * Retrieve
 */
export const useExplorerSubgraph = (account?: string) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [explorers, setExplorers] = useState<IExplorer[]>([])

  const loadExplorersForAccount = useCallback(
    async (force = false) => {
      if (!account) {
        return
      }
      try {
        setLoading(true)
        setExplorers([])
        const { data } = await axios.post<{
          data: {
            explorers: { id: string }[]
          }
        }>(getSubgraphBaseUrl(), {
          query: `
        {
          explorers(where:{owner: "${account.toLowerCase()}"}) {
            id
          }
        }
        `,
        })

        // Retrieve explorer metadata from ipfs
        // Split to multiple chunks of 10
        const chunkedExplorerIds = chunk(
          data.data.explorers.map((explorer) => explorer.id),
          10,
        )
        for (let i = 0; i < chunkedExplorerIds.length; i++) {
          const newExplorers = await Promise.all(
            chunkedExplorerIds[i].map(async (id) => {
              const { data } = await axios.get<IExplorerMetadata>(`${BackendBaseURL}/metadata/${id}`)

              return convertIExplorerMetadataToIExplorer(id, data)
            }),
          )
          setExplorers((existingExplorers) => {
            return [...existingExplorers, ...newExplorers]
          })
        }
      } catch (error) {
        console.log("ERROR: ", error)
        setExplorers([])
      } finally {
        setLoading(false)
      }
    },
    [account],
  )

  const refreshExplorers = useCallback(() => {
    loadExplorersForAccount(true)
  }, [loadExplorersForAccount])

  useEffect(() => {
    loadExplorersForAccount()
  }, [loadExplorersForAccount])

  return {
    loading,
    explorers,
    refreshExplorers,
  }
}
