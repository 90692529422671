import styled from "styled-components"
import { colors } from "../../styles"
import { clickable } from "./common"

export const Button = styled.button`
  ${clickable}
  font-size: 21px;
  font-weight: bold;
  color: ${colors.darkGreen};
  padding: 6px 8px;
  position: relative;
  background-color: ${colors.lightGreen};
  border: 3.5px solid ${colors.darkGreen};
  border-radius: 14px;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
`
