import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import ExplorersShowcaseGif from "../../../assets/explorers.gif"
import ExplorersShowcaseImage from "../../../assets/explorers.webm"
import { ReactComponent as ETHIcon } from "../../../assets/icons/eth.svg"
import { ReactComponent as Opensea } from "../../../assets/icons/opensea.svg"
import ExplorerPlaceholderImage from "../../../assets/webp/minting/placeholder.webp"
import useExplorerContract from "../../../hooks/contracts/useExplorerContract"
import { useNavigation } from "../../../hooks/useNavigation"
import { useGlobalState } from "../../../store/state"
import { colors } from "../../../styles"
import sizes from "../../../utils/sizes"
import { processLog } from "../../../utils/transaction"
import { getEtherscanUrl, openseaAssetBaseUrl, openseaCollectionUrl } from "../../../utils/urls"
import BasicModal from "../../common/BasicModal"
import { clickableScale, scrollbarStyle } from "../../common/common"
import Loader from "../../common/Loader"
import GifOrWebm from "../../GifOrWebm"
import { Container } from "../styles"
import TitleBar from "../TitleBar"
import OGMint from "./OGMint"
import PublicWhitelistMint from "./PublicWhitelistMint"

const { REACT_APP_EXPLORER_IMAGE_BASE_URL } = process.env
const ETH_ICON_SIZE = 16
const OPENSEA_ICON_SIZE = 56

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-top: 3vh;
`

const PendingTxContainer = styled(LoadingContainer)`
  padding-top: 0;
`

const ExplorerShowcaseContainer = styled.div`
  padding: 16px 23%;
`

const MintedExplorersContainer = styled.div<{ onlyOne?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2%;
  width: 100%;

  padding: 32px ${({ onlyOne }) => (onlyOne ? "15%" : "3%")};

  @media (min-width: ${sizes.md}px) {
    grid-template-columns: ${({ onlyOne }) => (onlyOne ? "1fr" : "1fr 1fr")};
  }
`

const ExplorerImageContainer = styled.a`
  ${clickableScale}
  position: relative;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
`

const ExplorerImage = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
`

const ExplorerNameText = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
`

const PendingTransactionText = styled.p`
  margin-bottom: 0;
  font-size: 1.5rem;
`
const ViewOnEtherscan = styled.a``

const MintMoreButton = styled.button`
  ${clickableScale}
  font-weight: bold;
  font-size: 0.7rem;
  margin-top: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  color: ${colors.darkGreen};
  background-color: ${colors.lightGreen};
  border: 3px solid ${colors.darkGreen};

  @media (min-width: ${sizes.md}px) {
    font-size: 1rem;
    padding: 4px 8px;
  }
`

const ConnectWalletText = styled.h3`
  padding: 0 10vw;
`

const OuterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StickyTopContainer = styled.div`
  position: absolute;
  z-index: 1;
  background-color: ${colors.pale};
  left: 5vw;
  right: calc(5vw + 16px);

  @media (min-width: ${sizes.md}px) {
    left: 4vw;
    right: calc(3.5vw + 16px);
  }

  @media (min-width: ${sizes.xxxl}px) {
    left: 3vw;
    right: calc(3vw + 16px);
  }
`

const ContentContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop ?? "140px"};
  flex: 1;
`

const MintedAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
`

const MintPriceText = styled.div``

const MintedAmountText = styled.div`
  font-weight: bold;
  padding-right: 8px;
`

const WalletLimitMessage = styled.div`
  padding-bottom: 8px;
`

const DisclaimerMessage = styled.div`
  font-size: 0.7rem;
`

const ErrorTitle = styled.h4`
  font-weight: bold;
  color: ${colors.brown};
`

const ScrollableContent = styled.div`
  ${scrollbarStyle}
  overflow-y: auto;
  max-height: 85%;
`

const ErrorBody = styled.div`
  color: ${colors.brown};
`

const SoldOutContainer = styled.div`
  font-size: 1.2rem;
  padding: 0 24px;
  padding-top: 5vh;
`

const SoldOutText = styled.p`
  margin: 0;
  padding: 0;
`

const SecondaryMarketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`

const IconButton = styled.a`
  ${clickableScale}
`

interface MintContentProps {
  type: "og" | "whitelist" | "public"
}

/**
 * A wrapper that provides common functionality to all mint children
 */
const MintContent = ({ type }: MintContentProps) => {
  const { navigateTo } = useNavigation()
  const { library, active, account } = useWeb3React()
  const {
    saleStage,
    bags,
    lootLoading,
    mintedBagIds,
    mintedAmount,
    updateMintedAmount,
    updateMintedBagIds,
    generalMintingInfo,
    proof,
    ogMint,
    whitelistMint,
    publicMint,
    isSoldOut,
    isMintingSoon,
  } = useExplorerContract(library, account)

  const { currentModalContentId } = useNavigation()
  const [transaction, setTransaction] = useGlobalState("transaction")
  const [loading, setLoading] = useState(true)
  const [modalError, setModalError] = useState("")
  const topContainerRef = useRef<HTMLDivElement>(null)
  const [topContainerHeight, setTopContainerHeight] = useState(0)

  useEffect(() => {
    setTopContainerHeight(topContainerRef.current?.clientHeight ?? 0)
  }, [])

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    const updateTopContainerHeight = () => {
      setTopContainerHeight(topContainerRef.current?.clientHeight ?? 0)
    }
    window.addEventListener("resize", updateTopContainerHeight)
    return () => {
      window.removeEventListener("resize", updateTopContainerHeight)
    }
  }, [])

  // If wrong sale stage, automatically redirect.
  useEffect(() => {
    const isMintingContent =
      currentModalContentId === "mintLoot" ||
      currentModalContentId === "mintFirstExplorers" ||
      currentModalContentId === "mintPublic"
    if (saleStage && isMintingContent) {
      setLoading(false)

      // If minting soon and showing minting content, redirect
      if (isMintingSoon) {
        navigateTo(undefined)
        return
      }
      switch (saleStage) {
        case "loot":
          navigateTo("mintLoot")
          break
        case "whitelist":
          navigateTo("mintFirstExplorers")
          break
        case "public":
          navigateTo("mintPublic")
          break
        default:
          break
      }
    }
  }, [saleStage, navigateTo, currentModalContentId, isMintingSoon])

  // Poll for updated mint amount and sale stage
  useEffect(() => {
    // Update minted amount every 15 seconds
    const mintedAmountInterval = setInterval(() => {
      updateMintedAmount()
    }, 30000)

    return () => {
      clearInterval(mintedAmountInterval)
    }
  }, [updateMintedAmount])

  // Handle any errors from transaction
  useEffect(() => {
    if (transaction?.state === "error") {
      setModalError(transaction.error ?? "An error occured.")
      setTransaction(undefined)
    }
  }, [transaction, setTransaction])

  const onMintMore = useCallback(() => {
    updateMintedBagIds()
    setTransaction(undefined)
  }, [setTransaction, updateMintedBagIds])

  const title = useMemo(() => {
    switch (type) {
      case "og":
        return "OG Loot Mint"
      case "whitelist":
        return "First Explorers Mint"
      case "public":
        return "Public Mint"
    }
  }, [type])

  const loadingOrNotConnected = useMemo(() => {
    return loading || !active || !account
  }, [loading, active, account])

  const Content = useMemo(() => {
    // If theres pending transaction and transaction hash, shows that instead
    if (transaction && transaction.state === "processing") {
      return (
        <PendingTxContainer>
          <ExplorerShowcaseContainer>
            <GifOrWebm gifSrc={ExplorersShowcaseGif} videoUrl={ExplorersShowcaseImage} />
          </ExplorerShowcaseContainer>
          <PendingTransactionText>Transaction Pending...</PendingTransactionText>
          <ViewOnEtherscan
            href={getEtherscanUrl("tx", transaction.hash ?? "")}
            target="_blank"
            rel="noreferrer noopener"
          >
            View on Etherscan
          </ViewOnEtherscan>
        </PendingTxContainer>
      )
    } else if (transaction && transaction.state === "completed") {
      const mintedIds = (transaction.receipt?.logs ?? []).flat().map((log) => {
        return processLog(log, "Transfer").tokenId
      })

      return (
        <PendingTxContainer>
          <PendingTransactionText>Transaction Completed!</PendingTransactionText>
          <MintMoreButton onClick={onMintMore}>MINT MORE?</MintMoreButton>
          <MintedExplorersContainer onlyOne={mintedIds.length === 1}>
            {mintedIds.map((id) => {
              return (
                <ExplorerImageContainer
                  key={String(id)}
                  href={`${openseaAssetBaseUrl}/${id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ExplorerImage
                    src={
                      REACT_APP_EXPLORER_IMAGE_BASE_URL
                        ? `${REACT_APP_EXPLORER_IMAGE_BASE_URL}/${id}.png`
                        : ExplorerPlaceholderImage
                    }
                  />
                  <ExplorerNameText>Explorer #{id}</ExplorerNameText>
                </ExplorerImageContainer>
              )
            })}
          </MintedExplorersContainer>
        </PendingTxContainer>
      )
    }

    // SOLD OUT CONTENT
    if (isSoldOut) {
      return (
        <SoldOutContainer>
          <SoldOutText>The summonning has come to an end...</SoldOutText>
          <SoldOutText>Checkout Opensea for more.</SoldOutText>
          <SecondaryMarketContainer>
            <IconButton href={openseaCollectionUrl} target="_blank" rel="noreferrer noopener">
              <Opensea width={OPENSEA_ICON_SIZE} height={OPENSEA_ICON_SIZE} />
            </IconButton>
          </SecondaryMarketContainer>
        </SoldOutContainer>
      )
    }

    switch (type) {
      case "og":
        return (
          <OGMint
            bags={bags}
            mintedBagIds={mintedBagIds}
            lootLoading={lootLoading}
            generalMintingInfo={generalMintingInfo}
            walletActive={Boolean(active && account)}
            mintFunction={ogMint}
          />
        )
      case "whitelist":
        return (
          <PublicWhitelistMint
            generalMintingInfo={generalMintingInfo}
            walletActive={Boolean(active && account)}
            whitelist={{ isWhitelisted: Boolean(proof) }}
            mintFunction={whitelistMint}
          />
        )
      case "public":
        return (
          <PublicWhitelistMint
            generalMintingInfo={generalMintingInfo}
            walletActive={Boolean(active && account)}
            mintFunction={publicMint}
          />
        )
      default:
        return null
    }
  }, [
    type,
    bags,
    mintedBagIds,
    account,
    active,
    generalMintingInfo,
    lootLoading,
    ogMint,
    transaction,
    onMintMore,
    whitelistMint,
    publicMint,
    proof,
    isSoldOut,
  ])

  const MintedAmount = useMemo(() => {
    return (
      <MintedAmountContainer>
        <MintedAmountText>
          {mintedAmount}/{generalMintingInfo.totalAmount}
        </MintedAmountText>
        <MintPriceText>
          MINT PRICE: <ETHIcon width={ETH_ICON_SIZE} height={ETH_ICON_SIZE} /> {generalMintingInfo.displayPricePerMint}
        </MintPriceText>
      </MintedAmountContainer>
    )
  }, [mintedAmount, generalMintingInfo])

  return (
    <OuterContainer>
      <StickyTopContainer ref={topContainerRef}>
        <TitleBar title={title} containerStyle={{ paddingBottom: "8px" }} />
        {MintedAmount}
        {/* OG + WHITELIST HAVE A MAX 5 PER WALLET LIMIT */}
        {(type === "og" || type === "whitelist") && (
          <WalletLimitMessage>
            You are only allowed to mint a maximum of <b>5 explorers per wallet</b>
            {type === "og" && (
              <DisclaimerMessage>
                Before minting, please make sure you&apos;ve read our&nbsp;
                <a href="#" onClick={() => navigateTo("faq")}>
                  FAQ and Terms of Conditions
                </a>
              </DisclaimerMessage>
            )}
          </WalletLimitMessage>
        )}
        {/* PUBLIC WILL HAVE 5 PER TXN, NO LIMIT */}
        {type === "public" && (
          <WalletLimitMessage>
            You are only allowed to mint a maximum of <b>5 explorers per transaction</b>
          </WalletLimitMessage>
        )}
      </StickyTopContainer>
      <ContentContainer marginTop={`${topContainerHeight + 16}px`}>
        {loadingOrNotConnected ? (
          <LoadingContainer>
            {loading ? (
              <Loader containerStyle={{ width: "80%" }} />
            ) : (
              <ConnectWalletText>Please connect your wallet</ConnectWalletText>
            )}
          </LoadingContainer>
        ) : (
          Content
        )}
      </ContentContainer>
      <BasicModal show={Boolean(modalError)} onClose={() => setModalError("")} height={300} closeButton>
        <ErrorTitle>Something happened when minting!</ErrorTitle>
        <ScrollableContent>
          <ErrorBody>
            The Council has encountered this error:
            <br />
            <br />
            <i>&quot;{modalError}&quot;</i>
            <br />
            <br />
            <b>
              Please make sure your wallet is connected, and you are not minting more than 5 explorers&nbsp;
              {(type === "og" || type === "whitelist") && "per wallet"}.
            </b>
          </ErrorBody>
        </ScrollableContent>
      </BasicModal>
    </OuterContainer>
  )
}

export default MintContent
