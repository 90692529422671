import { RefObject, useCallback, useMemo } from "react"
import styled, { css } from "styled-components"
import CampsImage from "../../../../assets/webp/map/camps.webp"
import BaseImage from "../../../../assets/webp/map/land/base.webp"
import LagoonaImage from "../../../../assets/webp/map/land/base_lg.webp"
import NoctusImage from "../../../../assets/webp/map/land/base_nc.webp"
import SmokeRightImage from "../../../../assets/webp/map/land/smoke-1.webp"
import SmokeLeftImage from "../../../../assets/webp/map/land/smoke-2.webp"
import TerrainImage from "../../../../assets/webp/map/terrain.webp"
import TreesImage from "../../../../assets/webp/map/trees.webp"
import { LandNamesType } from "../../../../models/Map"
import { colors } from "../../../../styles"
import Landmarks from "./Landmarks"
import { labelClickSound, labelHoverSound } from "./sound"

const MAP_WIDTH = 1420.5
const MAP_HEIGHT = 917.5

const Container = styled.div<{ transformOffset: { x: number; y: number } }>`
  position: relative;
  width: ${MAP_WIDTH}px;
  height: ${MAP_HEIGHT}px;
  overflow: visible;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  ${({ transformOffset }) => {
    return css`
      transform: translate(${transformOffset.x}px, ${transformOffset.y}px);
    `
  }}
`

const SharedBase = styled.img`
  position: absolute;
  overflow: visible;
  object-fit: contain;
  width: 100%;
  height: 100%;

  transition: opacity 0.4s ease;
`

const OpacityContainer = styled.div<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.4s ease;
`

const Terrain = styled(SharedBase).attrs({
  src: TerrainImage,
})<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
`

const Camps = styled(SharedBase).attrs({
  src: CampsImage,
})<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
`

const Trees = styled(SharedBase).attrs({
  src: TreesImage,
})<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
`

const SmokeShared = styled.img`
  position: absolute;
  width: 300px;
`
const SmokeLeft = styled(SmokeShared).attrs({
  src: SmokeLeftImage,
})`
  left: 790px;
  top: 135px;
`
const SmokeRight = styled(SmokeShared).attrs({
  src: SmokeRightImage,
})`
  left: 1070px;
  top: 265px;
`

const Unexplored = styled(SharedBase).attrs({
  src: "https://firebasestorage.googleapis.com/v0/b/lootexplorers-feff0.appspot.com/o/landing-site%2Fmap%2Funexplored-faded.webp?alt=media",
})`
  transform: scale(1.42) translate(0%, -3.3%);
`

const Base = styled(SharedBase).attrs({
  src: BaseImage,
})``

const Lagoona = styled(SharedBase).attrs({
  src: LagoonaImage,
})``

const Noctus = styled(SharedBase).attrs({
  src: NoctusImage,
})``

// LABELS
const LabelsContainer = styled.div<{ opacity: number }>`
  position: relative;
  opacity: ${({ opacity }) => opacity};
`
const BaseLabel = styled.h3`
  pointer-events: auto;
  color: ${colors.lightGreen};
  position: absolute;
  font-size: 32px;
  font-weight: bold;
  width: 200px;
  text-align: center;

  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  &:hover {
    color: ${colors.darkGreen};
    cursor: pointer;
  }
`
const FrostennLabel = styled(BaseLabel).attrs({
  children: "FROSTENN",
})`
  left: 100px;
  top: 130px;
`
const MagmaLabel = styled(BaseLabel).attrs({
  children: "MAGMA",
})`
  left: 470px;
  top: 180px;
`
const PollenLabel = styled(BaseLabel).attrs({
  children: "POLLEN",
})`
  left: 340px;
  top: 470px;
`
const CannonCoastLabel = styled(BaseLabel).attrs({
  children: "CANNON COAST",
})`
  left: 680px;
  top: 500px;
`
const VioletEversideLabel = styled(BaseLabel).attrs({
  children: "VIOLET EVERSIDE",
})`
  left: 180px;
  top: 640px;
`
const MerladeLabel = styled(BaseLabel).attrs({
  children: "MERLADE",
})`
  left: 470px;
  top: 640px;
`
const ShatteredBoneLabel = styled(BaseLabel).attrs({
  children: "SHATTERED BONE",
})`
  left: 570px;
  top: 790px;
`
const NoctusLabel = styled(BaseLabel).attrs({
  children: "NOCTUS",
})`
  left: 1030px;
  top: 130px;
`
const LagoonaLabel = styled(BaseLabel).attrs({
  children: "LAGOONA",
})`
  left: 950px;
  top: 620px;
`
const UnderluneLabel = styled(BaseLabel).attrs({
  children: "UNDERLUNE",
})`
  left: 1140px;
  top: 830px;
`

const Renders = styled(SharedBase).attrs({
  src: "https://firebasestorage.googleapis.com/v0/b/lootexplorers-feff0.appspot.com/o/landing-site%2Fmap%2Ffull-render.webp?alt=media",
})<{ show?: boolean; opacity: number }>`
  transition: opacity 0.4s ease;
  opacity: ${({ show, opacity }) => (show ? opacity : 0)};
`

interface LandsProps {
  // Number from 0-1
  scaleProgress: number
  showRendersOnly?: boolean
  onSelectLand: (landName?: LandNamesType) => void
  onSelectLandmark: (id?: string) => void
  parentRef?: RefObject<HTMLDivElement>
}

// Does not render the child when there are no opacity
const RemoveElemWhenNoOpacity = ({ opacity, children }: { opacity: number; children: JSX.Element }) => {
  return opacity <= 0 ? null : children
}

const Lands = ({ scaleProgress, showRendersOnly, onSelectLand, onSelectLandmark, parentRef }: LandsProps) => {
  const parentWidth = parentRef?.current?.offsetWidth ?? 0
  const parentHeight = parentRef?.current?.offsetHeight ?? 0
  const xOffset = (parentWidth - MAP_WIDTH) / 2
  const yOffset = (parentHeight - MAP_HEIGHT) / 2
  const transformOffset = {
    x: xOffset,
    y: yOffset,
  }

  const onMapLabelHover = useCallback(() => {
    try {
      labelHoverSound.currentTime = 0
      labelHoverSound.volume = 0.5
      labelHoverSound.play()
    } catch (error) {
      // Do nothing
    }
  }, [])

  const onMapLabelClick = useCallback(
    (landName: LandNamesType) => {
      try {
        labelClickSound.currentTime = 0
        labelClickSound.volume = 0.5
        labelClickSound.play()
      } catch (error) {
        // Do nothing
      }
      onSelectLand(landName)
    },
    [onSelectLand],
  )

  const opacities = useMemo(() => {
    const terrain = showRendersOnly ? 0 : scaleProgress * 2
    const landmarks = showRendersOnly ? 0 : scaleProgress * 3
    const trees = showRendersOnly ? 0 : scaleProgress
    const camps = showRendersOnly ? 0 : scaleProgress
    const renders = showRendersOnly ? scaleProgress * 4 : 0
    const labels = 1 - scaleProgress * 4
    return {
      terrain,
      landmarks,
      trees,
      camps,
      renders,
      labels,
    }
  }, [showRendersOnly, scaleProgress])

  return (
    <Container transformOffset={transformOffset}>
      {/* LAND */}
      <Unexplored />
      <Base />
      <Lagoona />
      <Noctus />

      {/* TERRAIN */}
      <RemoveElemWhenNoOpacity opacity={opacities.terrain}>
        <Terrain opacity={opacities.terrain} />
      </RemoveElemWhenNoOpacity>

      {/* LANDMARKS */}
      <RemoveElemWhenNoOpacity opacity={opacities.landmarks}>
        <OpacityContainer opacity={opacities.landmarks}>
          <Landmarks onLandmarkIdClicked={onSelectLandmark} />
        </OpacityContainer>
      </RemoveElemWhenNoOpacity>

      {/* TREES */}
      <RemoveElemWhenNoOpacity opacity={opacities.trees}>
        <Trees opacity={opacities.trees} />
      </RemoveElemWhenNoOpacity>

      {/* CAMPS */}
      <RemoveElemWhenNoOpacity opacity={opacities.camps}>
        <Camps opacity={opacities.camps} />
      </RemoveElemWhenNoOpacity>

      {/* RENDERS */}
      <Renders show={showRendersOnly} opacity={opacities.renders} />

      <SmokeLeft />
      <SmokeRight />

      {/* LABELS */}
      <RemoveElemWhenNoOpacity opacity={opacities.labels}>
        <LabelsContainer opacity={opacities.labels}>
          <FrostennLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Frostenn")} />
          <MagmaLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Magma")} />
          <PollenLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Pollen")} />
          <CannonCoastLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Cannon Coast")} />
          <VioletEversideLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Violet Everside")} />
          <MerladeLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Merlade")} />
          <ShatteredBoneLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Shattered Bone")} />
          <NoctusLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Noctus")} />
          <LagoonaLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Lagoona")} />
          <UnderluneLabel onMouseEnter={onMapLabelHover} onClick={() => onMapLabelClick("Underlune")} />
        </LabelsContainer>
      </RemoveElemWhenNoOpacity>
    </Container>
  )
}

export default Lands
