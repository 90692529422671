// theme

import Landscape from "../assets/webp/normal-theme/BG.webp"
import Clouds from "../assets/webp/normal-theme/cloudrolloop.webp"
import ArcaneBase from "../assets/webp/normal-theme/deco/arcane_base.webp"
import ArcaneCup from "../assets/webp/normal-theme/deco/arcane_cup.webp"
import ArcaneJewel from "../assets/webp/normal-theme/deco/arcane_jewel.webp"
import Banner from "../assets/webp/normal-theme/deco/banner.webp"
import Book from "../assets/webp/normal-theme/deco/book.webp"
import Lantern from "../assets/webp/normal-theme/deco/lantern.webp"
import LanternLight from "../assets/webp/normal-theme/deco/lantern-light.webp"
import Letter from "../assets/webp/normal-theme/deco/letter.webp"
import MapBottle from "../assets/webp/normal-theme/deco/map_bottle.webp"
import Stump from "../assets/webp/normal-theme/deco/stump.webp"
import Telescope from "../assets/webp/normal-theme/deco/telescope.webp"
import Tree from "../assets/webp/normal-theme/deco/tree.webp"
import Turnip from "../assets/webp/normal-theme/deco/turnip.webp"
import FG from "../assets/webp/normal-theme/FG.webp"
import MidBG from "../assets/webp/normal-theme/MG.webp"
import SoupView from "../assets/webp/normal-theme/soup-view.webp"
import TelescopeView from "../assets/webp/normal-theme/telescope-view.webp"
import TelescopeViewPuzzle from "../assets/webp/normal-theme/telescope-view-puzzle.webp"
import LandscapeWinter from "../assets/webp/winter-theme/BG.webp"
import CloudsWinter from "../assets/webp/winter-theme/cloudrolloop.webp"
import ArcaneJewelWinter from "../assets/webp/winter-theme/deco/arcane_jewel.webp"
import BannerWinter from "../assets/webp/winter-theme/deco/banner.webp"
import LanternWinter from "../assets/webp/winter-theme/deco/lantern.webp"
import LanternLightWinter from "../assets/webp/winter-theme/deco/lantern-light.webp"
import StumpWinter from "../assets/webp/winter-theme/deco/stump.webp"
import TreeWinter from "../assets/webp/winter-theme/deco/tree.webp"
import TurnipWinter from "../assets/webp/winter-theme/deco/turnip.webp"
import FGWinter from "../assets/webp/winter-theme/FG.webp"
import MidBGWinter from "../assets/webp/winter-theme/MG.webp"
import TelescopeViewWinter from "../assets/webp/winter-theme/telescope-view.webp"
import TelescopeViewPuzzleWinter from "../assets/webp/winter-theme/telescope-view-puzzle.webp"

const month = new Date().getMonth()
// Shows winter from month count 11 (december) to month count 2 (march)
export const theme = month > 10 || month <= 2 ? "winter" : "normal"

export const LetterStillImage = Letter
export const ForegroundImage = theme === "winter" ? FGWinter : FG
export const LandscapeImage = theme === "winter" ? LandscapeWinter : Landscape
export const CloudsImage = theme === "winter" ? CloudsWinter : Clouds
export const BannerImage = theme === "winter" ? BannerWinter : Banner
export const TreeImage = theme === "winter" ? TreeWinter : Tree
export const TurnipImage = theme === "winter" ? TurnipWinter : Turnip
export const MidBGImage = theme === "winter" ? MidBGWinter : MidBG
export const ArcaneBaseImage = ArcaneBase
export const ArcaneCupImage = ArcaneCup
export const ArcaneJewelImage = theme === "winter" ? ArcaneJewelWinter : ArcaneJewel
export const BookImage = Book
export const LanternImage = theme === "winter" ? LanternWinter : Lantern
export const LanternLightImage = theme === "winter" ? LanternLightWinter : LanternLight
export const MapBottleImage = MapBottle
export const StumpImage = theme === "winter" ? StumpWinter : Stump
export const TelescopeImage = Telescope
export const TelescopeViewImage = theme === "winter" ? TelescopeViewWinter : TelescopeView
export const TelescopeViewPuzzleImage = theme === "winter" ? TelescopeViewPuzzleWinter : TelescopeViewPuzzle
export const SoupViewImage = SoupView
