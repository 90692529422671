export const shortenAddress = (address?: string | null) => {
  if (!address) {
    return ""
  }
  return `${address.substr(0, 6)}...${address.substr(address.length - 5, address.length - 1)}`
}

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(" ")
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}
