import { useEffect } from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import Modal from "../components/common/Modal"
import { fullscreenContentIds } from "../components/FullscreenContent"
import { contentIds } from "../components/ModalContentPage"
import { useNavigation } from "../hooks/useNavigation"
import { useGlobalState } from "../store/state"

const Container = styled.div`
  height: 100%;
`

interface HomeProps {
  headerHeight: number
}

const Home = (props: HomeProps) => {
  const { headerHeight } = props

  const { page } = useParams<{ page: string }>()
  const [, setModalContentId] = useGlobalState("currentModalContentId")
  const { navigateTo, navigateToFullscreen } = useNavigation()
  const [history] = useGlobalState("browserHistory")

  useEffect(() => {
    const existingFullscreenPage = fullscreenContentIds.find((id) => id.toLowerCase() === page?.toLowerCase())
    const existingPage = contentIds.find((id) => id.toLowerCase() === page?.toLowerCase())

    if (existingFullscreenPage) {
      navigateToFullscreen(existingFullscreenPage)
    } else if (existingPage) {
      navigateTo(existingPage)
    } else {
      navigateTo(undefined)
      navigateToFullscreen(undefined)
    }
  }, [navigateTo, page, setModalContentId, navigateToFullscreen, history])

  return (
    <Container>
      <Modal top={headerHeight} />
    </Container>
  )
}

export default Home
