import { doc, getDoc } from "firebase/firestore"
import { getBlob, ref } from "firebase/storage"
import { motion } from "framer-motion"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import ArrowLeftImage from "../assets/icons/arrow-left.webp"
import ArrowRightImage from "../assets/icons/arrow-right.webp"
import PosterPlaceholder from "../assets/mailbox/poster-placeholder.png"
import useScreenSize from "../hooks/useScreenSize"
import { firestore, storage } from "../utils/firebase"
import Loader from "./common/Loader"

const POSTER_ASPECT_RATIO = 2293 / 3339
const POSTER_OFFSET_PERCENTAGE = 0.02

const StyledModal = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.6);

  .modal-dialog {
    width: 95vw;
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    overflow: visible;
    background-color: transparent;
    border: none;
  }
`

const PosterDiv = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

const PosterImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

interface MailboxPosterModalProps {
  show: boolean
  onClose: () => void
}

const MailboxPosterModal: React.FC<MailboxPosterModalProps> = ({ show, onClose }) => {
  const [page, setPage] = useState(0)
  const { height, width } = useScreenSize()
  const [posterImages, setPosterImages] = useState([PosterPlaceholder])
  const [loadingStatuses, setLoadingStatuses] = useState([true])

  useEffect(() => {
    if (!show) {
      setPage(0)
    }
  }, [show])

  const posterImgSize = useMemo(() => {
    const maxWidth = width * 0.4 // Maximum width of poster is 40% of screen width
    const maxHeight = height * 0.9 // Maximum height of poster is 90% of screen height
    const maxAspectRatioWidth = maxHeight * POSTER_ASPECT_RATIO
    const posterWidth = maxAspectRatioWidth < maxWidth ? maxAspectRatioWidth : maxWidth
    const posterHeight = posterWidth / (2293 / 3339)

    return { width: parseFloat(posterWidth.toFixed(2)), height: parseFloat(posterHeight.toFixed(2)) }
  }, [height, width])

  const preloadPosterImageData = useCallback(async () => {
    const adminConfigSnapshot = await getDoc(doc(firestore, "admin", "config"))
    const posterConfig = adminConfigSnapshot.data()?.posterConfig || []

    setPosterImages(new Array(posterConfig.length).fill(PosterPlaceholder))
    setLoadingStatuses(new Array(posterConfig.length).fill(true))

    for (let i = 0; i < posterConfig.length; i++) {
      const imageRef = ref(storage, posterConfig[i].storageRef)

      // Wrap the request in a Promise
      const blob = await getBlob(imageRef)

      const url = URL.createObjectURL(blob)

      setPosterImages((prevPosterImages) => prevPosterImages.map((image, index) => (index === i ? url : image)))
      setLoadingStatuses((prevLoadingStatuses) =>
        prevLoadingStatuses.map((loading, index) => (index === i ? false : loading)),
      )
    }
  }, [])

  useEffect(() => {
    preloadPosterImageData()
  }, [preloadPosterImageData])

  const calculatePosterLeft = useCallback(
    (posterIndex: number) => {
      if (page === 0) {
        return `calc(50% + ${
          posterImgSize.width / -2 + posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE
        }px)`
      }

      const posterMargin = (posterImgSize.width * 0.01) / 2

      if (page > posterIndex) {
        const leftOffset =
          -posterImgSize.width - posterMargin + posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE
        return `calc(50% + ${leftOffset}px)`
      }

      const leftOffset = posterMargin + posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE
      return `calc(50% + ${leftOffset}px)`
    },
    [page, posterImgSize],
  )

  const leftArrow = useMemo(() => {
    if (page <= 0) {
      return <></>
    }

    return (
      <motion.img
        role="button"
        key="left"
        src={ArrowLeftImage}
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: 1,
          position: "absolute",
          width: posterImgSize.width * 0.15,
          right: `calc(50% + ${posterImgSize.width - posterImgSize.width * 0.04}px)`,
        }}
        exit={{ opacity: 0, width: 0 }}
        transition={{
          type: "keyframes",
        }}
        onClick={() => setPage((prev) => prev - 1)}
      />
    )
  }, [page, posterImgSize.width])

  const rightArrow = useMemo(() => {
    if (page >= posterImages.length - 1) {
      return <></>
    }

    return (
      <motion.img
        role="button"
        key="right"
        src={ArrowRightImage}
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: 1,
          position: "absolute",
          width: posterImgSize.width * 0.15,
          left: `calc(50% + ${
            (posterImages.length - 3) * posterImgSize.width * POSTER_OFFSET_PERCENTAGE +
            (page === 0 ? posterImgSize.width / 2 : posterImgSize.width)
          }px)`,
        }}
        exit={{ opacity: 0, width: 0 }}
        transition={{
          type: "keyframes",
        }}
        onClick={() => setPage((prev) => prev + 1)}
      />
    )
  }, [page, posterImages, posterImgSize.width])

  return (
    <StyledModal show={show} centered onHide={onClose}>
      <Modal.Body>
        <div className="d-flex position-relative align-items-center justify-content-center">
          {posterImages.map((posterImage, index) => (
            <PosterDiv
              key={index}
              initial={{ height: 0, width: 0 }}
              animate={{
                opacity: 1,
                position: "absolute",
                left: calculatePosterLeft(index),
                zIndex: -index,
                width: posterImgSize.width,
                height: posterImgSize.height,
              }}
              transition={{
                type: "keyframes",
              }}
            >
              {/* <a
                key={index}
                href={`${BackendBaseURL}/frontend/posterURI/${index + 1}`}
                target="_blank"
                rel="noreferrer noopener"
                style={{ "--index": index.toString() } as React.CSSProperties}
              > */}
              <PosterImg src={posterImage} />
              {loadingStatuses[index] ? (
                <div className="d-flex position-absolute h-100 w-100 left-0 top-0 align-items-center justify-content-center">
                  <Loader containerStyle={{ width: "50%" }} />
                </div>
              ) : (
                <></>
              )}
              {/* </a> */}
            </PosterDiv>
          ))}
          {leftArrow}
          {rightArrow}
        </div>
      </Modal.Body>
    </StyledModal>
  )
}

export default MailboxPosterModal
