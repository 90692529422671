export const colors = {
  pale: "#FFF7E1",
  background: "#FFF0D1",
  lightGreen: "#E8FFDA",
  green: "#A6C698",
  darkGreen: "#899E7D",
  lightBrown: "#6e664c",
  brown: "#6A585A",
  brown2: "#8e7f5e",
  purple: "#605E72",
  red: "#CC2936",
  gray: "#746F72",
  lightGray2: "#e7e8dd",
  lightGray: "#DBD3D8",
  blue: "#072AC8",
  lightBlue: "#84D2F6",
  puzzleUnsolved: "#C8D7CC",
  puzzleSolved: "#F8F0D7",
  soupBg: "#FFF6D3",
}

export const borderColors = {
  Apocalypse: "#899976",
  Beast: "#C6825D",
  Colossal: "#948877",
  Despair: "#847A80",
  Luminescence: "#D3AF5F",
  Psychic: "#CE769F",
  Rage: "#D27463",
  Skeleton: "#996B8F",
  Storm: "#657EA1",
  Default: "#424057",
}
