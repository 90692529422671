import React from "react"
import sizes from "../utils/sizes"
import useScreenSize from "./useScreenSize"

const useIsMobile = () => {
  const { width } = useScreenSize()
  return {
    isMobile: width <= sizes.md,
    screenWidth: width,
  }
}

export default useIsMobile
