import { useCallback, useEffect, useMemo } from "react"
// IMAGES
import Apocalypse from "../assets/puzzle/apocalypse.png"
import Beast from "../assets/puzzle/beast.png"
import Colossal from "../assets/puzzle/colossal.png"
import Despair from "../assets/puzzle/despair.png"
import Luminescence from "../assets/puzzle/luminescence.png"
import Psychic from "../assets/puzzle/psychic.png"
import Rage from "../assets/puzzle/rage.png"
import Skeleton from "../assets/puzzle/skeleton.png"
import Storm from "../assets/puzzle/storm.png"
import { isDevelopment, isProduction } from "../config/env"
import {
  allPuzzleBackgrounds,
  allPuzzleNames,
  CompletedPuzzle,
  phraseOfTheDay,
  PuzzleName,
  SinglePuzzlePiece,
} from "../models/Puzzle"
import { useGlobalState } from "../store/state"
import { getDecryptedPuzzle, storeAndEncryptPuzzle } from "../utils/storage"

/**
 * triggerConfigUpdates starts a series of firebase snapshot listeners to update the config.
 * Only do this on screens that requires updated config and puzzle solved count
 */
export const usePuzzle = () => {
  const [puzzle, setPuzzle] = useGlobalState("puzzle")
  const [collectDiscord, setCollectDiscord] = useGlobalState("collectDiscord")
  const [puzzleSolved, setPuzzleSolved] = useGlobalState("puzzleSolved")
  const [discordName, setDiscordName] = useGlobalState("discordName")

  // Puzzle is enabled by default now that WL quiz is over
  const puzzleDisabled = useMemo(() => {
    return false
  }, [])

  // UTILITY FUNCTIONS
  const randomPuzzleName = useCallback(() => {
    const randomName: PuzzleName = allPuzzleNames[Math.floor(Math.random() * allPuzzleNames.length)]
    return randomName
  }, [])

  const getPhraseOfTheDay = useCallback(() => {
    // Loop backwards, returns the latest phrase
    const entries = Object.entries(phraseOfTheDay).reverse()
    for (let i = 0; i < entries.length; i++) {
      const [time, phrase] = entries[i]
      if (Date.now() >= Number(time)) {
        return phrase
      }
    }

    // If not, returns the first phrase
    return phraseOfTheDay[1639324800000]
  }, [])

  const isSamePuzzle = useCallback((puzzleA: SinglePuzzlePiece, puzzleB: SinglePuzzlePiece) => {
    return puzzleA.name === puzzleB.name && puzzleA.background === puzzleB.background
  }, [])

  const getImageAssetForPuzzleName = useCallback((name: PuzzleName) => {
    switch (name) {
      case "beast":
        return Beast
      case "colossal":
        return Colossal
      case "luminescence":
        return Luminescence
      case "skeleton":
        return Skeleton
      case "apocalypse":
        return Apocalypse
      case "rage":
        return Rage
      case "despair":
        return Despair
      case "psychic":
        return Psychic
      case "storm":
        return Storm
    }
  }, [])

  // Trigger update to save the correct puzzle
  const completePuzzle = useCallback(
    async (discordName: string) => {
      if (puzzle) {
        setPuzzleSolved(true)
        storeAndEncryptPuzzle(puzzle, true, discordName)
        setDiscordName(discordName)
      } else {
        alert("Submission limit reached.")
      }
    },
    [setPuzzleSolved, puzzle, setDiscordName],
  )

  // If puzzle doesnt exist, check localstorage, or create new puzzle
  useEffect(() => {
    if (!puzzle) {
      const storedPuzzle = getDecryptedPuzzle()
      if (storedPuzzle) {
        const { puzzle, solved, discordName } = storedPuzzle
        setPuzzle(puzzle)
        setPuzzleSolved(solved)
        setDiscordName(discordName)
      } else {
        const newPuzzle: CompletedPuzzle = {
          0: {
            name: randomPuzzleName(),
            background: allPuzzleBackgrounds[2],
          },
          1: {
            name: randomPuzzleName(),
            background: allPuzzleBackgrounds[2],
          },
          2: {
            name: randomPuzzleName(),
            background: allPuzzleBackgrounds[1],
          },
          3: {
            name: randomPuzzleName(),
            background: allPuzzleBackgrounds[0],
          },
        }
        setPuzzle(newPuzzle)
        setPuzzleSolved(false)
        storeAndEncryptPuzzle(newPuzzle, false)
      }
    }
    isDevelopment() && console.log("PUZZLE:", puzzle)
  }, [puzzle, randomPuzzleName, setPuzzle, setPuzzleSolved, setDiscordName])

  return {
    // UTILITY FUNCTIONS
    isSamePuzzle,
    getImageAssetForPuzzleName,

    correctPuzzle: puzzle,
    puzzleSolved,
    completePuzzle,
    getPhraseOfTheDay,
    collectDiscord,
    setCollectDiscord,
    discordName,
    puzzleDisabled,
  }
}
