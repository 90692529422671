import { ReactComponent as Discord } from "../assets/icons/discord.svg"
import { ReactComponent as DiscordMobile } from "../assets/icons/mobile-social/discord.svg"
import { ReactComponent as OpenseaMobile } from "../assets/icons/mobile-social/opensea.svg"
import { ReactComponent as TwitterMobile } from "../assets/icons/mobile-social/twitter.svg"
import { ReactComponent as Opensea } from "../assets/icons/opensea.svg"
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg"
import { LandNamesType } from "../models/Map"
import { openseaCollectionUrl } from "../utils/urls"

export const socials = ["twitter", "opensea", "discord"] as const
export type SocialType = typeof socials[number]

export interface SocialInfo {
  url: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  alternativeIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  // TEMPORARILY HIDES FROM UI
  hide?: boolean
}
export type SocialsToSocialInfo = {
  [key in SocialType]: SocialInfo
}

export const socialInfos: SocialsToSocialInfo = {
  twitter: {
    url: "https://twitter.com/lootexplorers",
    icon: Twitter,
    alternativeIcon: TwitterMobile,
  },
  opensea: {
    url: openseaCollectionUrl,
    icon: Opensea,
    alternativeIcon: OpenseaMobile,
  },
  discord: {
    url: "https://discord.com/invite/qMFegq7GWr",
    icon: Discord,
    alternativeIcon: DiscordMobile,
  },
}

interface FaqInfo {
  question: string
  answers: any[]
}
export const faqInfos: FaqInfo[] = [
  {
    question: "⚔️  What are Loot: Explorers?",
    answers: [
      <span key="What are Loot: Explorers?">
        Loot: Explorers are 8000 unique Explorer characters visualising the gear found in the first 8000 genesis bags of
        Loot. Every piece of equipment on an Explorer corresponds to the 8 items found in their respective Loot bags. On
        top of that, each Explorer will come with a different race, allegiance, & biome, all tied to a unique Explorer
        name.
        <br />
        More on how they are generated&nbsp;
        <a href="https://link.medium.com/uk8ELb1iivb" target="_blank" rel="noreferrer noopener">
          here
        </a>
        .
      </span>,
    ],
  },
  {
    question: "⬛ What is Loot?",
    answers: [
      <span key="What is loot?">
        Loot was created on Aug 27th 2021 by dom, 8000 randomized text-based adventurer gear stored on chain, open to
        anyone&apos;s interpretation. Each loot bag contains 8 items: a piece for an adventurer&apos;s chest, foot,
        hand, head, neck, ring, waist, and weapon.
        <br />
        Dive into Loot:&nbsp;
        <a href="https://docs.loot.foundation/" target="_blank" rel="noreferrer noopener">
          https://docs.loot.foundation/
        </a>
        .
      </span>,
    ],
  },
  {
    question: "🏹 How do I get a Loot: Explorer?",
    answers: [
      <span key="how do i get a loot: explorer?">
        Loot: Explorers was initially launched back in December 2021 and has been fully minted out. Right now, you can
        still get an Explorer through a secondary marketplace like{" "}
        <a href="https://docs.loot.foundation/" target="_blank" rel="noreferrer noopener">
          Opensea
        </a>
        &nbsp;and&nbsp;
        <a href="https://docs.loot.foundation/" target="_blank" rel="noreferrer noopener">
          Blur.io
        </a>
      </span>,
    ],
  },
  {
    question: "🎮 Are we making a game?",
    answers: [
      // "At it's core, the Loot Explorers project is the amalgamation of fun art, the community, and RPG-styled storytelling with game inspired elements. Instead of focusing on creating just a game or a PFP art-type project, we're looking towards creating a new world within the Loot timeline. A world of Loot Explorers visualized through your very own loot bags!",
      // "If you've enjoyed the experience we've designed for you so far, trust us and follow along on what we'll build for you next.",
      <span key="are we making a game?">
        At it&apos;s core, the Loot Explorers project is the amalgamation of fun art, the community, and RPG-styled
        storytelling with game inspired elements. Instead of focusing on creating just a game or a PFP art-type project,
        we&apos;re looking towards creating a new world within the Loot timeline.&nbsp;
        <a href="/map">A world for Loot Explorers</a>.
      </span>,
    ],
  },
  {
    question: "What can I do with a Loot: Explorer?",
    answers: [
      "You will be able to participate in helping us further develop the lore of the Explorer world. Every character has a origin story, and we hope you'll create one just for your Explorer.",
      "You'll also be able to participate in side quests, which are text-based mini games held every so often in our Discord. Over the past year, we've ran 7 different side quests where people stood a chance to win various Loot: Explorer NFTs as prizes.",
      "On top of all of this, owning a Loot: Explorer effectively makes you part of the Lootverse, an infinitely-expansive, yet still loosely-cohesive universe of games, stories, NFTs, art, multimedia, communities and more. Due to this, we've had collaborations with other Loot-based projects such as BannersNFT & the Genesis Project, which are currently still on-going.",
    ],
  },
  {
    question: "Future plans?",
    answers: [
      "Currently, our next most anticipated release will be the Explorer hideouts, a cool little clubhouse perfect for your Explorers to lounge in after long days of adventuring. Keep up with us in our Discord for updates.",
    ],
  },
  {
    question: "Who is the team?",
    answers: [
      "This project is being run by a small team of 3! JH is our full stack developer, Lunaa is our main artist/UI/Music, and Kai is our project/community manager",
    ],
  },
  {
    question: "🗺️  How many Explorers will there be available to mint?",
    answers: [
      "The total supply is 8003, but there will be 8000 Explorers available to mint. The additional 3 are reserved for the team (Kai, JH, Lunaa).",
    ],
  },
]

type LandNameToDescription = {
  [key in LandNamesType]: string[]
}
export const landDescription: LandNameToDescription = {
  Noctus: [
    "To the east of the Centos sea lies Noctus, an ancient empire built upon a crescent-shaped land known as the moon's shadow. From afar, tall dark spires poke through the shroud of mystical purple mist, with the occasional flashes of red lightning luminating the lustreless sky.",
    "It is known to be the place where the Noctii race came to be, with the general populace of the region being fellow Skeles, Dark Spirits, and Goblins. The outer-moon territories of Noctus are mostly left uncharted, lands braved by many but most return incomprehensible, lost souls scarred by the terror of the unknown.",
  ],
  Frostenn: [
    "Frostenn is the snowy region of the Explorer realm, with wide frozen flats spanning across the lands surrounded by the Lisuni mountain range. The Northern winds are known to be deadly to those unprepared, where most casualties suffered here are mostly due to the forces of nature.",
    "The low lying land is where the main encampments are set up, with well established routes heading towards the natural hot springs, a popular location for both the locals and travellers alike. Beyond the Lisuni mountains are unexplored wilderness, where the whispers of beasts and mysterious howls come alive. It is said that when the blue moon shines upon fields of the frozen, while the stars align with Lisuni's 3 tallest peaks, the peril of the North will be awoken.",
  ],
  Magma: [
    "The molten-heart of the isle. Almost everything in the region is powered by the blistering heat of its two active volcanoes. The heat from the lava shaped this kingdom into a form that almost resembles a meatball cluster. The interior of the castle has long bridge-like hallways that connect each area. The leaders of the Magma Isle take great pride in maintaining the natural formations of their land, with the only entrance to the partially-underground city being a well guarded gate made out of igneous rocks.",
    "Explorers raised and trained in the Isle have been known to be skilled in traditional combat, especially with melee weapons like maces and mauls. Many festivals and trials of strength are held daily in the arena. Always lively, never empty, the walls are made of a specific type of rock that reverberates the crowd's roars across the city. ",
  ],
  Pollen: [
    "Pollen is the land of the Verdant Crystal Hills, scattered with ancient ruins across the region. Remnants of the past can be found buried within the hillsides covered in moss and greenery. A crystallized bridge that is cold to the touch sits where the region meets it's frozen neighbour.",
    "Beside it is the old Emerald mine, where a sparkling green glow shines from the mouth of the cave. Traces of emerald fragments can still be found lodged throughout the ground, emitting tiny gleams of green when reflected by the sun rays. There is a good chance of snowfall during the winter season, a place suitable for Explorers with thicker hide like the Ursas.",
  ],
  "Cannon Coast": [
    "Located on the southern side of the Explorer peninsula is the Canon Coast, where clear beaches of fine sand make up most of the terrain, with a couple of palm trees scarcely scattered across the shoreline. Kruel burrows are dotted across the dunes, tiny critters which scurry away in their pyramid-shaped shells if you get too close.",
    "On the northern tip of the coast, the Canon Arms Citadel was built right by the water as a defensive stronghold overlooking the ocean, to safeguard from threats that appear from the depths. Rumor has it that Grunosk, a twin headed vortex serpent the size of 2 ships attacked the coastal market 20 years ago, leaving behind only destruction and ruin. This urban legend seems to ring true, as the coastal patrol still routinely survey these parts.",
  ],
  "Violet Everside": [
    "In the Violet Everside, there's an ever-present sense of calmness. A constant breeze fills the air, drifting with the sweet scent of the lilac forest, painting beautiful sceneries of purple treetops and pink hills as far as the eye can see.",
    "Within these forests live friendly creatures who'd go up to the curious visitor, welcoming and unafraid. The golden meadows & pastures of the Everside farms glistens in the sunset, with flower fields of violet & lavender stretching across the landscape. After a long & tiring adventure, the Everside is the place all Explorers long to be.",
  ],
  Merlade: [
    "Merlade is the central crossroads of the Explorer Realm, characterized by its wide grassy hillscape and bright blue sky. It is said that this was the origin place of the Explorer's spirit, an inspiring aura felt throughout every Explorer who's set foot in the region. As such, it is where most Explorers get their start, with plenty of schools and temples serving as Explorer training grounds in preparation of their first adventure.",
    "The Bonfire, the beacon of safety and communal space for all Explorers is also located within Merlade, serving as the primary gathering spot for all travellers. Earl the Eternal Flame is always seen nested under a large tree atop the sunset hill, providing guidance and advice to all Explorers who've come to meet with him.",
  ],
  "Shattered Bone": [
    'The sandy region of Shattered Bone took its name from a distinct bone-shaped island "cracked" in half. Although scattered across multiple islands, the land here is more densely populated compared to Canon Coast. More traces of life can be found, with makeshift camps & shipwrecks strewn across the beaches.',
    "The new sandy marketplace was built here, becoming one of the primary trading hubs for travellers from across the realm. Goods like the fiery spices of Magma to the Spirit charms of Noctus can all be found within the merchant stands. Just below that is the Loot Fishery, built on top of the ocean on wooden stilts, occasionally fishing out crates and chests filled with lost loot and treasures.",
  ],
  Lagoona: [
    "Lagoona is the Kingdom of the Deeptides. To begin your journey underwater, one must first travel to the harbour of Eastbone island where a boat will take you to the Descent. From above, a shimmering blue and yellow glow can be seen coming from the city, surrounded by thick bubble columns rising from the vapour geysers below.",
    "The city is situated within a large circle formation enclosed by underwater mountains, with ancient walls of an older civilisation still standing tall, resembling a veil of protection that has lasted through the ages. Arriving at the Algae Forest station, non-aquatic Explorers are casted with a respiration spell to be able to breath while traversing the depths. The city is filled with buildings of Atlantean architecture, with tall ornate pillars and intricate domed roofs from the magistrate to the scholar academy. The underwater kingdom is a whole nother world waiting to be explored.",
  ],
  Underlune: [
    "The Underlune is the deeper region of the ocean kingdom surrounded by clouds of steam tides and dark waters. After going through the vibrant Coral pass, Explorers will emerge upon an undercity engulfed in the darkness of the depths. Orange salt lanterns scattered across the pathways are dimly lit and barely illuminate the surroundings, so an aqueous lamp is recommended for those who wish to visit these parts.",
    "Those who travel here usually come to the Oktharis weaponcrafting facility ran by the Deeptide Guardians Guild, known for imbuing weapons with runic powers. A treacherous ravine known as the Abyssal deep sits at the bottom of Underlune, only accessible if you join in on the recon missions approved by the Deeptide magistrate. It serves only as a taste of what to expect with the ocean's depths, as much of the deep is still unknown and unexplored even today.",
  ],
}
