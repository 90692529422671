import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import ArcaneParticlesImage from "../assets/runes.gif"
import ArcaneParticlesVideo from "../assets/runes.webm"
import {
  ArcaneBaseImage,
  ArcaneCupImage,
  ArcaneJewelImage,
  BookImage,
  LanternImage,
  LanternLightImage,
  MapBottleImage,
  StumpImage,
  TelescopeImage,
} from "../config/themedAssets"
import useIsMobile from "../hooks/useIsMobile"
import { useNavigation } from "../hooks/useNavigation"
import { usePuzzle } from "../hooks/usePuzzle"
import { useGlobalState } from "../store/state"
import sizes from "../utils/sizes"
import { clickableShrunk, crystalFloat, crystalRotate } from "./common/common"
import GifOrWebm from "./GifOrWebm"

const StumpContainer = styled.div<{ hide: boolean; isMobile?: boolean; moveDownBy: string }>`
  position: relative;
  z-index: 2;
  transition: 2s transform ease;
  transform: ${(props) => {
    let transform = ""
    if (props.hide) {
      transform += `translateX(${props.isMobile ? "-50vw" : "-30vw"})`
    } else {
      transform += "translateX(0)"
    }
    if (props.moveDownBy) {
      transform += ` translateY(${props.moveDownBy})`
    }
    return transform
  }};
`

const shared = css`
  position: absolute;
  pointer-events: none;
`

const MOBILE_SCALE = 1.5

const Telescope = styled.img.attrs({
  src: TelescopeImage,
})<{ hide: boolean }>`
  ${clickableShrunk}
  position: absolute;
  bottom: 12.5vw;
  left: 11vw;
  width: 10vw;

  transform: ${(props) => {
    if (props.hide) {
      return "translateY(10vh)"
    }
    return "translateY(0)"
  }};
`

const Stump = styled.img.attrs({
  src: StumpImage,
})`
  ${shared}
  bottom: 0;
  left: 0;
  width: 30vw;

  @media (max-width: ${sizes.md}px) {
    width: calc(30vw * ${MOBILE_SCALE});
  }
`

const Lantern = styled.img.attrs<{ lightOn?: boolean }>(({ lightOn }) => ({
  src: lightOn ? LanternLightImage : LanternImage,
}))<{ lightOn?: boolean }>`
  ${shared}
  bottom: 0;
  left: 0;
  width: 6vw;
  bottom: 10vw;
  pointer-events: auto;

  @media (max-width: ${sizes.md}px) {
    width: calc(6vw * ${MOBILE_SCALE});
    bottom: calc(10vw * ${MOBILE_SCALE});
  }
`

const ArcaneBase = styled.img.attrs({
  src: ArcaneBaseImage,
})`
  ${shared}
  left: 5vw;
  width: 6vw;
  bottom: 10vw;

  @media (max-width: ${sizes.md}px) {
    left: calc(5vw * ${MOBILE_SCALE});
    width: calc(6vw * ${MOBILE_SCALE});
    bottom: calc(10vw * ${MOBILE_SCALE});
  }
`

const ArcaneCup = styled.img.attrs({
  src: ArcaneCupImage,
})`
  ${shared}
  bottom: 0;
  left: 4.8vw;
  width: 6.9vw;
  bottom: 20.5vw;
  animation: ${crystalFloat()} 6s ease-in-out infinite;

  @media (max-width: ${sizes.md}px) {
    left: calc(4.8vw * ${MOBILE_SCALE});
    width: calc(6.9vw * ${MOBILE_SCALE});
    bottom: calc(20.5vw * ${MOBILE_SCALE});
  }
`

const ArcaneJewel = styled.img.attrs({
  src: ArcaneJewelImage,
})`
  ${shared}
  left: 6.9vw;
  width: 3.6vw;
  bottom: 23.7vw;
  animation: ${crystalRotate} 6s linear infinite;

  @media (max-width: ${sizes.md}px) {
    left: calc(6.9vw * ${MOBILE_SCALE});
    width: calc(3.6vw * ${MOBILE_SCALE});
    bottom: calc(23.7vw * ${MOBILE_SCALE});
  }
`

const ArcaneParticlesContainer = styled.div`
  ${shared}
  left: 2.3vw;
  width: 13vw;
  bottom: 18.7vw;

  @media (max-width: ${sizes.md}px) {
    left: calc(2.3vw * ${MOBILE_SCALE});
    width: calc(13vw * ${MOBILE_SCALE});
    bottom: calc(18vw * ${MOBILE_SCALE});
  }
`

const Book = styled.img.attrs({
  src: BookImage,
})`
  ${clickableShrunk}
  position: absolute;
  pointer-events: auto;
  left: 2vw;
  width: 13vw;
  bottom: 5vw;

  @media (max-width: ${sizes.md}px) {
    left: calc(2vw * ${MOBILE_SCALE});
    width: calc(13vw * ${MOBILE_SCALE});
    bottom: calc(5vw * ${MOBILE_SCALE});
  }
`

const MapBottle = styled.img.attrs({
  src: MapBottleImage,
})`
  ${clickableShrunk}
  position: absolute;
  pointer-events: auto;
  left: 15vw;
  width: 11vw;
  bottom: 6.5vw;

  @media (max-width: ${sizes.md}px) {
    left: calc(15vw * ${MOBILE_SCALE});
    width: calc(11vw * ${MOBILE_SCALE});
    bottom: calc(6.5vw * ${MOBILE_SCALE});
  }
`

const PuzzleHint = styled.img.attrs<{ show?: boolean }>(({ src }) => ({
  src,
}))<{ show?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 20vw;
  width: 48px;
  height: 48px;
  z-index: -1;

  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  ${({ show }) => {
    if (show) {
      return css`
        transform: translateY(-70px);
        opacity: 1;
      `
    }
    return ""
  }}
`

interface StumpControlsProps {
  hide: boolean
  moveDownBy: string
}

const StumpControls = (props: StumpControlsProps) => {
  const { hide, moveDownBy } = props

  const [, setShowTelescopeScene] = useGlobalState("showTelescopeScene")
  const { currentModalContentId, navigateTo, navigateToFullscreen } = useNavigation()
  const isShowingContent = !!currentModalContentId
  const { isMobile } = useIsMobile()
  const { correctPuzzle, getImageAssetForPuzzleName, puzzleSolved, puzzleDisabled } = usePuzzle()
  const [showLanternSymbol, setShowLanternSymbol] = useState(false)

  const onBookClicked = useCallback(() => {
    navigateTo("lore")
  }, [navigateTo])

  const onBottleClicked = useCallback(() => {
    navigateToFullscreen("map")
  }, [navigateToFullscreen])

  const onTelescopeClicked = useCallback(() => {
    setShowTelescopeScene(true)
  }, [setShowTelescopeScene])

  // PUZZLE
  const onSecretLanternClick = useCallback(() => {
    if (!puzzleDisabled) {
      setShowLanternSymbol(true)
    }
  }, [puzzleDisabled])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (showLanternSymbol) {
      timeout = setTimeout(() => {
        setShowLanternSymbol(false)
      }, 5000)
    }
    return () => clearTimeout(timeout)
  }, [showLanternSymbol])

  const puzzleSymbol = useMemo(() => {
    if (correctPuzzle?.[0] && !puzzleSolved) {
      return getImageAssetForPuzzleName(correctPuzzle[0].name)
    }
    return undefined
  }, [correctPuzzle, getImageAssetForPuzzleName, puzzleSolved])

  return (
    <StumpContainer hide={hide} moveDownBy={moveDownBy} isMobile={isMobile}>
      {!isMobile && <Telescope onClick={onTelescopeClicked} hide={isShowingContent} />}

      <Stump />
      <Lantern lightOn={showLanternSymbol} onClick={onSecretLanternClick} />
      {puzzleSymbol && <PuzzleHint show={showLanternSymbol} src={puzzleSymbol} />}
      <ArcaneBase />
      <ArcaneCup />
      <ArcaneJewel />
      <ArcaneParticlesContainer>
        <GifOrWebm gifSrc={ArcaneParticlesImage} videoUrl={ArcaneParticlesVideo} />
      </ArcaneParticlesContainer>
      <Book onClick={onBookClicked} />
      <MapBottle onClick={onBottleClicked} />
    </StumpContainer>
  )
}

export default StumpControls
