import { useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { ExplorerAttributeFilter } from "../../../hooks/useFilterExplorers"
import GalleryExplorerContent from "./GalleryExplorerContent"
import GalleryListContent from "./GalleryListContent"

const GalleryView: React.FC = () => {
  const [filter, setFilter] = useState<ExplorerAttributeFilter>({})

  const location = useLocation()
  const explorerId = useMemo(() => new URLSearchParams(location.search).get("explorer"), [location])

  return explorerId ? (
    <GalleryExplorerContent explorerId={explorerId} />
  ) : (
    <GalleryListContent filter={filter} setFilter={setFilter} />
  )
}

export default GalleryView
